/*
    Intercom JavaScript API: Methods
    https://developers.intercom.com/installing-intercom/docs/intercom-javascript
*/

export enum IntercomFunction {
  Boot = 'boot',
  Shutdown = 'shutdown',
  Update = 'update',
  Hide = 'hide',
  Show = 'show',
  ShowMessage = 'showMessages',
  ShowNewMessage = 'showNewMessage',
  OnHide = 'onHide',
  OnShow = 'onShow',
  OnUnreadCountChange = 'onUnreadCountChange',
  TrackEvent = 'trackEvent',
  GetVisitorId = 'getVisitorId',
  StartTour = 'startTour',
  ShowArticle = 'showArticle',
  StartSurvey = 'startSurvey',
  OnUserEmailSupplied = 'onUserEmailSupplied',
}

export enum TaxJarServerMessages {
  CrystalDefaultAccount = 'Crystal',
  CustomIntegrationAccount = 'Custom',
  InvalidKey = 'API Key Invalid',
  InsertionSuccessful = 'New Key Added',
  DeletionSuccessful = 'Integration Deleted',
  IntegrationAlreadyExists = 'Custom Key Exists',
  AccountNotFound = 'No Account Found',
  NoRatesMessage = 'Failed To Fetch Rates',
  NoRatesForGivenZip = 'No Rates',
  TaxJarRatesInactive = 0.0,
}

// All supported integrations
export enum IntegrationType {
  BigCommerce = 'bigcommerce',
  QuickBooks = 'quickbooks',
  Shopify = 'shopify',
  TaxJar = 'taxjar',
  WooCommerce = 'woocommerce',
  Zillion = 'zillion',
  Montage = 'montage',
}
