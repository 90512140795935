import * as Sentry from '@sentry/react';
import * as React from 'react';

import UserFeedbackPage from './UserFeedbackPage';

interface Props {
  children: React.ReactNode;
}

interface State {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach((key: string) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      // Always show UserFeedbackPage on `/sentry` route, even in dev
      if (process.env.NODE_ENV === 'development' && !document.location.pathname.startsWith('/sentry')) {
        const RedBox = require('redbox-react').default;

        return <RedBox error={error} />;
      }

      return <UserFeedbackPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
