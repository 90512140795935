import {
  BROWSE_PRODUCTS,
  GET_MERCH_RECEIVED_HISTORY,
  RESET_BROWSE_INVENTORY_FILTER,
  RESET_BROWSE_INVENTORY_FILTERS,
  SET_BROWSE_INVENTORY_FILTERS,
  SET_BROWSE_INVENTORY_SORT,
  SET_BROWSE_INVENTORY_VIEW_MODE,
  SET_SELECTED_LOCATION,
  UPDATE_BROWSE_INVENTORY,
} from 'redux/constants/action-types';

import { _apiRequest } from 'redux/actions/system';

export const setBrowseInventoryFilters = (filters, checkMap) => ({
  type: SET_BROWSE_INVENTORY_FILTERS,
  payload: {
    filters,
    checkMap,
  },
});

export const resetBrowseInventoryFilters = () => ({
  type: RESET_BROWSE_INVENTORY_FILTERS,
});

export const resetBrowseInventoryFilter = key => ({
  type: RESET_BROWSE_INVENTORY_FILTER,
  payload: {
    key,
  },
});

export const setBrowseInventoryViewMode = viewMode => ({
  type: SET_BROWSE_INVENTORY_VIEW_MODE,
  payload: viewMode,
});

export const setBrowseInventorySort = sort => ({
  type: SET_BROWSE_INVENTORY_SORT,
  payload: sort,
});

export const browseProducts = payload => async dispatch =>
  _apiRequest({
    actionBase: BROWSE_PRODUCTS,
    dispatch,
    method: 'Post',
    payload,
    path: `products`,
    unauthorizedError: 'You are not allowed to view these items',
  });

export const setSelectedLocation = locationId => ({
  type: SET_SELECTED_LOCATION,
  payload: locationId,
});

export const updateBrowseInventoryFilters = payload => ({
  type: UPDATE_BROWSE_INVENTORY,
  payload,
});

export const getMerchReceivedHistory = id => async dispatch =>
  _apiRequest({
    actionBase: GET_MERCH_RECEIVED_HISTORY,
    dispatch,
    method: 'Get',
    path: `product/merch?id=${id}`,
    returnPayloadOnSuccess: false,
  });
