import { _apiRequest } from 'redux/actions/system';
import { CONVERT_CURRENCY, SET_FOREIGN_EXCHANGE_RATE_SESSION_DATA } from 'redux/constants/action-types';

export const convertCurrency = (from, to) => async dispatch =>
  _apiRequest({
    actionBase: CONVERT_CURRENCY,
    dispatch,
    method: 'Get',
    path: `forex?from=${from}&to=${to}`,
  });

export const setForeignExchangeRateSessionData = payload => ({
  type: SET_FOREIGN_EXCHANGE_RATE_SESSION_DATA,
  payload,
});
