import { _apiRequest } from 'redux/actions/system';
import { ADD_CASE, EDIT_CASE, GET_CASES } from 'redux/constants/action-types';

export const getCases = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_CASES,
    dispatch,
    method: 'Get',
    path: 'admin/inventory-cases',
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const editCase = payload => async dispatch => {
  await _apiRequest({
    actionBase: EDIT_CASE,
    dispatch,
    method: 'Put',
    path: `admin/inventory-case`,
    payload,
    returnPayloadOnSuccess: true,
    notification: 'Case updated',
  });
};

export const addCase = payload => async dispatch => {
  await _apiRequest({
    actionBase: ADD_CASE,
    dispatch,
    method: 'Post',
    path: `admin/inventory-case`,
    payload,
    returnPayloadOnSuccess: false,
    notification: 'Case created',
  });
};
