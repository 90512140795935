import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { Theme } from 'styles/theme';

function not(a: string[], b: string[]) {
  return a.filter((value: string) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value: string) => b.indexOf(value) !== -1);
}

function union(a: string[], b: string[]) {
  return [...a, ...not(b, a)];
}

interface TOption {
  name?: string;
  key?: string;
  id: string | number;
  isDefault?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  type?: string;
  label?: string;
  render?: (row: { uuid: string; sku?: string }) => string | ReactNode;
  link?: (row: { uuid: string; sku?: string }) => string;
}

export interface TransfersProps {
  sourceKeys: number[] | string[];
  targetKeys: number[] | string[];
  onChange?: null | ((left: string | string[], right: string | string[]) => void);
  options: TOption[];
  idKey?: string;
  className?: string;
  // Use this for a static property used as the label - if not passed the Option must have a label property on it
  labelKey?: string;
  leftLabel: string;
  rightLabel: string;
}

const Transfers = ({
  sourceKeys = [],
  targetKeys = [],
  onChange = null,
  options = [],
  idKey = 'key',
  labelKey = 'label',
  leftLabel = 'Available',
  rightLabel = 'Selected',
  className,
}: TransfersProps) => {
  const [checked, setChecked] = React.useState<string[]>([]);
  const [left, setLeft] = React.useState(sourceKeys);
  const [right, setRight] = React.useState(targetKeys);

  const leftChecked = intersection(checked, left as string[]);
  const rightChecked = intersection(checked, right as string[]);

  React.useEffect(() => {
    setLeft(sourceKeys);
  }, [sourceKeys]);

  React.useEffect(() => {
    setRight(targetKeys);
  }, [targetKeys]);

  React.useEffect(() => {
    if (onChange) {
      onChange(left as string[] | string, right as string[] | string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [right]);

  const checkBoxSx = {
    '&.Mui-checked': {
      color: Theme.colors.primary.base,
    },
    '&.Mui-disabled': {
      color: '#cccccc',
    },
    '&.MuiCheckbox-indeterminate': {
      color: `${Theme.colors.primary.base} !important`,
    },
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked: string[] = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: string[]) => intersection(checked, items).length;

  const handleToggleAll = (items: string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight((right as string[]).concat(leftChecked));
    setLeft(not(left as string[], leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft((left as string[]).concat(rightChecked));
    setRight(not(right as string[], rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const getLabel = (keyValue: string) => {
    const option = options.find((o: TOption) => o[idKey as keyof TOption] === keyValue);
    if (option) {
      const label = option[labelKey as keyof TOption];
      if (label) {
        return <>{label}</>;
      }
    }
    return <p />;
  };

  const customList = (title: string, items: string[]) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            disabled={items.length === 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
            onClick={handleToggleAll(items)}
            sx={checkBoxSx}
          />
        }
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
        sx={{ px: 2, py: 1 }}
        title={title}
      />
      <Divider />
      <List
        component='div'
        dense
        role='list'
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
      >
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} button onClick={handleToggle(value)} role='listitem'>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  sx={checkBoxSx}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={getLabel(value)} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <ThemeProvider theme={Theme}>
      <Grid alignItems='center' className={className} container justifyContent='center' spacing={2}>
        <Grid item>{customList(leftLabel, left as string[])}</Grid>
        <Grid item>
          <Grid alignItems='center' container direction='column'>
            <Button
              aria-label='move selected right'
              disabled={leftChecked.length === 0}
              onClick={handleCheckedRight}
              size='small'
              sx={{ my: 0.5 }}
              variant='outlined'
            >
              &gt;
            </Button>
            <Button
              aria-label='move selected left'
              disabled={rightChecked.length === 0}
              onClick={handleCheckedLeft}
              size='small'
              sx={{ my: 0.5 }}
              variant='outlined'
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(rightLabel, right as string[])}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Transfers;
