import { RESET_ALL_DATA, SET_CLIENT_PAGE_CLIENT_ID } from '../constants/action-types';

const initialState = {
  clientId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_PAGE_CLIENT_ID:
      return {
        ...state,
        clientId: action.payload,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
