import { successReqActionType } from '../actions/helpers';
import { CREATE_PRODUCT_TYPE, GET_PRODUCT_TYPES, RESET_ALL_DATA, UPDATE_PRODUCT_TYPE } from '../constants/action-types';

const initialState = {
  productTypes: [],
};

const reducer = (state = initialState, action) => {
  const { productTypes } = state;
  switch (action.type) {
    case successReqActionType(GET_PRODUCT_TYPES):
      return {
        ...state,
        productTypes: action.payload,
      };
    case successReqActionType(CREATE_PRODUCT_TYPE):
      const addedNewProductTypes = [...productTypes, action.payload];
      return {
        ...state,
        productTypes: addedNewProductTypes,
      };
    case successReqActionType(UPDATE_PRODUCT_TYPE):
      const updatedNewProductTypes = [...productTypes];
      const { payload } = action;
      const currentItem = updatedNewProductTypes.find(pt => pt.id === payload.id);
      const currentItemIndex = updatedNewProductTypes.findIndex(pt => pt.id === payload.id);
      const newItem = { ...currentItem, ...payload };
      updatedNewProductTypes.splice(currentItemIndex, 1, newItem);
      return {
        ...state,
        productTypes: updatedNewProductTypes,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
