import { DebouncedFunc } from 'lodash-es';
import React, { CSSProperties, MutableRefObject } from 'react';
import ReactSelect from 'react-select';
import AsyncReactSelect from 'react-select/async';
import { Theme } from 'styles/theme';
import { SelectOption } from 'utils/common-types';

export interface SelectProps {
  id?: string;
  // NOTE: additional typing added after migration
  // original react crystal:
  // options: TValue[]
  options?: SelectOption[] | any;
  // NOTE: additional typing added after migration
  // original react crystal:
  // value: TValue | string | number | null
  value?: SelectOption | string | number | null | any;
  // NOTE: needed to add 'any' to fit our wide range of types we pass into this component
  // original react crystal:
  //   onChange?: (newValue: TValue | any) => void;
  onChange?: (newValue: SelectOption | any) => void;
  autoFocus?: boolean;
  isAsync?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  isFixed?: boolean;
  menuPlacement?: string;
  className?: string;
  defaultOptions?: boolean;
  name?: string;
  isFilterable?: boolean;
  // NOTE: needed to add 'any' to fit our wide range of types we pass into this component
  // original react crystal:
  // defaultValue?: TValue;
  defaultValue?: SelectOption | any;
  filterOption?: (option: SelectOption, inputValue: string) => boolean;
  closeMenuOnSelect?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  disabled?: boolean;
  cacheOptions?: boolean;
  styles?: CSSProperties;
  style?: CSSProperties;
  label?: string;
  onInputChange?: DebouncedFunc<(query: string) => void> | ((query: string) => void) | Promise<SelectOption[]>;
  ref?: MutableRefObject<null | undefined>;
  size?: string;
  getOptionLabel?: (e: any) => any;
  components?: {
    Option?: (props: any) => JSX.Element;
    MultiValue?: (props: any) => JSX.Element;
  };
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  noOptionsMessage?: () => string;
}

// TODO: specify theme, for now it's 'any', because there's no types for ReactSelect.theme
const Select = React.forwardRef((props: SelectProps, ref) => {
  const { isAsync, isSearchable, menuPlacement = 'auto' } = props;
  if (isAsync) {
    return (
      <AsyncReactSelect
        ref={ref}
        cacheOptions
        className='form-select-control'
        isDisabled={props.disabled || false}
        isOptionDisabled={option => option.disabled}
        isSearchable={isSearchable || false}
        loadOptions={props.onInputChange}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body}
        menuPosition={props.isFixed ? 'fixed' : 'absolute'}
        menuShouldScrollIntoView={false}
        styles={{
          // Fixes the overlapping problem of the component
          singleValue: (base: CSSProperties) => ({
            ...base,
            overflow: 'visible',
            width: '99%',
          }),
          menu: (provided: CSSProperties) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 9999, fontSize: Theme.font.size.medium }),
        }}
        theme={(theme: any) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary: Theme.colors.primary.base,
            primary75: 'rgba(0, 0, 0, 0.05)',
            primary50: 'rgba(0, 0, 0, 0.05)',
            primary25: 'rgba(0, 0, 0, 0.05)',
            dangerLight: '#f44336',
            danger: 'white',
            neutral0: 'hsl(0, 0%, 100%)',
            neutral5: 'hsl(0, 0%, 95%)',
            neutral10: 'hsl(0, 0%, 90%)',
            neutral20: 'hsl(0, 0%, 80%)',
            neutral30: 'hsl(0, 0%, 70%)',
            neutral40: 'hsl(0, 0%, 60%)',
            neutral50: 'hsl(0, 0%, 50%)',
            neutral60: 'hsl(0, 0%, 40%)',
            neutral70: 'hsl(0, 0%, 30%)',
            neutral80: 'hsl(0, 0%, 20%)',
            neutral90: 'hsl(0, 0%, 10%)',
          },
        })}
        {...props}
      />
    );
  }

  return (
    <ReactSelect
      ref={ref}
      className='form-select-control'
      isDisabled={props.disabled || false}
      isOptionDisabled={option => option.disabled}
      isSearchable={isSearchable || false}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      menuPosition={props.isFixed ? 'fixed' : 'absolute'}
      menuShouldScrollIntoView={false}
      styles={{
        // Fixes the overlapping problem of the component
        singleValue: (base: CSSProperties) => ({
          ...base,
          overflow: 'visible',
          width: '99%',
        }),
        menu: (provided: CSSProperties) => ({ ...provided, zIndex: 9999 }),
        menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 9999, fontSize: Theme.font.size.medium }),
      }}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary: Theme.colors.primary.base,
          primary75: 'rgba(0, 0, 0, 0.05)',
          primary50: 'rgba(0, 0, 0, 0.05)',
          primary25: 'rgba(0, 0, 0, 0.05)',
          dangerLight: '#f44336',
          danger: 'white',
          neutral0: 'hsl(0, 0%, 100%)',
          neutral5: 'hsl(0, 0%, 95%)',
          neutral10: 'hsl(0, 0%, 90%)',
          neutral20: 'hsl(0, 0%, 80%)',
          neutral30: 'hsl(0, 0%, 70%)',
          neutral40: 'hsl(0, 0%, 60%)',
          neutral50: 'hsl(0, 0%, 50%)',
          neutral60: 'hsl(0, 0%, 40%)',
          neutral70: 'hsl(0, 0%, 30%)',
          neutral80: 'hsl(0, 0%, 20%)',
          neutral90: 'hsl(0, 0%, 10%)',
        },
      })}
      {...props}
    />
  );
});
// https://react-select.com/props#prop-types

export default Select;
