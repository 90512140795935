import i18n from 'i18next';

import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const COST_OF_GOODS_SOLD_SUMMARY_REPORT_FIELDS = [
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.account')
      ? i18n.t('reports:cost-of-goods-sold.columns.account')
      : 'Account',
    key: 'accounting_bucket',
    id: 'accounting_bucket',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/accounting/inventory-accounting/cost-of-goods-sold-summary-details/${row.accounting_bucket_id}`}
      >
        {row.accounting_bucket}
      </Link>
    ),
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.starting-quantity')
      ? i18n.t('reports:cost-of-goods-sold.columns.starting-quantity')
      : 'Starting Quantity',
    key: 'starting_quantity',
    id: 'starting_quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.starting_quantity,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.starting-retail')
      ? i18n.t('reports:cost-of-goods-sold.columns.starting-retail')
      : 'Starting Retail $',
    key: 'starting_retail',
    id: 'starting_retail',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.starting_retail) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.starting-cost')
      ? i18n.t('reports:cost-of-goods-sold.columns.starting-cost')
      : 'Starting Cost $',
    key: 'starting_cost',
    id: 'starting_cost',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.starting_cost) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.ending-quantity')
      ? i18n.t('reports:cost-of-goods-sold.columns.ending-quantity')
      : 'Ending Quantity',
    key: 'ending_quantity',
    id: 'ending_quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.ending_quantity,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.ending-retail')
      ? i18n.t('reports:cost-of-goods-sold.columns.ending-retail')
      : 'Ending Retail $',
    key: 'ending_retail',
    id: 'ending_retail',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ending_retail) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.ending-cost')
      ? i18n.t('reports:cost-of-goods-sold.columns.ending-cost')
      : 'Ending Cost $',
    key: 'ending_cost',
    id: 'ending_cost',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ending_cost) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.total-closed-sales')
      ? i18n.t('reports:cost-of-goods-sold.columns.total-closed-sales')
      : 'Total Closed Sales $',
    key: 'total_sales',
    id: 'total_sales',
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.total_sales) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.total-deposits')
      ? i18n.t('reports:cost-of-goods-sold.columns.total-deposits')
      : 'Total Deposits $',
    key: 'deposits',
    id: 'deposits',
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.deposits) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.cost-of-goods-sold')
      ? i18n.t('reports:cost-of-goods-sold.columns.cost-of-goods-sold')
      : 'Cost of Goods Sold $',
    key: 'cost_of_goods_sold',
    id: 'cost_of_goods_sold',
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.cost_of_goods_sold) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
];
