import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import {
  ADD_ORDER_CODE,
  EDIT_ORDER_CODE,
  GET_ORDER_CODE,
  GET_ORDER_CODES,
  RESET_ALL_DATA,
} from '../constants/action-types';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_ORDER_CODES):
      return {
        ...state,
        ...keyBy(action.payload, 'id'),
      };
    case successReqActionType(EDIT_ORDER_CODE):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };
    case successReqActionType(GET_ORDER_CODE):
    case successReqActionType(ADD_ORDER_CODE):
      return { ...state, [action.payload.id]: action.payload };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
