import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import {
  ADD_VENDOR,
  ADD_VENDOR_ADDRESS,
  ADD_VENDOR_EMAIL,
  ADD_VENDOR_PHONE,
  EDIT_VENDOR,
  EDIT_VENDOR_ADDRESS,
  EDIT_VENDOR_EMAIL,
  EDIT_VENDOR_PHONE,
  EDIT_VENDOR_PRIMARY_FIELD,
  GET_REORDER_PRODUCTS,
  GET_VENDOR,
  GET_VENDORS,
  GET_VENDOR_MEMO_ITEMS,
  GET_VENDOR_MERCH_HISTORY,
  GET_VENDOR_PRODUCTS,
  GET_VENDOR_PURCHASE_HISTORY,
  GET_VENDOR_TRANSACTIONS,
  RESET_ALL_DATA,
  SEARCH_VENDORS,
  SET_REORDER_VENDOR,
} from '../constants/action-types';

const initialState = {};
const updateObjectInArray = (arr, searchKey, newVal) =>
  arr.map(el => {
    if (el.id === searchKey) {
      return { ...el, ...newVal };
    }
    return el;
  });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_VENDORS):
    case successReqActionType(SEARCH_VENDORS):
      return { ...state, ...keyBy(action.payload, 'uuid') };
    case successReqActionType(EDIT_VENDOR):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          ...action.payload,
        },
      };
    case successReqActionType(GET_VENDOR):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          ...action.payload,
        },
      };
    case successReqActionType(ADD_VENDOR):
      return { ...state, [action.payload.uuid]: action.payload };
    case successReqActionType(ADD_VENDOR_EMAIL):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          emails: [...state[action.uuid].emails, action.payload],
        },
      };
    case successReqActionType(GET_VENDOR_TRANSACTIONS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          transactions: action.payload,
        },
      };
    case successReqActionType(GET_VENDOR_MERCH_HISTORY):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          merchReceivedHistory: action.payload,
        },
      };
    case successReqActionType(GET_VENDOR_PURCHASE_HISTORY):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          purchaseHistory: action.payload,
        },
      };
    case successReqActionType(GET_VENDOR_PRODUCTS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          products: action.payload,
        },
      };
    case successReqActionType(GET_REORDER_PRODUCTS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          reorder: action.payload,
        },
      };
    case successReqActionType(EDIT_VENDOR_EMAIL):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          emails: updateObjectInArray(state[action.payload.uuid].emails, action.payload.id, action.payload),
        },
      };
    case successReqActionType(ADD_VENDOR_PHONE):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          phones: [...state[action.uuid].phones, action.payload],
        },
      };
    case successReqActionType(EDIT_VENDOR_PHONE):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          phones: updateObjectInArray(state[action.payload.uuid].phones, action.payload.id, action.payload),
        },
      };
    case successReqActionType(ADD_VENDOR_ADDRESS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          addresses: [...state[action.uuid].addresses, action.payload],
        },
      };
    case successReqActionType(EDIT_VENDOR_ADDRESS):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          addresses: updateObjectInArray(state[action.payload.uuid].addresses, action.payload.id, action.payload),
        },
      };
    case EDIT_VENDOR_PRIMARY_FIELD:
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          [action.payload.dataType]: state[action.payload.uuid][action.payload.dataType].map(el => {
            if (el.id === action.payload.newPrimaryId) {
              return { ...el, primary: true };
            }
            return { ...el, primary: false };
          }),
        },
      };
    case successReqActionType(GET_VENDOR_MEMO_ITEMS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          memoItems: action.payload,
        },
      };
    case SET_REORDER_VENDOR:
      return {
        ...state,
        reorderVendor: action.payload,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
