import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import { GET_SKU_SALES, GET_SKU_TRANSACTIONS, RESET_ALL_DATA } from '../constants/action-types';

const initialState = {
  movement: {},
  sales: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_SKU_TRANSACTIONS):
      return { ...state, movement: keyBy(action.payload, 'id') };
    case successReqActionType(GET_SKU_SALES):
      return { ...state, sales: keyBy(action.payload, 'id') };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
