import { _apiRequest } from 'redux/actions/system';
import {
  ADD_MEMO_ITEM,
  ADD_PURCHASE_ORDER,
  ADD_PURCHASE_ORDER_ITEM_RECEIPT,
  CONVERT_PURCHASE_ORDER,
  CREATE_MEMO,
  DELETE_APPRAISAL_ATTACHMENTS,
  DELETE_CLIENT_ATTACHMENTS,
  DELETE_ORDER_ATTACHMENTS,
  EDIT_MEMO_ITEM,
  EDIT_PURCHASE_ORDER,
  GET_APPRAISAL_ATTACHMENTS,
  GET_CLIENT_ATTACHMENTS,
  GET_MEMO,
  GET_MEMOS,
  GET_ORDER_ATTACHMENTS,
  GET_PURCHASE_HISTORY,
  GET_PURCHASE_ORDER,
  SEARCH_DIAMONDS,
  SET_DIAMOND_SEARCH_FILTERS,
  SET_FILTERED_ITEMS,
  UPDATE_MEMO,
} from 'redux/constants/action-types';
import history from 'utils/history';
import * as Request from 'utils/request';

export const createMemo = (payload, callback) => async dispatch =>
  _apiRequest({
    actionBase: CREATE_MEMO,
    dispatch,
    method: 'Post',
    path: `memo`,
    payload,
    callback: data => callback && callback(data),
    returnPayloadOnSuccess: false,
  });

export const getMemosList = (customer_id, account_id) => async dispatch =>
  _apiRequest({
    actionBase: GET_MEMOS,
    dispatch,
    method: 'Get',
    path: `memos${customer_id ? `?customer_id=${customer_id}` : ''}${account_id ? `?account_id=${account_id}` : ''}`,
    returnPayloadOnSuccess: false,
  });

export const getMemo = (id, cb?: (data: any) => void) => async dispatch =>
  _apiRequest({
    actionBase: GET_MEMO,
    dispatch,
    method: 'Get',
    path: `memo?id=${id}`,
    returnPayloadOnSuccess: false,
    callback: data => {
      cb && cb(data);
    },
  });

export const updateMemo = payload => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_MEMO,
    dispatch,
    method: 'Put',
    path: `memo`,
    payload,
    returnPayloadOnSuccess: false,
  });

export const addMemoItems = (currentMemo, items) => async dispatch => {
  const { uuid } = currentMemo;
  await Promise.all(
    items.map(item => {
      return _apiRequest({
        actionBase: ADD_MEMO_ITEM,
        dispatch,
        method: 'Post',
        path: `memo/item`,
        payload: {
          memo_id: currentMemo.id,
          location_id: currentMemo.location.id,
          sku_id: item.id,
          quantity: item.quantity,
          unit_price: item.unit_price,
          is_return: item.is_return ? true : null,
        },
        metaDispatch: { uuid },
        handleStatus: status => {
          switch (status) {
            case 417:
              window.showNotification(
                'error',
                'Quantity Not Available',
                `The quantity you requested for this Stock # (${item.sku}) is not available at the source location. It has not been added to the memo.`
              );
              break;
            default:
              break;
          }
        },
      });
    })
  );
};

export const editMemoItem = (payload, callback) => async dispatch => {
  _apiRequest({
    actionBase: EDIT_MEMO_ITEM,
    dispatch,
    method: 'Put',
    path: `memo/item`,
    payload,
    callback,
    handleStatus: status => {
      if (status !== 200) window.showError('Something Went Wrong');
    },
  });
};

export const addPurchaseOrder = (payload, cb) => async dispatch =>
  _apiRequest({
    actionBase: ADD_PURCHASE_ORDER,
    dispatch,
    method: 'Post',
    path: `po`,
    payload,
    callback: data => {
      cb && cb(data);

      if (data) {
        history.replace(`/purchase-orders/${data}`);
      }
    },
  });

export const editPurchaseOrder = (payload, callback?: (response?: any) => any) => async dispatch =>
  _apiRequest({
    actionBase: EDIT_PURCHASE_ORDER,
    dispatch,
    method: 'Put',
    path: `po`,
    payload,
    returnPayloadOnSuccess: true,
    handleStatus: status => {
      switch (status) {
        case 409:
          window.showNotification('error', 'Not Allowed', '');
          break;
        default:
          break;
      }
    },
    onSuccess: () => {
      window.showSuccess('Saved');
    },
    callback,
  });

export const getPurchaseOrder = (id, cb?: (data: any) => void) => async dispatch =>
  _apiRequest({
    actionBase: GET_PURCHASE_ORDER,
    dispatch,
    method: 'Get',
    path: `po?id=${id}`,
    callback: data => {
      cb && cb(data);
    },
  });

export const getPurchaseHistory = id => async dispatch =>
  _apiRequest({
    actionBase: GET_PURCHASE_HISTORY,
    dispatch,
    method: 'Get',
    path: `product/po?id=${id}`,
    returnPayloadOnSuccess: false,
  });

export const convertPurchaseOrder = (payload, cb?: (data: any) => void) => async dispatch =>
  _apiRequest({
    actionBase: CONVERT_PURCHASE_ORDER,
    dispatch,
    method: 'Post',
    path: `po/conversion`,
    payload,
    callback: data => {
      cb && cb(data);

      if (data) {
        history.push(`/receives/${data}`);
      }
    },
  });

export const addPurchaseOrderItemReceipt = (payload, cb?: () => void) => async dispatch =>
  _apiRequest({
    actionBase: ADD_PURCHASE_ORDER_ITEM_RECEIPT,
    dispatch,
    method: 'Post',
    path: `po/receive`,
    payload,
    callback: () => {
      cb && cb();
    },
  });

export const getFilteredValues = () => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_DIAMONDS,
    dispatch,
    method: 'Post',
    path: `diamond/search`,
    payload: {},
  });

export const setDiamondSearchFilters = payload => ({
  type: SET_DIAMOND_SEARCH_FILTERS,
  payload,
});

export const getOrderAttachments = (refId, uuid) => async dispatch => {
  _apiRequest({
    actionBase: GET_ORDER_ATTACHMENTS,
    dispatch,
    method: 'Get',
    path: `order/attachments?id=${refId}`,
    metaDispatch: { uuid },
  });
};

export const detachOrderAttachment = (refId, uuid, attachmentId) => async dispatch => {
  _apiRequest({
    actionBase: DELETE_ORDER_ATTACHMENTS,
    dispatch,
    method: 'Put',
    path: `order/attachment`,
    payload: {
      id: refId,
      attachment_id: attachmentId,
      uuid,
    },
    returnPayloadOnSuccess: true,
  });
};

export const getAppraisalAttachments = (refId, uuid) => async dispatch => {
  _apiRequest({
    actionBase: GET_APPRAISAL_ATTACHMENTS,
    dispatch,
    method: 'Get',
    path: `appraisals/${refId}/attachments`,
    metaDispatch: { uuid },
  });
};

export const detachAppraisalAttachment = (refId, uuid, attachmentId) => async dispatch => {
  _apiRequest({
    actionBase: DELETE_APPRAISAL_ATTACHMENTS,
    dispatch,
    method: 'Delete',
    path: `appraisals/${refId}/attachments/${attachmentId}`,
    payload: { attachment_id: attachmentId },
    returnPayloadOnSuccess: true,
  });
};

export const getClientAttachments = (refId, uuid) => async dispatch => {
  _apiRequest({
    actionBase: GET_CLIENT_ATTACHMENTS,
    dispatch,
    method: 'Get',
    path: `client/attachments?id=${refId}`,
    metaDispatch: { uuid },
  });
};

export const detachClientAttachment = (refId, uuid, attachmentId) => async dispatch => {
  _apiRequest({
    actionBase: DELETE_CLIENT_ATTACHMENTS,
    dispatch,
    method: 'Put',
    path: `client/attachment`,
    payload: {
      id: refId,
      attachment_id: attachmentId,
      uuid,
    },
    returnPayloadOnSuccess: true,
  });
};

export const setTableFilteredItems = payload => ({
  type: SET_FILTERED_ITEMS,
  payload,
});

export const addItemToPurchaseOrder = payload => async dispatch => {
  const response = await Request.Post('po/item', payload);

  if (response.error) {
    console.error(response.error);
    return;
  }

  dispatch(getPurchaseOrder(payload.uuid, () => {}));
};

export const editPurchaseOrderItem = payload => async dispatch => {
  const response = await Request.Put('po/item', payload);

  if (response.error) {
    console.error(response.error);
    return;
  }

  dispatch(getPurchaseOrder(payload.uuid, () => {}));
};
