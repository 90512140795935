import Modal, { Appearance, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { ReactNode } from 'react';

export type ModalWidthType = typeof ModalWidth[keyof typeof ModalWidth];

export enum ModalWidth {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'x-large',
}

interface ModalProps {
  autoFocus?: boolean;
  children: ReactNode;
  appearance?: Appearance;
  title?: string;
  titleContent?: ReactNode;
  width?: ModalWidthType;
}

const CrystalModal = ({
  appearance,
  autoFocus = true,
  children,
  title = '',
  titleContent,
  width = ModalWidth.Medium,
}: ModalProps) => {
  return (
    <ModalTransition>
      {
        <Modal autoFocus={autoFocus} width={width}>
          {title && (
            <ModalHeader>
              <ModalTitle appearance={appearance}>{title}</ModalTitle>
              {titleContent}
            </ModalHeader>
          )}
          {children}
        </Modal>
      }
    </ModalTransition>
  );
};

export default CrystalModal;
