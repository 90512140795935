import { ReactNode } from 'react';

interface ModalHeaderProps {
  className?: string;
  children: ReactNode;
  props?: any;
}

const ModalHeader = ({ className, children, ...props }: ModalHeaderProps) => (
  <div className={`modal-header ${className || ''}`} {...props}>
    {children}
  </div>
);

export default ModalHeader;
