import { getProductTypes } from 'redux/actions';
import { _apiRequest } from 'redux/actions/system';
import {
  EDIT_ECOM_PRODUCT_TYPES,
  GET_ECOM_PRODUCTS,
  GET_ECOM_VENDOR_PRODUCT_STATUSES,
  UPDATE_ECOM_VENDOR_PRODUCT_STATUSES,
} from 'redux/constants/action-types';

export const getEcomProducts = payload => async dispatch =>
  _apiRequest({
    actionBase: GET_ECOM_PRODUCTS,
    dispatch,
    method: 'Post',
    payload,
    path: `ecom/products`,
  });

export const editEcomProductTypes = payload => async dispatch => {
  const response = await _apiRequest({
    actionBase: EDIT_ECOM_PRODUCT_TYPES,
    dispatch,
    method: 'Post',
    payload,
    path: `ecom/product-types/batch`,
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (response.status === 200) {
    dispatch(getProductTypes());
    window.showSuccess('Saved');
  }
};

export const getEcomVendorProductStatuses = (filter, cb?: (data: any) => void) => async dispatch => {
  _apiRequest({
    actionBase: GET_ECOM_VENDOR_PRODUCT_STATUSES,
    dispatch,
    method: 'Get',
    path: `ecom/product-ecom-status?${filter}`,
    callback: data => {
      cb && cb(data);
    },
  });
};

export const editEcomVendorProductStatuses = payload => async dispatch => {
  const response = await _apiRequest({
    actionBase: UPDATE_ECOM_VENDOR_PRODUCT_STATUSES,
    dispatch,
    method: 'Post',
    payload,
    path: `ecom/product-ecom-status/batch`,
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (response.status === 200) {
    window.showSuccess('Saved');
  }
};
