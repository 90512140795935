import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const MEMOS_REPORT_FIELDS = [
  {
    name: 'Date',
    id: 'created',
    key: 'created',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: row => formatDate(row.created),
  },
  {
    name: 'Memo #',
    key: 'record_id',
    id: 'record_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/memo/${row.uuid}`}>
        {row.record_id}
      </Link>
    ),
  },
  {
    name: 'Vendor Name',
    key: 'account_name',
    id: 'account_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Client',
    key: 'customer_name',
    id: 'customer_name',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_name}
      </Link>
    ),
  },
  {
    name: 'Location',
    key: 'location_name',
    id: 'location_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Destination',
    key: 'destination_name',
    id: 'destination_name',
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-number') ? i18n.t('sku:stock-number') : 'Stock #',
    id: 'sku',
    key: 'sku',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.sku_uuid}`}>
        {row.sku}
      </Link>
    ),
  },
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
  },
  {
    name: 'Serial #',
    id: 'serial_number',
    key: 'serial_number',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    id: 'note',
    key: 'note',
    sortable: true,
    filterable: true,
    isDefault: false,
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Unit Price',
    id: 'unit_price',
    key: 'unit_price',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: row => formatMoney(row.unit_price || 0),
  },
  {
    name: 'Online Price',
    id: 'online_price',
    key: 'online_price',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: row => formatMoney(row.online_price || 0),
  },
  {
    name: 'Memo Status',
    id: 'is_return',
    key: 'is_return',
    type: 'String',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: row => (row.is_return ? 'Memo In' : 'Memo Out'),
  },
];
