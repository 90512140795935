import { Theme } from 'styles/theme';

export const DailySummaryPrintCSS = `
* {
  font-size: ${Theme.font.size.large};
}
.MuiButtonBase-root {
  display: none;
}
.MuiTableRow-root {
  height: auto;
}
.TableTreeIndent-indent {
  margin-right: 30px;
}
.Content-content {
  text-align: start;
}
.TableTreeIndent-indent ~ .TableTreeContent-content {
  padding-left: 30px;
}
`;
