import { successReqActionType } from '../actions/helpers';
import { RESET_ALL_DATA, SEARCH_DIAMONDS, SET_DIAMOND_SEARCH_FILTERS } from '../constants/action-types';

const initialState: {
  diamonds: any[];
  diamondSearchFilters: any;
} = {
  diamonds: [],
  diamondSearchFilters: [],
};

const reducer = (state = initialState, action: { type: string; payload: any[] }) => {
  switch (action.type) {
    case successReqActionType(SEARCH_DIAMONDS):
      return {
        ...state,
        diamonds: action.payload,
      };
    case SET_DIAMOND_SEARCH_FILTERS:
      return { ...state, diamondSearchFilters: action.payload };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
