import PhoneRender from 'components/common/PhoneRender';
import { Link } from 'react-router-dom';
import { UnlocalizedMarketingChannelOptions } from 'strings/constants';
import { formatDate, formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const CLIENTS_REPORT_FIELDS = [
  {
    name: 'Client #',
    key: 'customer_id',
    id: 'customer_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_id}
      </Link>
    ),
  },
  {
    name: 'First Name',
    key: 'first_name',
    id: 'first_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last Name',
    key: 'last_name',
    id: 'last_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'E-mail',
    key: 'email',
    id: 'email',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Phone',
    key: 'phone',
    id: 'phone',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => row.phone && <PhoneRender phone={row.phone} />,
  },
  {
    name: 'Address',
    key: 'address',
    // address ID is keyed to 'street'
    // this is because Address is a column representing a combination of several fields found in the row,
    // but does not exist within the row
    // so for sorting purposes we will use street / street1 as it is the bulk of address data
    id: 'street',
    render: (row: ReportRow) =>
      `${row.street ? `${row.street},` : ''} ${row.street2 ? `${row.street2},` : ''} ${
        row.city ? `${row.city},` : ''
      } ${row.state ? row.state : ''} ${row.postal ? row.postal : ''}`,
    sortable: true,
  },
  {
    name: 'Street',
    key: 'street',
    id: 'street',
    isDefault: true,
    sortable: true,
    render: (row: ReportRow) => `${row.street || ''}${row.street2 ? `, ${row.street2}` : ''}`,
  },
  {
    name: 'City',
    key: 'city',
    id: 'city',
    isDefault: true,
    sortable: true,
  },
  {
    name: 'State/Province',
    key: 'state',
    id: 'state',
    isDefault: true,
    sortable: true,
  },
  {
    name: 'ZIP/Postal',
    key: 'postal',
    id: 'postal',
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Country',
    key: 'country',
    id: 'country',
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Do Not Mail',
    key: 'do_not_mail',
    id: 'do_not_mail',
    render: (row: ReportRow) => (row.do_not_mail ? 'Yes' : ''),
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Do Not Text',
    key: 'do_not_text',
    id: 'do_not_text',
    render: (row: ReportRow) => (row.do_not_text ? 'Yes' : ''),
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Created On',
    key: 'created',
    id: 'created',
    render: (row: ReportRow) => formatDate(row.created),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'DATE',
  },
  {
    name: 'Last Close Date',
    key: 'last_close_date',
    id: 'last_close_date',
    render: (row: ReportRow) => formatLocalDate(row.last_close_date),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'DATE',
  },
  {
    name: 'Last Updated',
    key: 'last_updated',
    id: 'last_updated',
    render: (row: ReportRow) => formatDate(row.last_updated),
    sortable: true,
    filterable: true,
    type: 'DATE',
  },
  {
    name: 'Anniversary',
    key: 'anniversary',
    id: 'anniversary',
    render: (row: ReportRow) => formatDate(row.anniversary, 'MMM D'),
    sortable: true,
    filterable: true,
    type: 'DATE',
  },
  {
    name: 'Birthday',
    key: 'birthday',
    id: 'birthday',
    render: (row: ReportRow) => formatDate(row.birthday, 'MMM D'),
    sortable: true,
    filterable: true,
    type: 'DATE',
  },
  {
    name: 'Amount',
    key: 'total_amount',
    id: 'total_amount',
    render: (row: ReportRow) => formatMoney(row.total_amount || 0),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Store Credit',
    key: 'store_credit',
    id: 'store_credit',
    render: (row: ReportRow) => formatMoney(row.store_credit),
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Total Sales',
    key: 'grand_total',
    id: 'grand_total',
    render: (row: ReportRow) => formatMoney(row.grand_total || 0),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Transactions Amount',
    key: 'all_items_amount',
    id: 'all_items_amount',
    render: (row: any) => formatMoney(row.all_items_amount || 0),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Location',
    key: 'location_name',
    id: 'location_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson Name',
    key: 'salesperson',
    id: 'salesperson',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Ring Size',
    key: 'ring_size',
    id: 'ring_size',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Notes',
    key: 'notes',
    id: 'notes',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Warning Notes',
    key: 'warning_notes',
    id: 'warning_notes',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Client Source',
    key: 'marketing_source',
    id: 'marketing_source',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) =>
      row.marketing_source
        ? UnlocalizedMarketingChannelOptions.find((option: any) => option.value === row.marketing_source)?.label
        : '',
  },
  {
    name: 'Credit Plan',
    key: 'credit_plan_name',
    id: 'credit_plan_name',
    sortable: true,
    filterable: true,
  },
];
