import React, { createContext, useEffect, useRef, useState } from 'react';

import { isPreviewDeploy as checkIfPreviewDeploy, PreviewDeployBanner } from 'components/PreviewDeploy';

type Props = {
  children: JSX.Element;
};

type State = {
  isPreviewDeploy: boolean;
  previewBannerHeight: number;
};

const initialState: State = {
  isPreviewDeploy: false,
  previewBannerHeight: 0,
};

export const LayoutContext = createContext(initialState);

export const Layout = LayoutContext.Consumer;

export const LayoutProvider: React.FC<Props> = ({ children }) => {
  const isPreviewDeploy = checkIfPreviewDeploy();

  const bannerRef = useRef<HTMLDivElement>();
  const [bannerHeight, setBannerHeight] = useState(initialState.previewBannerHeight);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(event => {
      setBannerHeight(event[0].contentBoxSize[0].blockSize);
    });

    if (bannerRef?.current) {
      resizeObserver.observe(bannerRef.current);
    }
  }, [bannerRef]);

  return (
    <LayoutContext.Provider
      value={{
        isPreviewDeploy,
        previewBannerHeight: bannerHeight,
      }}
    >
      {children}

      <PreviewDeployBanner ref={bannerRef} />
    </LayoutContext.Provider>
  );
};
