import Cookies from 'js-cookie';

import { PREVIEW_DEPLOY_COOKIE_KEY } from 'components/PreviewDeploy';
import { handleIntercom } from 'integrations/intercom';
import { resetAllData } from 'redux/actions';
import { resetAllData as resetAllDataRefresh } from 'redux/slices/browseInventorySlice';
import { getStore } from 'redux/store';
import { baseApiV1 } from 'services/base-api-v1';
import { baseApiV2 } from 'services/base-api-v2';
import { LocalStorageKeys } from 'strings/local-storage';
import { IntercomFunction } from 'types/integrations';
import history from 'utils/history';

export const setDocumentTitle = (title: string) => {
  document.title = title;
};

export const getCurrentValue = (location, param, options, allowFallback = false) => {
  const subroutes = location.pathname.split('/');
  const route = subroutes[subroutes.length - 1];

  if (route === param) {
    return 1;
  }

  return (
    options.find(option => {
      const sublink = option.sublink;
      const split = sublink.split('/');

      if (split && split.length > 1) {
        if (split[0] === route) {
          return true;
        }
        const routeAdj = option.hasIdSegment ? 3 : 2;
        if (split[1] === '*') {
          const bucketRoute = subroutes[subroutes.length - routeAdj];
          if (split[0] === bucketRoute) {
            return true;
          }
          if (allowFallback) {
            return split[0] === subroutes[subroutes.length - (routeAdj + 1)];
          }
        }
      }

      return sublink === route;
    })?.value || 1
  );
};

export const getParam = (param: string) => {
  const params = new URLSearchParams(history.location.search);
  if (params.has(param)) {
    return params.get(param);
  }
};

export const setParam = (param: string, value: string | boolean | number) => {
  const params = new URLSearchParams(history.location.search);
  params.set(param, `${value}`);
  history.push(`?${params.toString()}`);
};

// remove all user cookies with the exception of `v` used for preview deploys
export const clearAuthCookies = () => {
  Object.keys(Cookies.get())
    .filter(cookie => cookie !== PREVIEW_DEPLOY_COOKIE_KEY)
    .forEach(cookie => {
      Cookies.remove(cookie, { path: '' });
    });
};

export const logoutUser = (reload = false) => {
  const store = getStore();

  store.dispatch(resetAllData());
  store.dispatch(resetAllDataRefresh());
  store.dispatch(baseApiV1.util.resetApiState());
  store.dispatch(baseApiV2.util.resetApiState());

  clearAuthCookies();

  Object.keys(localStorage).forEach((key: string) => {
    if (key !== LocalStorageKeys.NewPos) {
      localStorage.removeItem(key);
    }
  });

  handleIntercom([IntercomFunction.Shutdown]);

  history.push('/login');

  if (reload && process.env.WEB_ENV !== 'development') {
    window.location.reload();
  }
};
