import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const SALES_TAX_THRESHOLD_REPORT_FIELDS = [
  {
    name: 'State Name',
    id: 'state_name',
    key: 'state_name',
    isDefault: true,
    sortable: false,
    filterable: true,
  },
  {
    name: 'Effective Date',
    id: 'effective_date',
    key: 'effective_date',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatLocalDate(row.effective_date),
  },
  {
    name: 'Sales Limit',
    id: 'sales_limit',
    key: 'sales_limit',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.sales_limit),
  },
  {
    name: 'Transaction Limit',
    id: 'transaction_limit',
    key: 'transaction_limit',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'NUMBER',
    render: (row: ReportRow) => row.transaction_limit || 0,
  },
  {
    name: 'Total Sales',
    id: 'total_sales',
    key: 'total_sales',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: any) => (
      <TreeView
        aria-label='file system navigator'
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        <TreeItem
          label={
            <span style={{ fontWeight: 'bold' }}>
              {formatMoney(
                String(
                  Object.values(row.total_sales).reduce((accumulator: number, currentValue: number) => {
                    return accumulator + currentValue;
                  }, 0)
                )
              )}
            </span>
          }
          nodeId='0'
        >
          {Object.keys(row.total_sales).map((locationId: string) => {
            return (
              <TreeItem
                label={
                  <>
                    <span>
                      {`${row.locations.find((location: any) => String(location.id) === locationId).name} - `}
                    </span>
                    <span style={{ fontWeight: 'bold' }}>{formatMoney(row.total_sales[locationId])}</span>
                  </>
                }
                nodeId={locationId}
              />
            );
          })}
        </TreeItem>
      </TreeView>
    ),
  },
  {
    name: 'Total Transactions',
    id: 'total_transactions',
    key: 'total_transactions',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => (
      <TreeView
        aria-label='file system navigator'
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        <TreeItem
          label={
            <span style={{ fontWeight: 'bold' }}>
              {String(
                Object.values(row.total_transactions).reduce((accumulator: number, currentValue: number) => {
                  return accumulator + currentValue;
                }, 0)
              )}
            </span>
          }
          nodeId='0'
        >
          {Object.keys(row.total_transactions).map((locationId: string) => {
            return (
              <TreeItem
                label={
                  <>
                    <span>
                      {`${row.locations.find((location: any) => String(location.id) === locationId).name} - `}
                    </span>
                    <span style={{ fontWeight: 'bold' }}>{row.total_transactions[locationId]}</span>
                  </>
                }
                nodeId={locationId}
              />
            );
          })}
        </TreeItem>
      </TreeView>
    ),
  },
];
