import { getGiftCard } from 'redux/actions/giftCards';
import { _apiRequest } from 'redux/actions/system';
import {
  RESET_SEARCH_RESULTS,
  SEARCH_CLIENTS,
  SEARCH_GIFT_CARDS,
  SEARCH_HIDDEN_CLIENTS,
  SEARCH_INVOICES,
  SEARCH_ORDERS,
  SEARCH_PRODUCTS,
  SEARCH_PURCHASE_ORDERS,
  SEARCH_REPAIRS,
  SEARCH_SKUS,
  SEARCH_TRANSFERS,
  SEARCH_VENDORS,
  SEND_GIFT_CARD,
  SET_BRANDS_SEARCH,
  SET_CLIENT_SEARCH_FILTERS,
  SET_COLLECTIONS_SEARCH,
  SET_PRODUCT_SEARCH_FILTERS,
  SET_SEARCH_KEY,
  SET_SKU_SEARCH_FILTERS,
  SET_TRADE_IN_SEARCH,
} from 'redux/constants/action-types';

import { BrandsGetRequest } from 'components/Brands/brand-api';
import { ProductCollectionsGetRequest } from 'components/Collections/collection-api';
import { TradeInsGetRequest } from 'components/Orders/TradeIn/trade-in-api';
import { successReqActionType } from './helpers';

export const resetSearchResults = (searchType, searchFilter?: any) => ({
  type: RESET_SEARCH_RESULTS,
  searchType,
  searchFilter,
});

export const setSearchKey = value => ({
  type: SET_SEARCH_KEY,
  value,
});

export const searchVendors =
  (text, searchable?: boolean, detail = false) =>
  async dispatch =>
    _apiRequest({
      actionBase: SEARCH_VENDORS,
      dispatch,
      method: `${searchable ? 'Get' : 'Post'}`,
      path: `${searchable ? 'vendors?searchable=1' : 'vendor/search'}`,
      payload: { text, searchable, detail },
    });

export const searchTransfers = text => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_TRANSFERS,
    dispatch,
    method: 'Post',
    path: `transfer/search`,
    payload: { text },
  });

export const searchClients = (data, shouldSetFilters) => async dispatch => {
  await _apiRequest({
    actionBase: data.hidden ? SEARCH_HIDDEN_CLIENTS : SEARCH_CLIENTS,
    dispatch,
    method: 'Post',
    path: `client/search`,
    payload: data,
  });

  if (shouldSetFilters) {
    dispatch({
      type: SET_CLIENT_SEARCH_FILTERS,
      payload: data,
    });
  }
};

export const setClientSearchFilters = payload => ({
  type: SET_CLIENT_SEARCH_FILTERS,
  payload,
});

export const searchOrders =
  (text, customer_id = 0) =>
  async dispatch =>
    _apiRequest({
      actionBase: SEARCH_ORDERS,
      dispatch,
      method: 'Post',
      path: `order/search`,
      payload: { text, customer_id },
    });

export const searchGiftCards = text => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_GIFT_CARDS,
    dispatch,
    method: 'Post',
    path: `gift/search`,
    payload: { text },
  });

export const sendGiftToStoreCredit = payload => async dispatch =>
  _apiRequest({
    actionBase: SEND_GIFT_CARD,
    dispatch,
    method: 'Post',
    path: `gift/send`,
    payload,
    callback: () => {
      dispatch(getGiftCard(payload.gift_id));
    },
  });

export const searchInvoices = (text, is_return) => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_INVOICES,
    dispatch,
    method: 'Post',
    path: `invoice/search`,
    payload: { text, is_return },
  });

export const getInvoices = isReturn => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_INVOICES,
    dispatch,
    method: 'Get',
    path: `invoices?return=${isReturn ? 1 : 0}`,
  });

export const getPurchaseOrders = () => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_PURCHASE_ORDERS,
    dispatch,
    method: 'Get',
    path: `po`,
  });

export const searchPurchaseOrders = text => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_PURCHASE_ORDERS,
    dispatch,
    method: 'Post',
    path: `po/search`,
    payload: { text },
  });

export const searchSkus = (data, shouldSetFilters?: any) => async dispatch => {
  if (data == null) {
    dispatch({
      type: successReqActionType(SEARCH_SKUS),
      payload: [],
    });

    return;
  }

  if (data.price) {
    data.price = Number(data.price);
  }

  await _apiRequest({
    actionBase: SEARCH_SKUS,
    dispatch,
    method: 'Post',
    path: `sku/search`,
    payload: data,
  });

  if (!shouldSetFilters) return;

  dispatch({
    type: SET_SKU_SEARCH_FILTERS,
    payload: data,
  });
};

export const searchProducts = (payload, shouldSetFilters?: boolean, withSKU?: boolean) => async dispatch => {
  if (payload == null) {
    dispatch({
      type: successReqActionType(SEARCH_PRODUCTS),
      payload: [],
    });

    return;
  }

  await _apiRequest({
    actionBase: SEARCH_PRODUCTS,
    dispatch,
    method: 'Post',
    path: withSKU ? 'sku/search' : 'product/search',
    payload,
    onSuccess: () => {
      if (shouldSetFilters) {
        dispatch({
          type: SET_PRODUCT_SEARCH_FILTERS,
          payload,
        });
      }
    },
  });
};

export const searchRepairs = payload => async dispatch =>
  _apiRequest({
    actionBase: SEARCH_REPAIRS,
    dispatch,
    method: 'Post',
    path: `repairs/search`,
    payload,
  });

export const setTradeInSearchFilters = (payload: TradeInsGetRequest) => ({
  type: SET_TRADE_IN_SEARCH,
  payload,
});

export const setBrandsSearchFilters = (payload: BrandsGetRequest) => ({
  type: SET_BRANDS_SEARCH,
  payload,
});

export const setCollectionsSearchFilters = (payload: ProductCollectionsGetRequest) => ({
  type: SET_COLLECTIONS_SEARCH,
  payload,
});
