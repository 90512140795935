import { successReqActionType } from '../actions/helpers';
import {
  ADD_ROLE,
  EDIT_PERMISSION,
  EDIT_ROLE,
  EDIT_ROLE_PERMISSION,
  GET_PERMISSIONS,
  GET_ROLES,
  RESET_ALL_DATA,
  SET_USER,
} from '../constants/action-types';

const initialState = {
  roles: [],
  permissions: [],
};

const reducer = (state = initialState, action) => {
  const { roles, permissions } = state;
  const newPermissions = [...permissions];
  switch (action.type) {
    case successReqActionType(GET_ROLES):
      return {
        ...state,
        roles: action.payload,
      };
    case successReqActionType(EDIT_ROLE):
      const newRoles = [...roles];
      const newRoleIndex = newRoles.findIndex(role => role.id === action.payload.id);
      if (action.payload && action.payload.active === false) {
        newRoles.splice(newRoleIndex, 1);
      } else {
        newRoles.splice(newRoleIndex, 1, action.payload);
      }
      return {
        ...state,
        roles: newRoles,
      };
    case successReqActionType(ADD_ROLE):
      const addNewRoles = [...roles, action.payload];
      return {
        ...state,
        roles: addNewRoles,
      };
    case successReqActionType(GET_PERMISSIONS):
      return {
        ...state,
        permissions: action.payload,
      };
    case successReqActionType(EDIT_ROLE_PERMISSION):
      const newPermissionIndex = newPermissions.findIndex(permission => permission.id === action.payload.id);
      newPermissions.splice(newPermissionIndex, 1, action.payload);
      return {
        ...state,
        permissions: newPermissions,
      };
    case successReqActionType(EDIT_PERMISSION):
      const newIndex = newPermissions.findIndex(permission => permission.id === action.payload.id);
      newPermissions.splice(newIndex, 1, action.payload);
      return {
        ...state,
        permissions: newPermissions,
      };
    case SET_USER:
      return {
        ...state,
        permissions: action.payload.role.permissions,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
