import { Menu, MenuItem } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import Button from './Button';

interface TOption {
  action?: () => void;
  label: string;
}

interface SplitButtonProps {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  variant?: 'link' | 'primary' | 'secondary' | 'danger' | 'light' | 'dark';
  icon?: string | ReactNode;
  title: string;
  options: TOption[];
  href?: string;
  target?: string;
  block?: boolean;
  onClick?: () => void;
  size?: 'md' | 'sm' | 'lg';
  props?: any;
}

const SplitButton = ({
  className,
  children,
  disabled = false,
  variant = 'primary',
  /* eslint-disable-next-line */ // unsure what this prop ( block ) is used for
  block = false,
  icon,
  title,
  options,
  size = 'md',
  ...props
}: SplitButtonProps) => {
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | (EventTarget & HTMLAnchorElement) | null
  >(null);

  return (
    <div className='btn-group' onClick={() => setShow(false)}>
      <Button className={className} disabled={disabled} icon={icon} size={size} variant={variant} {...props}>
        {title}
      </Button>
      <Button
        className='dropdown-toggle dropdown-toggle-split'
        disabled={disabled}
        icon={icon}
        onClick={e => {
          e.stopPropagation();
          setShow(!show);

          if (!show) {
            setAnchorEl(e.currentTarget);
          }
        }}
        size={size}
        variant={variant}
      >
        <span className='sr-only'>Toggle Dropdown</span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={0}
      >
        {options &&
          options.map((option: TOption, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                const { action } = option;
                if (action) {
                  action();
                }
                setAnchorEl(null);
              }}
              style={{ minWidth: 200 }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export { SplitButton };
