import cx from 'clsx';
import React, {
  cloneElement,
  Component,
  DetailedReactHTMLElement,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
} from 'react';
import { deepMap } from '../tab-helpers/childrenDeepMap';
import { getPanelsCount, getTabsCount } from '../tab-helpers/count';
import { isTab, isTabList, isTabPanel } from '../tab-helpers/elementTypes';
import uuid from '../tab-helpers/uuid';

interface TElement {
  key?: string;
  type?: { tabsRole: string };
  props: { children: ReactNode };
}

function isNode(node: TElement | HTMLDivElement) {
  return node && 'getAttribute' in node;
}

// Determine if a node from event.target is a Tab element
function isTabNode(node: HTMLDivElement) {
  return node && isNode(node) && node.getAttribute('role') === 'tab';
}

// Determine if a tab node is disabled
function isTabDisabled(node: HTMLDivElement) {
  return isNode(node) && node.getAttribute('aria-disabled') === 'true';
}

let canUseActiveElement: boolean;

function determineCanUseActiveElement(environment: any) {
  const env = environment || (typeof window !== 'undefined' ? window : undefined);

  try {
    canUseActiveElement = !!(typeof env !== 'undefined' && env.document && env.document.activeElement);
  } catch (e) {
    // Work around for IE bug when accessing document.activeElement in an iframe
    // Refer to the following resources:
    // http://stackoverflow.com/a/10982960/369687
    // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/12733599
    canUseActiveElement = false;
  }
}

export interface UncontrolledTabsProps {
  children: ReactNode | TElement;
  direction?: 'rtl' | 'ltr';
  className?: string | string[];
  disabledTabClassName?: string;
  disableUpDownKeys?: boolean;
  selectedClassName?: string;
  disabledClassName?: string;
  domRef?: (node: ReactNode) => void;
  focus?: boolean;
  forceRenderTabPanel?: boolean;
  onSelect?: (index: number | null, selectedIndex?: number | null, event?: MouseEvent) => void;
  selectedIndex?: number | null;
  selectedTabClassName?: string;
  selectedTabPanelClassName?: string;
  environment?: any; //
}

interface UncontrolledTabsState {}

export default class UncontrolledTabs extends Component<UncontrolledTabsProps, UncontrolledTabsState> {
  /* eslint-disable-next-line */
  static defaultProps = {
    className: 'react-tabs',
    focus: false,
  };

  tabNodes: {
    [key: string | number]: HTMLDivElement;
  } = {};

  tabIds: any[] = [];

  node?: ReactNode;

  panelIds: any[] = [];

  setSelected(index: number, event: MouseEvent) {
    // Check index boundary
    if (index < 0 || index >= this.getTabsCount()) return;

    const { onSelect, selectedIndex } = this.props;

    // Call change event handler
    onSelect && onSelect(index, selectedIndex, event);
  }

  getNextTab(index: number | null) {
    const count = this.getTabsCount();
    index = Number(index);

    // Look for non-disabled tab from index to the last tab on the right
    for (let i = index + 1; i < count; i++) {
      if (!isTabDisabled(this.getTab(i) as HTMLDivElement)) {
        return i;
      }
    }

    // If no tab found, continue searching from first on left to index
    for (let i = 0; i < index; i++) {
      if (!isTabDisabled(this.getTab(i) as HTMLDivElement)) {
        return i;
      }
    }

    // No tabs are disabled, return index
    return index;
  }

  getPrevTab(index: number | null) {
    index = Number(index);
    let i = index;

    // Look for non-disabled tab from index to first tab on the left
    while (i--) {
      if (!isTabDisabled(this.getTab(i) as HTMLDivElement)) {
        return i;
      }
    }

    // If no tab found, continue searching from last tab on right to index
    i = this.getTabsCount();
    while (i-- > index) {
      if (!isTabDisabled(this.getTab(i) as HTMLDivElement)) {
        return i;
      }
    }

    // No tabs are disabled, return index
    return index;
  }

  getFirstTab() {
    const count = this.getTabsCount();

    // Look for non disabled tab from the first tab
    for (let i = 0; i < count; i++) {
      if (!isTabDisabled(this.getTab(i) as HTMLDivElement)) {
        return i;
      }
    }

    return null;
  }

  getLastTab() {
    let i = this.getTabsCount();

    // Look for non disabled tab from the last tab
    while (i--) {
      if (!isTabDisabled(this.getTab(i) as HTMLDivElement)) {
        return i;
      }
    }

    return null;
  }

  getTabsCount() {
    const { children } = this.props;
    return getTabsCount(children as TElement[]);
  }

  getPanelsCount() {
    const { children } = this.props;
    return getPanelsCount(children as TElement[]);
  }

  getTab(index: number) {
    return this.tabNodes[`tabs-${index}` as keyof number];
  }

  getChildren() {
    let index = 0;
    const {
      children,
      disabledTabClassName,
      focus,
      forceRenderTabPanel,
      selectedIndex,
      selectedTabClassName,
      selectedTabPanelClassName,
      environment,
    } = this.props;

    this.tabIds = this.tabIds || [];
    this.panelIds = this.panelIds || [];
    let diff = this.tabIds.length - this.getTabsCount();

    // Add ids if new tabs have been added
    // Don't bother removing ids, just keep them in case they are added again
    // This is more efficient, and keeps the uuid counter under control
    while (diff++ < 0) {
      this.tabIds.push(uuid());
      this.panelIds.push(uuid());
    }

    // Map children to dynamically setup refs
    return deepMap(children as DetailedReactHTMLElement<{ children: ReactNode }, HTMLElement>[], child => {
      let result = child as ReactElement;

      // Clone TabList and Tab components to have refs
      if (isTabList(child as TElement)) {
        let listIndex = 0;

        // Figure out if the current focus in the DOM is set on a Tab
        // If it is we should keep the focus on the next selected tab
        let wasTabFocused = false;

        if (canUseActiveElement == null) {
          determineCanUseActiveElement(environment);
        }

        if (canUseActiveElement) {
          /* eslint-disable-next-line */
          // @ts-ignore
          wasTabFocused = React.Children.toArray((child as TElement).props.children as typeof React.Children)
            .filter(isTab as (predicate: ReactNode) => void)
            .some((tab, i) => {
              const env = environment || (typeof window !== 'undefined' ? window : undefined);
              return env && env.document.activeElement === this.getTab(i);
            });
        }

        result = cloneElement(child as ReactElement, {
          children: deepMap(
            (child as TElement).props.children as DetailedReactHTMLElement<{ children: ReactNode }, HTMLElement>[],
            tab => {
              const key = `tabs-${listIndex}`;
              const selected = selectedIndex === listIndex;

              const props: {
                tabRef: (node: HTMLDivElement) => void;
                id: number | string;
                panelId: number | string;
                selected: boolean;
                focus: boolean;
                selectedClassName?: string;
                disabledClassName?: string;
              } = {
                tabRef: (node: HTMLDivElement) => {
                  this.tabNodes[key] = node as HTMLDivElement;
                },
                id: this.tabIds[listIndex],
                panelId: this.panelIds[listIndex],
                selected,
                focus: selected && (focus || wasTabFocused),
              };

              if (selectedTabClassName) props.selectedClassName = selectedTabClassName;
              if (disabledTabClassName) props.disabledClassName = disabledTabClassName;

              listIndex++;

              return cloneElement(tab as ReactElement, props);
            }
          ),
        });
      } else if (isTabPanel(child as TElement)) {
        const props: {
          id: number | string;
          tabId: number;
          selected: boolean;
          forceRender?: boolean;
          selectedClassName?: string;
        } = {
          id: this.panelIds[index],
          tabId: this.tabIds[index],
          selected: selectedIndex === index,
        };

        if (forceRenderTabPanel) props.forceRender = forceRenderTabPanel;
        if (selectedTabPanelClassName) props.selectedClassName = selectedTabPanelClassName;

        index++;

        result = cloneElement(child as ReactElement, props) as unknown as ReactElement;
      }

      return result;
    });
  }

  handleKeyDown = (e: KeyboardEvent) => {
    const { direction, disableUpDownKeys } = this.props;
    if (this.isTabFromContainer(e.target as HTMLDivElement)) {
      let { selectedIndex: index } = this.props;
      let preventDefault = false;
      let useSelectedIndex = false;

      if (e.keyCode === 32 || e.keyCode === 13) {
        preventDefault = true;
        useSelectedIndex = false;
        this.handleClick(e as unknown as MouseEvent);
      }

      if (e.keyCode === 37 || (!disableUpDownKeys && e.keyCode === 38)) {
        // Select next tab to the left, validate if up arrow is not disabled
        if (direction === 'rtl') {
          index = this.getNextTab(index as number);
        } else {
          index = this.getPrevTab(index as number);
        }
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 39 || (!disableUpDownKeys && e.keyCode === 40)) {
        // Select next tab to the right, validate if down arrow is not disabled
        if (direction === 'rtl') {
          index = this.getPrevTab(index as number);
        } else {
          index = this.getNextTab(index as number);
        }
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 35) {
        // Select last tab (End key)
        index = this.getLastTab();
        preventDefault = true;
        useSelectedIndex = true;
      } else if (e.keyCode === 36) {
        // Select first tab (Home key)
        index = this.getFirstTab();
        preventDefault = true;
        useSelectedIndex = true;
      }

      // This prevents scrollbars from moving around
      if (preventDefault) {
        e.preventDefault();
      }

      // Only use the selected index in the state if we're not using the tabbed index
      if (useSelectedIndex) {
        this.setSelected(index as number, e as unknown as MouseEvent);
      }
    }
  };

  handleClick = (e: MouseEvent) => {
    let node = e.target as ParentNode;
    do {
      if (node !== null && this.isTabFromContainer(node as HTMLDivElement)) {
        if (isTabDisabled(node as HTMLDivElement)) {
          return;
        }

        const index: number = ([] as any[]).slice
          .call((node.parentNode as ParentNode).children)
          .filter(isTabNode)
          .indexOf(node);
        this.setSelected(index, e);
        return;
      }
      node = node.parentNode as ParentNode;
    } while (node.parentNode != null);
  };

  /**
   * Determine if a node from event.target is a Tab element for the current Tabs container.
   * If the clicked element is not a Tab, it returns false.
   * If it finds another Tabs container between the Tab and `this`, it returns false.
   */
  isTabFromContainer(node: HTMLDivElement) {
    // return immediately if the clicked element is not a Tab.
    if (!isTabNode(node)) {
      return false;
    }

    // Check if the first occurrence of a Tabs container is `this` one.
    let nodeAncestor = node && node.parentElement;
    do {
      if (nodeAncestor === this.node) return true;
      if (nodeAncestor && nodeAncestor.getAttribute('data-tabs')) break;

      nodeAncestor = nodeAncestor && nodeAncestor.parentElement;
    } while (nodeAncestor);

    return false;
  }

  render() {
    // Delete all known props, so they don't get added to DOM
    const {
      children, // unused
      className,
      disabledTabClassName, // unused
      domRef,
      focus, // unused
      forceRenderTabPanel, // unused
      onSelect, // unused
      selectedIndex, // unused
      selectedTabClassName, // unused
      selectedTabPanelClassName, // unused
      environment, // unused
      disableUpDownKeys, // unused
      ...attributes
    } = this.props;

    return (
      <div
        {...attributes}
        ref={node => {
          /* eslint-disable-next-line */
          // @ts-ignore
          this.node = node;
          if (domRef) {
            /* eslint-disable-next-line */
            // @ts-ignore
            domRef(node);
          }
        }}
        className={cx(className)}
        data-tabs
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
      >
        {this.getChildren() as ReactNode}
      </div>
    );
  }
}
