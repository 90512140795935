import { keyBy } from 'lodash';
import { successReqActionType } from 'redux/actions/helpers';
import { Account } from 'types/api/account';
import { EcomProductRowType, EcomVendorProductStatus } from 'types/api/ecom-vendor-product-status';
import {
  GET_ECOM_VENDOR_PRODUCT_STATUSES,
  GET_VENDORS,
  UPDATE_ECOM_VENDOR_PRODUCT_STATUSES,
} from '../constants/action-types';

export interface EcomState {
  ecomVendorProductStatuses: EcomVendorProductStatus[];
  vendors: {
    [key: string]: Account;
  };
}

const initialState: EcomState = {
  ecomVendorProductStatuses: [],
  vendors: {},
};

const getProductQuantity = (statusRow, ecomVendorProductStatuses) => {
  return ecomVendorProductStatuses
    .filter(it => it.product_id === statusRow.product_id && it.sku_include_on_ecom)
    .reduce((prev, next) => {
      return prev + next.sku_quantity;
    }, 0);
};

const reducer = (state = initialState, action) => {
  const { ecomVendorProductStatuses, vendors } = state;
  switch (action.type) {
    case successReqActionType(GET_VENDORS):
      return { ...state, vendors: keyBy(action.payload, 'uuid') };
    case successReqActionType(GET_ECOM_VENDOR_PRODUCT_STATUSES):
      return {
        ...state,
        ecomVendorProductStatuses: action.payload,
      };
    case successReqActionType(UPDATE_ECOM_VENDOR_PRODUCT_STATUSES):
      const { include_on_ecom, account_uuid, product_ids, sku_uuid, allow_backorder, update_source } = action.payload;
      const updatedVendorProductStatuses = [...ecomVendorProductStatuses];

      switch (update_source) {
        case EcomProductRowType.Vendor:
          return {
            ...state,
            vendors: {
              ...vendors,
              [account_uuid]: { ...vendors[account_uuid], include_on_ecom: action.payload.include_on_ecom },
            },
          };
        case EcomProductRowType.Product:
          updatedVendorProductStatuses
            .filter(it => product_ids.includes(it.product_id) || product_ids.includes(it.variant_parent_id))
            .forEach(it => {
              it.product_include_on_ecom = include_on_ecom;
              it.sku_include_on_ecom = include_on_ecom;
            });
          updatedVendorProductStatuses
            .filter(it => product_ids.includes(it.product_id) || product_ids.includes(it.variant_parent_id))
            .forEach(it => {
              it.product_quantity = getProductQuantity(it, ecomVendorProductStatuses);
            });
          break;
        case EcomProductRowType.Sku:
          const skuItem = updatedVendorProductStatuses.find(it => it.sku_uuid === sku_uuid);
          if (skuItem) {
            skuItem.sku_include_on_ecom = include_on_ecom;
            const adj = include_on_ecom ? skuItem.sku_quantity : skuItem.sku_quantity * -1;
            const qty = skuItem.product_quantity + adj;
            updatedVendorProductStatuses
              .filter(it => it.product_id === skuItem.product_id)
              .forEach(it => {
                it.product_quantity = qty;
              });
          }
          break;
        case EcomProductRowType.ProductBackorder:
          updatedVendorProductStatuses
            .filter(it => product_ids.includes(it.product_id))
            .forEach(it => {
              it.product_allow_backorder = allow_backorder;
            });
          break;
        default:
          break;
      }
      return {
        ...state,
        ecomVendorProductStatuses: updatedVendorProductStatuses,
      };
    default:
      return state;
  }
};

export default reducer;
