import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const VENDOR_REPORT_FIELDS = [
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Avg. Retail',
    id: 'avg_retail',
    key: 'avg_retail',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.avg_retail),
  },
  {
    name: 'Amount Sold',
    id: 'sold_amount',
    key: 'sold_amount',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.sold_amount),
  },
  {
    name: 'Avg. DTS',
    id: 'avg_dts',
    key: 'avg_dts',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Sold Qty.',
    id: 'sold_qty',
    key: 'sold_qty',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'COGS',
    id: 'cogs',
    key: 'cogs',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.cogs),
  },
  {
    name: 'Avg. COGS',
    id: 'avg_cogs',
    key: 'avg_cogs',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.avg_cogs),
  },
  {
    name: 'Cost On Hand',
    id: 'cost_on_hand',
    key: 'cost_on_hand',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.cost_on_hand),
  },
  {
    name: 'On Hand Qty.',
    id: 'on_hand_qty',
    key: 'on_hand_qty',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Avg. DOH',
    id: 'avg_doh',
    key: 'avg_doh',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Avg. Cost On Hand',
    id: 'avg_cost_on_hand',
    key: 'avg_cost_on_hand',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.avg_cost_on_hand),
  },
  {
    name: 'Turnover',
    id: 'turnover',
    key: 'turnover',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Aged Cost',
    id: 'aged_cost',
    key: 'aged_cost',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.aged_cost),
  },
  {
    name: 'Aged Qty.',
    id: 'aged_qty',
    key: 'aged_qty',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: '% Aged Stock',
    id: 'aged_pct',
    key: 'aged_pct',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Avg. Aged DOH',
    id: 'avg_aged_doh',
    key: 'avg_aged_doh',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
];
