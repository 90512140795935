import { _apiRequest } from 'redux/actions/system';
import {
  ADD_MARKETING_SOURCE,
  ADD_ORDER_CODE,
  ADD_PAYMENT_METHOD,
  ADD_PRODUCT_ATTRIBUTE,
  ADD_PRODUCT_ATTRIBUTE_VALUE,
  ADD_SKU_CATEGORY,
  ADD_USER,
  ADD_VENDOR,
  ADD_VENDOR_ADDRESS,
  ADD_VENDOR_EMAIL,
  ADD_VENDOR_PHONE,
  CREATE_JOB_TYPE,
  CREATE_ORDER_TYPE,
  CREATE_PAYMENT_TERM,
  CREATE_PRODUCT_TYPE,
  DEACTIVATE_PRODUCT_ATTRIBUTE,
  EDIT_MARKETING_SOURCE,
  EDIT_ORDER_CODE,
  EDIT_PAYMENT_METHOD,
  EDIT_SKU_CATEGORY,
  EDIT_USER,
  EDIT_VENDOR,
  EDIT_VENDOR_ADDRESS,
  EDIT_VENDOR_EMAIL,
  EDIT_VENDOR_PHONE,
  EDIT_VENDOR_PRIMARY_FIELD,
  GET_ATTRIBUTES,
  GET_JM_PRODUCT_MAP,
  GET_JM_STORE_CODES,
  GET_JOB_TYPES,
  GET_MARKETING_SOURCES,
  GET_ORDER_CODE,
  GET_ORDER_CODES,
  GET_ORDER_TYPES,
  GET_ORGANIZATION,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_ALL,
  GET_PAYMENT_TERMS,
  GET_PRICE_CHANGES,
  GET_PRODUCT_TYPES,
  GET_SKU_CATEGORIES,
  GET_TIME_CLOCK,
  GET_USERS,
  GET_VENDOR,
  GET_VENDORS,
  GET_VENDOR_MEMO_ITEMS,
  GET_VENDOR_MERCH_HISTORY,
  GET_VENDOR_PRODUCTS,
  GET_VENDOR_PURCHASE_HISTORY,
  GET_VENDOR_TRANSACTIONS,
  REMOVE_PRODUCT_ATTRIBUTE_VALUE,
  SET_CARD_DATA,
  SET_ORGANIZATION,
  TOGGLE_JM_INTEGRATION,
  TOGGLE_TIME_CLOCK,
  UPDATE_JM_PRODUCT_MAP,
  UPDATE_JOB_TYPE,
  UPDATE_ORDER_TYPE,
  UPDATE_PAYMENT_TERM,
  UPDATE_PRODUCT_ATTRIBUTE_SORT_ORDER,
  UPDATE_PRODUCT_ATTRIBUTE_VALUES,
  UPDATE_PRODUCT_ATTRIBUTE_VARIANT_STATUS,
  UPDATE_PRODUCT_TYPE,
} from 'redux/constants/action-types';
import { ProductAttributeRequest } from 'types/api/attribute';
import history from 'utils/history';
import * as Request from 'utils/request';

export const getPaymentMethods =
  (all = false) =>
  async dispatch =>
    _apiRequest({
      actionBase: all ? GET_PAYMENT_METHODS_ALL : GET_PAYMENT_METHODS,
      dispatch,
      method: 'Get',
      path: `payments?all=${all}`,
    });

export const getPaymentMethod = id => async dispatch =>
  _apiRequest({
    actionBase: GET_PAYMENT_METHOD,
    dispatch,
    method: 'Get',
    path: `payments?id=${id}`,
  });

export const editPaymentMethod = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_PAYMENT_METHOD,
    dispatch,
    method: 'Put',
    path: `payments`,
    payload,
    returnPayloadOnSuccess: true,
  });

export const addPaymentMethod = payload => async dispatch => {
  await _apiRequest({
    actionBase: ADD_PAYMENT_METHOD,
    dispatch,
    method: 'Post',
    path: `payments`,
    payload,
  });
};

export const setCardData = payload => ({
  type: SET_CARD_DATA,
  payload,
});

export const getVendors = searchable => async dispatch =>
  _apiRequest({
    actionBase: GET_VENDORS,
    dispatch,
    method: 'Get',
    path: `vendors?searchable=${searchable || 0}`,
  });

export const getVendor = (id, cb?: (data: any) => void) => async dispatch =>
  _apiRequest({
    actionBase: GET_VENDOR,
    dispatch,
    method: 'Get',
    path: `vendor?id=${id}`,
    callback: data => {
      cb && cb(data);
    },
  });

export const editVendor = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_VENDOR,
    dispatch,
    method: 'Put',
    path: `vendor`,
    payload,
    returnPayloadOnSuccess: true,
    handleStatus: status => {
      switch (status) {
        case 400:
          window.showNotification(
            'error',
            'Error updating vendor',
            'Please make sure the code you provided does not already exist.'
          );
          break;
        case 409:
          window.showNotification(
            'error',
            'Stock #s still in stock',
            'There are still Stock #s in stock for this vendor.'
          );
          break;
        default:
          break;
      }
    },
    onSuccess: () => {
      window.showSuccess('Saved');
    },
  });

export const addVendor = (vendor, cb) => async dispatch =>
  _apiRequest({
    actionBase: ADD_VENDOR,
    dispatch,
    method: 'Post',
    payload: vendor,
    path: `vendor`,
    callback: data => {
      cb && cb();
      if (data) {
        const { uuid } = data;
        if (!uuid) return;

        history.push(`/accounts/${uuid}`);
      }
    },
  });

export const getVendorTransactions = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_VENDOR_TRANSACTIONS,
    dispatch,
    method: 'Get',
    path: `vendor/orders?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const getVendorMerchHistory = id => async dispatch =>
  _apiRequest({
    actionBase: GET_VENDOR_MERCH_HISTORY,
    dispatch,
    method: 'Get',
    path: `vendor/merch?id=${id}`,
    metaDispatch: { uuid: id },
  });

export const getVendorPurchaseHistory = id => async dispatch =>
  _apiRequest({
    actionBase: GET_VENDOR_PURCHASE_HISTORY,
    dispatch,
    method: 'Get',
    path: `vendor/po?id=${id}`,
    metaDispatch: { uuid: id },
  });

export const getVendorMemoItems = id => async dispatch =>
  _apiRequest({
    actionBase: GET_VENDOR_MEMO_ITEMS,
    dispatch,
    method: 'Get',
    path: `vendor/memo?id=${id}`,
    metaDispatch: { uuid: id },
  });

export const getVendorProducts = id => async dispatch =>
  _apiRequest({
    actionBase: GET_VENDOR_PRODUCTS,
    dispatch,
    method: 'Get',
    path: `vendor/products?id=${id}`,
    metaDispatch: { uuid: id },
  });

export const addVendorEmail = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_VENDOR_EMAIL,
    dispatch,
    method: 'Post',
    payload,
    path: `vendor/email`,
    notification: 'Account email added',
    metaDispatch: { uuid: payload.uuid },
  });

export const editVendorEmail = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_VENDOR_EMAIL,
    dispatch,
    method: 'Put',
    payload,
    path: `vendor/email`,
    returnPayloadOnSuccess: true,
    notification: 'Account email updated',
  });

export const addVendorPhone = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_VENDOR_PHONE,
    dispatch,
    method: 'Post',
    payload,
    path: `vendor/phone`,
    notification: 'Account phone added',
    metaDispatch: { uuid: payload.uuid },
  });

export const editVendorPhone = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_VENDOR_PHONE,
    dispatch,
    method: 'Put',
    payload,
    path: `vendor/phone`,
    returnPayloadOnSuccess: true,
    notification: 'Account phone updated',
  });

export const addVendorAddress = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_VENDOR_ADDRESS,
    dispatch,
    method: 'Post',
    payload,
    path: `vendor/address`,
    notification: 'Account address added',
    metaDispatch: { uuid: payload.uuid },
  });

export const editVendorAddress = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_VENDOR_ADDRESS,
    dispatch,
    method: 'Put',
    payload,
    path: `vendor/address`,
    returnPayloadOnSuccess: true,
    notification: 'Account address updated',
  });

export const editVendorPrimaryField = payload => ({
  type: EDIT_VENDOR_PRIMARY_FIELD,
  payload,
});

export const getProductTypes = () => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT_TYPES,
    dispatch,
    method: 'Get',
    path: `types`,
  });

export const createProductType = payload => async dispatch =>
  _apiRequest({
    actionBase: CREATE_PRODUCT_TYPE,
    dispatch,
    method: 'Post',
    path: `type`,
    payload,
  });

export const updateProductType = payload => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_PRODUCT_TYPE,
    dispatch,
    method: 'Put',
    path: `type`,
    payload,
    returnPayloadOnSuccess: true,
  });

export const getOrganization = () => async dispatch =>
  _apiRequest({
    actionBase: GET_ORGANIZATION,
    dispatch,
    method: 'Get',
    path: `org`,
  });

export const setOrganization = payload => async dispatch => {
  dispatch({
    type: SET_ORGANIZATION,
    payload,
  });
};

export const getUsers = advanced => async dispatch =>
  _apiRequest({
    actionBase: GET_USERS,
    dispatch,
    method: 'Get',
    path: `users?advanced=${advanced}`,
  });

export const editUser = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_USER,
    dispatch,
    method: 'Put',
    path: `user`,
    payload,
    returnPayloadOnSuccess: true,
    onSuccess: () => {
      window.showSuccess('Saved');
    },
  });

export const editUserTableDefinitions = (user: any, key: string, keys: string[]) => async dispatch => {
  if (keys.length === 0) {
    return;
  }

  const newDefinitions = user.table_definitions || [];

  const definition = newDefinitions.find(d => d.key === key);

  const newDefinition = {
    key,
    fields: keys,
  };

  if (definition) {
    const index = newDefinitions.findIndex(i => i.key === key);

    if (newDefinitions[index].fields === keys) {
      // No change to existing definition so no need to update
      return;
    }

    newDefinitions[index] = newDefinition;
  } else {
    // No definition found for this table, add a new one
    newDefinitions.push(newDefinition);
  }

  const payload = {
    id: user.id,
    table_definitions: JSON.stringify(newDefinitions),
  };

  return _apiRequest({
    actionBase: EDIT_USER,
    dispatch,
    method: 'Put',
    path: `user/table-definitions`,
    payload,
    returnPayloadOnSuccess: true,
  });
};

export const addUser = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_USER,
    dispatch,
    method: 'Post',
    path: `user`,
    payload,
  });

export const getProductAttributes = (vendorId, general) => async dispatch =>
  _apiRequest({
    actionBase: GET_ATTRIBUTES,
    dispatch,
    method: 'Get',
    path: `product/attributes?type=${vendorId}&general=${general}`,
    metaDispatch: { vendorId },
  });

export const addProductAttribute = (vendorId, name, sortOrder, general) => async dispatch => {
  const payload: ProductAttributeRequest = {
    name,
    sortOrder,
  };

  if (general && general === 1) {
    payload.type = vendorId;
  } else {
    payload.id = vendorId;
  }

  return _apiRequest({
    actionBase: ADD_PRODUCT_ATTRIBUTE,
    dispatch,
    method: 'Post',
    path: `product/attribute`,
    payload,
    metaDispatch: { vendorId },
  });
};

export const deactivateProductAttribute = (vendorId, attrId) => async dispatch =>
  _apiRequest({
    actionBase: DEACTIVATE_PRODUCT_ATTRIBUTE,
    dispatch,
    method: 'Put',
    path: 'product/attribute',
    payload: {
      id: Number(attrId),
      active: false,
    },
    metaDispatch: { vendorId, attrId },
  });

export const addProductAttributeValue = (vendorId, attrId, newValue) => async (dispatch, getState) => {
  const currentProductAttributeSet = getState().productAttributes[vendorId].find(attrSet => attrSet.id === attrId);

  return _apiRequest({
    actionBase: ADD_PRODUCT_ATTRIBUTE_VALUE,
    dispatch,
    method: 'Put',
    path: `product/attribute`,
    payload: {
      id: Number(attrId),
      values: [...currentProductAttributeSet.values, newValue],
    },
    returnPayloadOnSuccess: true,
    metaDispatch: { vendorId, attrId, newValue },
  });
};

export const updateProductAttributeValues = (vendorId, attrId, values) => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_PRODUCT_ATTRIBUTE_VALUES,
    dispatch,
    method: 'Put',
    path: `product/attribute`,
    payload: {
      id: Number(attrId),
      values,
    },
    returnPayloadOnSuccess: true,
    metaDispatch: { vendorId, attrId },
  });

export const updateProductAttributeVariantStatus = (vendorId, attrId, isVariant) => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_PRODUCT_ATTRIBUTE_VARIANT_STATUS,
    dispatch,
    method: 'Put',
    path: `product/attribute`,
    payload: {
      id: Number(attrId),
      is_variant_attribute: isVariant,
    },
    returnPayloadOnSuccess: true,
    metaDispatch: { attrId, vendorId },
  });

export const removeProductAttributeValue = (vendorId, attrId, value) => async (dispatch, getState) => {
  const currentProductAttributeSet = getState().productAttributes[vendorId].find(attrSet => attrSet.id === attrId);

  return _apiRequest({
    actionBase: REMOVE_PRODUCT_ATTRIBUTE_VALUE,
    dispatch,
    method: 'Put',
    path: `product/attribute`,
    payload: {
      id: Number(attrId),
      values: currentProductAttributeSet.values.filter(v => v !== value),
    },
    returnPayloadOnSuccess: true,
    metaDispatch: { vendorId, attrId, value },
  });
};

export const updateProductAttributeSortOrder = (vendorId, sortOrders) => async dispatch => {
  await Promise.all(
    sortOrders.map(so =>
      _apiRequest({
        actionBase: 'DUMMY',
        dispatch,
        method: 'Put',
        payload: {
          id: Number(so.id),
          sortOrder: so.sortOrder,
        },
        path: `product/attribute`,
        returnPayloadOnSuccess: true,
      })
    )
  );
  dispatch({
    type: UPDATE_PRODUCT_ATTRIBUTE_SORT_ORDER,
    payload: {
      sortOrders,
      vendorId,
    },
  });
};

export const getSkuCategories = () => async dispatch =>
  _apiRequest({
    actionBase: GET_SKU_CATEGORIES,
    dispatch,
    method: 'Get',
    path: `sku/categories`,
  });

export const addSkuCategory = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_SKU_CATEGORY,
    dispatch,
    method: 'Post',
    path: `sku/category`,
    payload,
  });

export const editSkuCategory = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_SKU_CATEGORY,
    dispatch,
    method: 'Put',
    path: `sku/category`,
    payload,
    returnPayloadOnSuccess: true,
  });

export const getOrderCodes = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_ORDER_CODES,
    dispatch,
    method: 'Get',
    path: 'order/codes',
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const getOrderCode = orderCodeId => async dispatch => {
  await _apiRequest({
    actionBase: GET_ORDER_CODE,
    dispatch,
    method: 'Get',
    path: `order/codes?id=${orderCodeId}`,
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const editOrderCode = orderCode => async dispatch => {
  await _apiRequest({
    actionBase: EDIT_ORDER_CODE,
    dispatch,
    method: 'Put',
    path: `order/code`,
    payload: {
      id: Number(orderCode.id),
      hidden: orderCode.hidden,
      active: orderCode.active,
      name: orderCode.name,
    },
    returnPayloadOnSuccess: true,
    notification: 'Order Code updated',
  });
};

export const addOrderCode = orderCode => async dispatch => {
  await _apiRequest({
    actionBase: ADD_ORDER_CODE,
    dispatch,
    method: 'Post',
    path: `order/code`,
    payload: orderCode,
    returnPayloadOnSuccess: false,
    notification: 'Order Code created',
  });
};

export const getMarketingSources = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_MARKETING_SOURCES,
    dispatch,
    method: 'Get',
    path: 'admin/source',
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const editMarketingSource = payload => async dispatch => {
  await _apiRequest({
    actionBase: EDIT_MARKETING_SOURCE,
    dispatch,
    method: 'Put',
    path: `admin/source/${payload.id}`,
    payload,
    returnPayloadOnSuccess: true,
  });
};

export const addMarketingSource = payload => async dispatch => {
  await _apiRequest({
    actionBase: ADD_MARKETING_SOURCE,
    dispatch,
    method: 'Post',
    path: `admin/source`,
    payload,
    returnPayloadOnSuccess: false,
  });
};

export const getOrderTypes = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_ORDER_TYPES,
    dispatch,
    method: 'Get',
    path: 'order/types',
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const updateOrderType = payload => async dispatch => {
  await _apiRequest({
    actionBase: UPDATE_ORDER_TYPE,
    dispatch,
    method: 'Put',
    path: `order/type`,
    payload,
    returnPayloadOnSuccess: true,
    notification: 'Transaction Type updated',
  });
};

export const createOrderType = payload => async dispatch => {
  await _apiRequest({
    actionBase: CREATE_ORDER_TYPE,
    dispatch,
    method: 'Post',
    path: `order/type`,
    payload,
    returnPayloadOnSuccess: false,
    notification: 'Transaction Type created',
  });
};

export const getPaymentTerms = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_PAYMENT_TERMS,
    dispatch,
    method: 'Get',
    path: 'payment/terms',
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const updatePaymentTerm = payload => async dispatch => {
  await _apiRequest({
    actionBase: UPDATE_PAYMENT_TERM,
    dispatch,
    method: 'Put',
    path: `payment/term`,
    payload,
    returnPayloadOnSuccess: true,
    notification: 'Payment Term updated',
  });
};

export const createPaymentTerm = payload => async dispatch => {
  await _apiRequest({
    actionBase: CREATE_PAYMENT_TERM,
    dispatch,
    method: 'Post',
    path: `payment/term`,
    payload,
    returnPayloadOnSuccess: false,
    notification: 'Payment Term created',
  });
};

export const getJobTypes = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_JOB_TYPES,
    dispatch,
    method: 'Get',
    path: 'job/types',
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const updateJobType = payload => async dispatch => {
  await _apiRequest({
    actionBase: UPDATE_JOB_TYPE,
    dispatch,
    method: 'Put',
    path: `job/type`,
    payload,
    returnPayloadOnSuccess: true,
    notification: 'Job Type updated',
  });
};

export const createJobType = payload => async dispatch => {
  await _apiRequest({
    actionBase: CREATE_JOB_TYPE,
    dispatch,
    method: 'Post',
    path: `job/type`,
    payload,
    returnPayloadOnSuccess: false,
    notification: 'Job Type created',
  });
};

export const getPriceChangeLogs = () => async dispatch =>
  _apiRequest({
    actionBase: GET_PRICE_CHANGES,
    dispatch,
    method: 'Get',
    path: `admin/sku-price-changes`,
  });

export const resetPassword = (email, cb?: (data: any) => void) => async () => {
  const response = await Request.PostNoAuth('forgot', { email });

  // response handler, mostly for errors
  cb && cb(response);

  return response;
};

export const validateToken = (tokenId, cb: (data: any) => void) => async () => {
  // launch request
  const response = await Request.PostNoAuth('forgot/validate-token', {
    tokenId,
  });

  // get response back
  cb(response);

  return response;
};

export const resetPasswordStepTwo = payload => async () => {
  const response = await Request.PostNoAuth('forgot-step-two', payload);
  if (response.error) {
    // print error message from backend
    window.showError(response.error);
    // exit
    return;
  }

  window.showSuccess('Password reset successful');
  history.push('/login');
};

export const getJmStoreCodes = () => async dispatch =>
  _apiRequest({
    actionBase: GET_JM_STORE_CODES,
    dispatch,
    method: 'Get',
    path: `jm/store-codes`,
  });

export const getJmProductMap = () => async dispatch =>
  _apiRequest({
    actionBase: GET_JM_PRODUCT_MAP,
    dispatch,
    method: 'Get',
    path: `jm/product-map`,
  });

export const updateJmProductMap = payload => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_JM_PRODUCT_MAP,
    dispatch,
    method: 'Put',
    payload,
    path: `jm/product-map`,
  });

export const toggleJmIntegration = payload => async dispatch =>
  _apiRequest({
    actionBase: TOGGLE_JM_INTEGRATION,
    dispatch,
    method: 'Post',
    payload,
    path: `jm/toggle-active`,
  });

export const toggleTimeClock = (time: string) => async dispatch => {
  return _apiRequest({
    actionBase: TOGGLE_TIME_CLOCK,
    dispatch,
    method: 'Post',
    path: `user/time-clock`,
    payload: {
      time,
    },
    returnPayloadOnSuccess: false,
  });
};

export const getUserTimeClock = (userId: number) => async dispatch => {
  return _apiRequest({
    actionBase: GET_TIME_CLOCK,
    dispatch,
    method: 'Get',
    path: `user/time-clock?id=${userId}`,
    payload: undefined,
    returnPayloadOnSuccess: false,
  });
};
