import { keyBy, sortBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import {
  GET_INVENTORY_SNAPSHOT,
  GET_INVENTORY_SNAPSHOTS,
  RESET_ALL_DATA,
  RESET_SNAPSHOT,
  SET_INVENTORY_SNAPSHOT_FILTER,
  UPDATE_INVENTORY_SNAPSHOT,
} from '../constants/action-types';

const initialState = {
  snapshots: {},
  snapshot: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_INVENTORY_SNAPSHOTS):
      return {
        ...state,
        snapshots: {
          ...keyBy(action.payload, 'uuid'),
        },
      };
    case successReqActionType(GET_INVENTORY_SNAPSHOT):
      const getVendors = items => {
        const allVendors = items.reduce((allVendors, el) => {
          if (!allVendors[el.account_id]) {
            allVendors[el.account_id] = {
              id: el.account_id,
              name: el.vendor_name,
            };
          }

          return allVendors;
        }, {});

        return sortBy(Object.values(allVendors), v => v.name);
      };
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          ...action.payload,
          vendors: getVendors(action.payload.items),
        },
      };
    case SET_INVENTORY_SNAPSHOT_FILTER:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          filters: {
            ...state.snapshot.filters,
            ...action.payload,
          },
        },
      };
    case successReqActionType(UPDATE_INVENTORY_SNAPSHOT):
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          ...action.payload,
        },
      };
    case RESET_SNAPSHOT:
      return {
        ...state,
        snapshot: {},
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
