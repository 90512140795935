import { Slider as MuiSlider } from '@mui/material';
import { SliderProps as MuiSliderProps } from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { Theme } from 'styles/theme';
import { BaseProps } from './props';

export interface SliderProps extends BaseProps, Omit<MuiSliderProps, 'name'> {}

const PrettoSlider = withStyles({
  root: {
    color: Theme.colors.primary.base,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  markLabel: {
    fontSize: '0.65rem',
  },
})(MuiSlider);

const Slider = ({ name, rules, ...rest }: SliderProps) => {
  const { getValues, control } = useFormContext();

  const defaultValue = getValues()[name];

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { name, onBlur, onChange, value } }) => (
        <PrettoSlider
          name={name}
          onBlur={onBlur}
          onChange={(e: Event, value: number | number[]) => onChange(value)}
          // onChange={(e: Event, value: number | number[], activeThumb: number) => onChange(value)}
          value={value}
          {...rest}
        />
      )}
      rules={rules}
    />
  );
};

export default Slider;
