import cx from 'clsx';
import { MouseEvent, ReactNode } from 'react';

interface TStep {
  id: number;
  content: string | number | ReactNode;
  onClick?: (e: MouseEvent) => void;
}

export interface ButtonStepsProps {
  className?: string;
  steps: TStep[];
  current?: number;
  children?: ReactNode;
  props?: any;
}

const ButtonSteps = ({ className, current = 0, ...props }: ButtonStepsProps) => {
  const steps: TStep[] = props.steps || [];

  const renderStep = (step: TStep) => (
    <div key={step.id} className={cx('step', step.id === current && `active`)} onClick={step.onClick}>
      {step.content}
    </div>
  );

  return (
    <div className={cx('btn-steps', className)} {...props}>
      {steps.map((step: TStep) => renderStep(step))}
    </div>
  );
};

export default ButtonSteps;
