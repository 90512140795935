import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const Page = styled.div({
  backgroundColor: 'rgba(27, 31, 35, 0.05)',
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
});

const Wrapper = styled.div({
  maxWidth: 800,
  width: '90%',
  margin: '5vh auto 0',
  fontSize: 12,

  '@media only screen and (min-width: 750px)': {
    fontSize: 16,
  },
});

const Code = styled.pre({
  backgroundColor: '#fff',
  padding: '1em',
  borderRadius: 10,
});

const BackButton = styled.button({
  left: 16,
  top: 16,
  position: 'relative',
  width: '3em',
  height: '3em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  backgroundColor: 'transparent',
  border: 0,

  '&:hover': {
    backgroundColor: '#fff !important',
  },
});

const BuildMeta = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <BackButton onClick={() => navigate(-1)}>
        <i className='fa-solid fa-chevron-left back-link' />
      </BackButton>
      <Wrapper>
        <Code>{JSON.stringify(global.build, null, 2)}</Code>
      </Wrapper>
    </Page>
  );
};

export default BuildMeta;
