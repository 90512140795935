export const PREVIEW_DEPLOY_BANNER_HEIGHT = 60;

export const PREVIEW_DEPLOY_COOKIE_KEY = 'v';

export const COLORS = {
  light: '#c9d1d9',
  dark: '#0d1117',
  markdownBg: 'rgba(110,118,129,0.4)',
};

export const FONTS = {
  default:
    '"Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
  monospace: 'ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace',
};
