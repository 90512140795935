import { CSSProperties } from 'react';
import Button from './Button';

export interface SearchBoxProps {
  className?: string;
  buttonText: string | Element | JSX.Element;
  buttonAt?: string;
  style?: CSSProperties;
  buttonVariant?: 'link' | 'primary' | 'secondary' | 'danger' | 'light' | 'dark' | undefined;
  onChange?: (e: any) => void;
  onSearch?: () => void;
  onClick?: () => void;
  placeholder: string;
  size?: string;
}

const SearchBox = ({
  className,
  buttonText,
  buttonAt,
  style,
  buttonVariant,
  onChange,
  onSearch,
  onClick,
  size,
  placeholder,
}: SearchBoxProps) => {
  // buttonAt: 'front', 'back'
  // size: 'sm', 'md', 'lg'

  const searchButton = (
    <Button onClick={onSearch} variant={buttonVariant || 'dark'}>
      {buttonText || 'Search'}
    </Button>
  );

  return (
    <div className={`input-group input-group-${size || 'md'} ${className || ''}`} onClick={onClick} style={style || {}}>
      {buttonAt === 'front' ? <div className='input-group-prepend'>{searchButton}</div> : ''}
      <input
        className='form-control'
        onChange={onChange}
        onKeyPress={ev => {
          if (ev.key === 'Enter') {
            onSearch && onSearch();
            ev.preventDefault();
          }
        }}
        placeholder={placeholder || 'Search...'}
        type='text'
      />
      {buttonAt === 'back' || !buttonAt ? <div className='input-group-append'>{searchButton}</div> : ''}
    </div>
  );
};

export default SearchBox;
