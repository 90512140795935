import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MUIDateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { OutlinedInputProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import Theme from './CrystalMUITheme';

interface DateTimePickerProps {
  className?: string;
  value: null | Moment;
  onChange?: null | ((date: Moment | null) => void) | Dispatch<SetStateAction<null>>;
  props?: any;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  size?: string;
  minDate?: Moment;
  minTime?: Moment;
}

interface TSize {
  [key: string]: 'small' | 'medium' | undefined;
}

export default function DateTimePicker({
  className = '',
  size = 'md',
  value = null,
  onChange = null,
  label = '',
  readOnly,
  disabled,
  minDate,
  minTime,
  ...props
}: DateTimePickerProps) {
  const [pickerStatus, setPickerStatus] = React.useState(false);

  const sizes: TSize = {
    sm: 'small',
    md: 'medium',
  };

  // NOTE: keyboardInputValue removed due to being unused
  // const onChangeDate = (date: Date | null, keyboardInputValue?: string) => {
  const onChangeDate = (date: Date | null) => {
    if (date) {
      const newDate = moment(date);
      onChange && onChange(newDate as ((prevState: null) => null) & Moment);
    } else {
      onChange && onChange(null);
    }
  };

  const newValue = value ? moment(value).toDate() : null;

  const resetDate = (e: MouseEvent) => {
    e.stopPropagation();
    onChange && onChange(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={Theme}>
        <MUIDateTimePicker
          disableMaskedInput
          inputFormat='M/d/yyyy p'
          label=''
          minDate={minDate?.toDate()}
          minTime={minTime?.toDate()}
          onChange={onChangeDate}
          onClose={() => setPickerStatus(false)}
          open={pickerStatus}
          renderInput={params => (
            <FormControl className={`form-date-control ${className} ${size}`} size={sizes[size]} variant='outlined'>
              <InputLabel>{label || 'DateTimePicker'}</InputLabel>
              <OutlinedInput
                onClick={() => !(readOnly || disabled) && setPickerStatus(true)}
                {...(params as OutlinedInputProps)}
                endAdornment={
                  <InputAdornment position='end'>
                    {value && (
                      <IconButton aria-label='reset time' onClick={resetDate}>
                        <i className='far fa-times-circle' />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
          value={newValue}
          {...props}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
}
