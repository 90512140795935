import { keyBy } from 'lodash';
import moment from 'moment';
import { OrderTypeKey } from 'types/api/order';

import { successReqActionType } from '../actions/helpers';
import {
  ADD_ATTACHMENT_TO_ORDER,
  ADD_PM_TO_ORDER,
  ADD_SKU_TO_ORDER,
  ADD_STATUS_UPDATE_TO_ORDER,
  ADD_TRADE_IN_TO_ORDER,
  CLEAR_JM_PLAN_SKUS,
  DELETE_APPRAISAL_ATTACHMENTS,
  DELETE_ORDER_ATTACHMENTS,
  EDIT_ORDER_META,
  EDIT_PM_ON_ORDER,
  EDIT_SKU_ON_ORDER,
  EDIT_STATUS_UPDATE_ON_ORDER,
  GET_APPRAISAL_ATTACHMENTS,
  GET_FULFILLMENT_STATUSES,
  GET_JEWELRY_REPAIR_COSTS,
  GET_ORDER,
  GET_ORDER_APPRAISALS,
  GET_ORDER_ATTACHMENTS,
  GET_RETURNABLE_ITEMS,
  GET_ZILLION_AUTO_QUOTE,
  IS_EDITING_ORDER,
  IS_WHOLESALE_CLIENT,
  NEEDS_WARRANTY_ACTIVATION,
  OPEN_RETURN_ORDER,
  REMOVE_JM_PLAN_SKU,
  REMOVE_ORDER_ATTACHMENT,
  REMOVE_PM_FROM_ORDER,
  REMOVE_SKU_FROM_ORDER,
  REMOVE_STATUS_UPDATE_ON_ORDER,
  REMOVE_TRADE_IN_FROM_ORDER,
  RESET_ALL_DATA,
  RESET_CLIENT_FORM_ON_ORDER,
  RESET_ORDER,
  RESET_ORDER_TRADE_INS,
  SELECT_CONTACT_DATA_ELEMENT,
  SET_CARD_DATA,
  SET_CLIENT_FORM_ON_ORDER,
  SET_ORDER_CUSTOMER_ID,
  SET_ORDER_PRIMARY_ATTACHMENT,
  SET_ORDER_SALE_VENDOR,
  SET_PRODUCT_ON_ORDER,
  SET_REGISTRY_ON_ORDER,
  STORE_JM_PLAN_SKU,
  UPDATE_ORDER_ITEM_APPRAISAL,
  VALIDATE_PASSWORD,
  ZILLION_OFFER_RECEIVED,
} from '../constants/action-types';

const removeKey = (obj, deleteKey) => {
  const clone = { ...obj };
  delete clone[deleteKey];
  return clone;
};

const extractProductFromOrder = (order, isVendor) => {
  if (isVendor) {
    return {
      vendorId: order.linkedAccount.id,
      vendorName: order.linkedAccount.name,
      bucketKey: null,
      isVendor,
    };
  }

  return {
    uuid: order.product.uuid,
    productId: order.product.id,
    reference: order.product.reference,
    description: order.product.description,
    vendorId: order.product.account.id,
    vendorName: order.product.account.name,
    bucketKey: order.product.bucketKey,
    isVendor,
  };
};

const initialState = {
  sale: {},
  skus: {},
  paymentMethods: [],
  voidedPayments: [],
  clientForm: {
    date: moment(),
    splitRatio: [100],
    includeJobDescription: true,
    includeJobScope: true,
  },
  // metadata about the order, only populated once
  // an order is created
  reference: null,
  vendor: null,
  card: null,
  registry: null,
  meta: {},
  changes: null,
  attachments: [],
  orderAppraisalItems: [],
  appraisals: [],
  appraisal_items: [],
  appraisal_attachments: [],
  statuses: [],
  returnable: [],
  selectContactDataElement: {
    key: null,
    id: null,
  },
  editing: false,
  wholesaleClient: false,
  zillion: {},
  jm: [],
  jewelry_repair_costs: [],
  needsWarrantyActivation: false,
  trade_ins: [],
  fulfillment: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(ADD_PM_TO_ORDER):
      let newPm = action.payload;
      if (action.orderID) {
        const { id, type } = newPm;
        newPm = {
          ...newPm,
          ...action.paymentMethod,
          orderPaymentID: id,
          id: type,
        };
      }
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, newPm],
      };
    case successReqActionType(EDIT_PM_ON_ORDER):
      const paymentsMap = state.paymentMethods.map((pm, index) => {
        if (index !== action.payload.index) {
          // This isn't the one we care about - keep it as-is
          return pm;
        }

        // Otherwise, this is the one we want - return an updated value
        if (action.payload.orderPaymentID) {
          return {
            ...pm,
            ...action.payload,
            // Overwrite the ID property
            // In `action.payload`, it is set to the `orderPaymentId`
            // because `PUT order/payment` expects that as `requestBody.id`
            id: action.paymentMethodId,
          };
        }
        return {
          ...pm,
          ...action.payload,
        };
      });

      return {
        ...state,
        paymentMethods: paymentsMap,
      };
    case successReqActionType(REMOVE_PM_FROM_ORDER):
      const methods = state.paymentMethods.slice();
      methods.splice(action.idxToRemove, 1);

      const voided = state.voidedPayments;

      if (action.payload?.is_voided) {
        const pm = state.paymentMethods[action.idxToRemove];
        voided.push(pm);
      }

      return {
        ...state,
        paymentMethods: methods,
        voidedPayments: voided,
      };
    case successReqActionType(ADD_SKU_TO_ORDER):
      return {
        ...state,
        skus: { ...state.skus, [action.payload.uuid]: action.payload },
        meta: action.payload.is_jewelry_repair_price_list
          ? {
              ...state.meta,
              jobScope: `${state.meta.jobScope || ''}${action.payload.description || ''}\n`,
            }
          : state.meta,
      };
    case successReqActionType(EDIT_SKU_ON_ORDER):
      return {
        ...state,
        skus: {
          ...state.skus,
          [action.payload.uuid]: {
            ...state.skus[action.payload.uuid],
            ...action.payload,
          },
        },
      };
    case successReqActionType(REMOVE_SKU_FROM_ORDER):
      return {
        ...state,
        skus: removeKey(state.skus, action.uuid),
      };
    case SET_CLIENT_FORM_ON_ORDER:
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          ...action.payload,
        },
      };
    case SET_CARD_DATA:
      return {
        ...state,
        card: action.payload,
      };
    case successReqActionType(SET_REGISTRY_ON_ORDER):
      return {
        ...state,
        registry: action.payload,
      };
    case RESET_CLIENT_FORM_ON_ORDER:
      return {
        ...state,
        clientForm: {},
      };
    case RESET_ORDER:
      return {
        ...state,
        skus: {},
        sale: {},
        reference: null,
        vendor: null,
        registry: null,
        paymentMethods: [],
        voidedPayments: [],
        statuses: [],
        returnable: [],
        clientForm: action.copy
          ? {
              ...state.clientForm,
              displayNote: false,
              note: '',
            }
          : {
              date: moment(),
              splitRatio: [100],
            },
        meta: action.copy ? { ...state.meta.customerId } : {},
        customer: action.copy ? { ...state.customer } : {},
        editing: false,
        changes: null,
        wholesaleClient: false,
      };
    case GET_ZILLION_AUTO_QUOTE:
      return {
        ...state,
        zillion: action.payload,
      };
    case ZILLION_OFFER_RECEIVED:
      return {
        ...state,
        zillion: {},
      };
    case STORE_JM_PLAN_SKU:
      state.jm?.push({
        planRetail: action.payload.planRetail,
        planSku: action.payload.planSku,
        itemSku: action.payload.itemSku,
      });
      return {
        ...state,
        jm: state.jm,
      };
    case REMOVE_JM_PLAN_SKU:
      const filteredPlans = state.jm.filter(jmCarePlan => jmCarePlan.planRetail !== action.payload.planRetailPrice);
      return {
        ...state,
        jm: filteredPlans,
      };
    case CLEAR_JM_PLAN_SKUS:
      return {
        ...state,
        jm: [],
      };
    case OPEN_RETURN_ORDER:
      const OROSkus = Object.values(state.skus);
      OROSkus.forEach(sku => {
        sku.quantity *= -1;
        sku.discount *= -1;
        sku.subtotal * -(-1);
      });
      const OROPayments = [...state.paymentMethods];
      OROPayments.forEach(payment => {
        payment.amount *= -1;
        payment.date = moment().format();
        payment.refund = payment.retref;
        delete payment.orderPaymentID;
      });

      return {
        ...state,
        editing: false,
        clientForm: {
          ...state.clientForm,
          orderType: 5,
          date: moment(),
          closeDate: null,
          note: null,
          displayNote: false,
          returnAll: true,
        },
        skus: keyBy(OROSkus, 'uuid'),
        sale: {},
        meta: {
          ...state.meta,
          id: null,
          date: moment(),
          closeDate: null,
          canceled_date: null,
          due_date: null,
          parent_order_id: state.meta.id,
        },
        reference: null,
        vendor: null,
      };
    case successReqActionType(GET_ORDER):
      if (!action.payload.id) {
        return state;
      }

      return {
        ...state,
        // for now pop raw server response on `sale`
        sale: {
          ...action.payload,
          payments: action.payload.payments.filter(p => p.active),
        },
        skus: keyBy(
          action.payload.items.map(item => ({
            id: item.skuID,
            uuid: item.uuid,
            skuUUID: item.skuUUID,
            orderItemID: item.id,
            sku: item.sku,
            description: item.description,
            price: item.price,
            amount: item.price,
            quantity: item.quantity,
            discount: item.discount,
            taxable: item.taxable,
            non_stock: item.non_stock,
            hide_on_receipt: item.hide_on_receipt || false,
            cost: item.cost,
            job_cost: item.job_cost || 0,
            quote_values: item.quote_values,
            trade_in: item.trade_in,
            appraisal: item.appraisal,
            bucketKey: item.bucketKey,
            product: item.product,
            reference_type: item.reference_data_type,
            reference_data: item.trade_in_reference_data || item.manufacture_reference_data,
          })),
          'uuid'
        ),
        paymentMethods: action.payload.payments.filter(p => p.active),
        voidedPayments: action.payload.payments.filter(p => p.is_voided),
        customer: action.payload.customer,
        clientForm: {
          orderType: action.payload.type,
          email: action.payload.emailID || null,
          phone: action.payload.phoneID || null,
          address: action.payload.addressID || null,
          shipping_address: action.payload.ship_to_address_id || null,
          locationID: action.payload.location.id,
          location: {
            value: action.payload.location.id,
            label: action.payload.location.name,
          },
          taxCode: action.payload.taxCode?.id,
          taxRate: String(action.payload.taxRate),
          taxComponents: action.payload.tax_components ? JSON.parse(action.payload.tax_components) : null,
          owners: action.payload.owners,
          note: action.payload.note_text || '',
          displayNote: action.payload.include_note || false,
          internal_note: action.payload.internal_note,
          productId: action.payload.productId,
          date: moment(action.payload.date),
          closeDate: action.payload.closeDate ? moment(action.payload.closeDate) : undefined,
          splitRatio: action.payload.split_ratio,
          marketingSource: action.payload.marketing_source
            ? {
                value: action.payload.marketing_source.id,
                label: action.payload.marketing_source.name,
              }
            : null,
          jobType: action.payload.jobType,
          dueDate: action.payload.due_date,
          estimatedValue: action.payload.estimated_value,
          estimatedRepairValue: action.payload.estimate_to_repair,
          job_serial_number: action.payload.job_serial_number,
          alternative_record_id: action.payload.alternative_record_id,
          underWarranty: action.payload.under_warranty,
          hideCustomer: action.payload.hideCustomer,
          jobDescription: action.payload.jobDescription,
          jobScope: action.payload.jobScope,
          includeJobDescription: action.payload.includeJobDescription || true,
          includeJobScope: action.payload.includeJobScope || true,
          preownedTaxRate: action.payload.preowned_tax_rate,
        },
        meta: {
          recordId: action.payload.recordID,
          id: action.payload.id,
          uuid: action.payload.uuid,
          lineItems: {
            subtotal: action.payload.subtotal,
            tax: action.payload.tax,
            total: action.payload.total,
            payments: action.payload.paid,
            balance: action.payload.balance,
            taxRate: action.payload.taxRate,
          },
          created: action.payload.created,
          collections: action.payload.collections,
          type: action.payload.type,
          primaryAttachment: action.payload.primaryAttachment,
          customerId: action.payload.customer && action.payload.customer.uuid,
          accountId: action.payload.account && action.payload.account.uuid,
          jobType: action.payload.jobType,
          jobDescription: action.payload.jobDescription,
          includeJobDescription: action.payload.includeJobDescription,
          jobScope: action.payload.jobScope,
          includeJobScope: action.payload.includeJobScope,
          underWarranty: action.payload.under_warranty,
          hideCustomer: action.payload.hideCustomer,
          note_text: action.payload.note_text,
          include_note: action.payload.include_note,
          internal_note: action.payload.internal_note,
          canceled: action.payload.voided_date != null || action.payload.canceled_date != null || false,
          date: action.payload.date,
          closeDate: action.payload.closeDate,
          canceled_date: action.payload.canceled_date,
          voided: action.payload.voided_date != null,
          voided_date: action.payload.voided_date,
          due_date: action.payload.due_date,
          lastUpdated: action.payload.lastUpdated,
          lastUpdatedUser: action.payload.lastUpdatedUser,
          fulfillment_status_id: action.payload.fulfillment_status_id,
          fulfillment_status: action.payload.fulfillment_status,
          tracking_number: action.payload.tracking_number,
          estimated_value: action.payload.estimated_value,
          estimate_to_repair: action.payload.estimate_to_repair,
          job_serial_number: action.payload.job_serial_number,
        },
        attachments: action.payload.attachments,
        appraisals: action.payload.appraisals,
        statementOfValue: {
          customer: action.payload.customer,
          items: action.payload.items,
        },
        statuses: keyBy(action.payload.statuses, 'id'),
        reference: action.payload.product && extractProductFromOrder(action.payload),
        vendor: action.payload.linkedAccount && extractProductFromOrder(action.payload, true),
        wholesaleClient: action.payload.type === OrderTypeKey.Wholesale && !action.payload.account,
      };
    case successReqActionType(ADD_ATTACHMENT_TO_ORDER):
      /*
        Goal here is if it's the first attachment, it will default
        default to be the primary attachment
      */
      return {
        ...state,
        attachments: [...state.attachments, action.payload],
        meta:
          state.attachments.length > 0
            ? state.meta
            : {
                ...state.meta,
                primaryAttachment: { id: action.payload.id },
              },
      };
    case successReqActionType(SET_ORDER_PRIMARY_ATTACHMENT):
      return {
        ...state,
        meta: {
          ...state.meta,
          primaryAttachment: { id: action.payload.primaryAttachmentId },
        },
      };
    case successReqActionType(REMOVE_ORDER_ATTACHMENT):
      return {
        ...state,
        attachments: [...state.attachments].filter(attachment => attachment.id !== action.payload.removingAttachmentId),
        meta: {
          ...state.meta,
          primaryAttachment:
            state.meta.primaryAttachment && state.meta.primaryAttachment.id === action.payload.removingAttachmentId
              ? null
              : state.meta.primaryAttachment,
        },
      };
    case SET_ORDER_CUSTOMER_ID:
      return {
        ...state,
        meta: {
          ...state.meta,
          customerId: action.payload,
        },
      };
    case SET_ORDER_SALE_VENDOR:
      return {
        ...state,
        meta: {
          ...state.meta,
          accountId: action.payload,
        },
      };
    case successReqActionType(ADD_STATUS_UPDATE_TO_ORDER):
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [action.payload.id]: action.payload,
        },
      };
    case successReqActionType(EDIT_STATUS_UPDATE_ON_ORDER):
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [action.payload.id]: {
            ...action.payload,
            code: action.fullCode,
          },
        },
      };
    case successReqActionType(REMOVE_STATUS_UPDATE_ON_ORDER):
      return {
        ...state,
        statuses: removeKey(state.statuses, action.payload.id),
      };
    case successReqActionType(EDIT_ORDER_META):
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
        changes: null,
      };
    case SET_PRODUCT_ON_ORDER:
      return {
        ...state,
        reference: action.payload.reference ? action.payload.reference : null,
        vendor: action.payload.vendor ? action.payload.vendor : null,
      };
    case SELECT_CONTACT_DATA_ELEMENT:
      return {
        ...state,
        selectContactDataElement: {
          ...state.selectContactDataElement,
          key: action.payload.key,
          id: action.payload.id,
        },
      };
    case successReqActionType(GET_RETURNABLE_ITEMS):
      return {
        ...state,
        returnable: action.payload,
      };
    case successReqActionType(GET_FULFILLMENT_STATUSES):
      return {
        ...state,
        fulfillment: action.payload,
      };
    case successReqActionType(GET_ORDER_ATTACHMENTS):
      return {
        ...state,
        attachments: action.payload,
      };
    case successReqActionType(GET_APPRAISAL_ATTACHMENTS):
      return {
        ...state,
        appraisal_attachments: action.payload,
      };
    case successReqActionType(GET_ORDER_APPRAISALS):
      return {
        ...state,
        appraisals: action.payload,
      };
    case successReqActionType(UPDATE_ORDER_ITEM_APPRAISAL):
      return {
        ...state,
        appraisals: [...state.appraisals.filter(saf => saf.uuid !== action.payload.uuid), action.payload],
      };
    case successReqActionType(DELETE_ORDER_ATTACHMENTS):
      return {
        ...state,
        attachments: state.attachments.filter(a => a.id !== action.payload.attachment_id),
      };
    case successReqActionType(DELETE_APPRAISAL_ATTACHMENTS):
      return {
        ...state,
        appraisal_attachments: state.appraisal_attachments.filter(a => a.id !== action.payload.attachment_id),
      };
    case successReqActionType(GET_JEWELRY_REPAIR_COSTS):
      return {
        ...state,
        jewelry_repair_costs: action.payload,
      };
    case successReqActionType(VALIDATE_PASSWORD):
    case IS_EDITING_ORDER:
      return { ...state, editing: action.payload && action.edit };
    case IS_WHOLESALE_CLIENT:
      return { ...state, wholesaleClient: action.payload };
    case NEEDS_WARRANTY_ACTIVATION:
      return { ...state, needsWarrantyActivation: action.payload };
    case RESET_ALL_DATA:
      return initialState;
    case ADD_TRADE_IN_TO_ORDER:
      return { ...state, trade_ins: [...state.trade_ins, action.payload] };
    case REMOVE_TRADE_IN_FROM_ORDER:
      return { ...state, trade_ins: state.trade_ins?.filter(tradeIn => tradeIn?.nonstock?.uuid !== action.payload) };
    case RESET_ORDER_TRADE_INS:
      return { ...state, trade_ins: [] };
    default:
      return state;
  }
};

export default reducer;
