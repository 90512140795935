import i18n from 'i18next';

import { formatMoney, formatPercent } from 'utils/money';
import { translate } from 'utils/translation';
import { ReportRow } from './field_types';

export const STOCK_CATEGORY_PERFORMANCE_REPORT_FIELDS = [
  {
    name: translate('reports', 'stock-category-performance.columns.stock-category-code', 'Stock Category Code'),
    key: 'stock_category_code',
    id: 'stock_category_code',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.stock-category', 'Stock Category'),
    key: 'stock_category',
    id: 'stock_category',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => <div>{row.stock_category}</div>,
  },
  {
    name: translate('reports', 'cost-of-goods-sold-report.columns.quantity', 'Quantity'),
    key: 'quantity',
    id: 'quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: translate('reports', 'stock-category-performance.columns.retail', 'Retail'),
    key: 'retail',
    id: 'retail',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.retail) || 0,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.discount', 'Discount'),
    key: 'discount',
    id: 'discount',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.discount) || 0,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.ext-price', 'Ext. Price'),
    key: 'ext_price',
    id: 'ext_price',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ext_price) || 0,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.ext-cost', 'Ext. Cost'),
    key: 'ext_cost',
    id: 'ext_cost',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ext_cost) || 0,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.job-cost', 'Job Cost'),
    key: 'job_cost',
    id: 'job_cost',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.job_cost) || 0,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.margin', 'Margin'),
    key: 'margin',
    id: 'margin',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.margin) || 0,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.margin', 'Margin %'),
    key: 'margin_pct',
    id: 'margin_pct',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'PERCENT',
    part: 'margin',
    whole: 'retail',
    render: (row: ReportRow) => formatPercent(Number(Number(row.margin_pct) * 100)) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.gross-profit')
      ? i18n.t('reports:stock-category-performance.columns.gross-profit', { sign: '$' })
      : 'Gross Profit $',
    key: 'gross_profit',
    id: 'gross_profit',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.gross_profit) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold-report.columns.gross-profit')
      ? i18n.t('reports:stock-category-performance.columns.gross-profit', { sign: '%' })
      : 'Gross Profit %',
    key: 'gross_profit_pct',
    id: 'gross_profit_pct',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'PERCENT',
    part: 'gross_profit',
    whole: 'ext_price',
    render: (row: ReportRow) => formatPercent(Number(Number(row.gross_profit_pct) * 100)) || 0,
  },
  {
    name: translate('reports', 'stock-category-performance.columns.online-price', 'Online Price'),
    key: 'online_price',
    id: 'online_price',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.online_price) || 0,
  },
];
