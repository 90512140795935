import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import {
  ADD_CLIENT,
  ADD_CLIENT_ADDRESS,
  ADD_CLIENT_COLLECTION,
  ADD_CLIENT_EMAIL,
  ADD_CLIENT_PHONE,
  ADD_STORE_CREDIT,
  DELETE_CLIENT_ATTACHMENTS,
  EDIT_CLIENT,
  EDIT_CLIENT_ADDRESS,
  EDIT_CLIENT_COLLECTION,
  EDIT_CLIENT_EMAIL,
  EDIT_CLIENT_NAME,
  EDIT_CLIENT_PHONE,
  EDIT_CLIENT_PRIMARY_FIELD,
  GET_CARD_TRANSACTIONS,
  GET_CLIENT,
  GET_CLIENTS,
  GET_CLIENTS_WITH_ACCOUNT,
  GET_CLIENT_ATTACHMENTS,
  GET_CLIENT_COLLECTIONS,
  GET_CLIENT_HISTORICAL_STATEMENT,
  GET_CLIENT_HISTORY,
  GET_CLIENT_INTEREST_DUE,
  GET_CLIENT_MILESTONES,
  GET_CLIENT_ON_ACCOUNT_STATEMENTS,
  GET_CLIENT_PAYMENT,
  GET_CLIENT_REFERRALS,
  GET_CLIENT_REFUND,
  GET_CLIENT_REGISTRY,
  GET_CLIENT_RELATIONSHIPS,
  GET_CLIENT_REWARDS_HISTORY,
  GET_CLIENT_STATEMENT_HISTORY,
  GET_CLIENT_VISITS,
  GET_CLIENT_WISHLIST,
  GET_CREDIT_PLANS,
  GET_ITEM_HISTORY,
  GET_STORE_CREDITS,
  REMOVE_WISHLIST_ITEM,
  RESET_ALL_DATA,
  UPDATE_WISHLIST_ITEM,
} from '../constants/action-types';

const updateObjectInArray = (arr, searchKey, newVal) =>
  arr.map(el => {
    if (el.id === searchKey) {
      return { ...el, ...newVal };
    }
    return el;
  });

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_CLIENTS):
      return { ...state, ...keyBy(action.payload, 'uuid') };
    case successReqActionType(GET_CLIENT):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          ...action.payload,
        },
      };
    case successReqActionType(GET_CLIENTS_WITH_ACCOUNT):
      return {
        ...state,
        clients_with_account: [...action.payload],
      };
    case successReqActionType(GET_CLIENT_REWARDS_HISTORY):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          rewards_history: action.payload,
        },
      };
    case successReqActionType(GET_CLIENT_INTEREST_DUE):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          interest_due: action.payload,
        },
      };
    case successReqActionType(ADD_CLIENT_EMAIL):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          emails: [...state[action.uuid].emails, action.payload],
        },
      };
    case successReqActionType(EDIT_CLIENT_EMAIL):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          emails: updateObjectInArray(state[action.payload.uuid].emails, action.payload.id, action.payload),
        },
      };
    case successReqActionType(ADD_CLIENT_PHONE):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          phones: [...state[action.uuid].phones, action.payload],
        },
      };
    case successReqActionType(EDIT_CLIENT_PHONE):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          phones: updateObjectInArray(state[action.payload.uuid].phones, action.payload.id, action.payload),
        },
      };
    case successReqActionType(ADD_CLIENT_ADDRESS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          addresses: [...state[action.uuid].addresses, action.payload],
        },
      };
    case successReqActionType(EDIT_CLIENT_ADDRESS):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          addresses: updateObjectInArray(state[action.payload.uuid].addresses, action.payload.id, action.payload),
        },
      };
    case successReqActionType(EDIT_CLIENT):
    case successReqActionType(ADD_CLIENT):
      return state;
    case successReqActionType(GET_CLIENT_HISTORY):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          orders: action.payload.orders,
          types: action.payload.types,
        },
      };
    case successReqActionType(GET_ITEM_HISTORY):
      if (action.canceled) {
        return {
          ...state,
          [action.clientID]: {
            ...state[action.clientID],
            canceled: action.payload,
          },
        };
      }
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          items: action.payload,
        },
      };
    case successReqActionType(GET_STORE_CREDITS):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          credits: action.payload,
        },
      };
    case successReqActionType(GET_CLIENT_REFUND):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          refund: action.payload,
        },
      };
    case successReqActionType(ADD_STORE_CREDIT):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          credits: [...state[action.clientID].credits, action.payload],
        },
      };
    case successReqActionType(UPDATE_WISHLIST_ITEM):
      return {
        ...state,
      };
    case successReqActionType(REMOVE_WISHLIST_ITEM):
      const wishlist = state[action.uuid].wishlist.slice();
      wishlist.splice(action.index, 1);

      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          wishlist,
        },
      };
    case successReqActionType(EDIT_CLIENT_NAME):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      };
    case successReqActionType(GET_CLIENT_RELATIONSHIPS):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          relationships: action.payload,
        },
      };
    case successReqActionType(GET_CLIENT_REFERRALS):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          referrals: action.payload,
        },
      };
    case EDIT_CLIENT_PRIMARY_FIELD:
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          [action.payload.dataType]: state[action.payload.uuid][action.payload.dataType].map(el => {
            if (el.id === action.payload.newPrimaryId) {
              return { ...el, primary: true };
            }
            return { ...el, primary: false };
          }),
        },
      };
    case successReqActionType(ADD_CLIENT_COLLECTION):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          collections: [...state[action.uuid].collections, action.payload.collection],
          account_balance: action.payload.balance,
        },
      };
    case successReqActionType(GET_CLIENT_COLLECTIONS):
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          payments: action.payload.payments,
          collections: action.payload.collections,
          accountsReceivable: action.payload.accounts_receivable,
          onAccountPayments: action.payload.on_account_payments,
        },
      };
    case successReqActionType(GET_CLIENT_PAYMENT):
      return {
        ...state,
        payment: action.payload,
      };
    case successReqActionType(EDIT_CLIENT_COLLECTION):
      const collectionIndex = state[action.uuid].collections.findIndex(c => c.id === action.payload.id);
      let newCollections = [...state[action.uuid].collections];
      if (action.payload.inactive) {
        newCollections = newCollections.filter(c => c.id !== action.payload.id);
      } else {
        newCollections.splice(collectionIndex, 1, action.payload);
      }
      return {
        [action.uuid]: {
          ...state[action.uuid],
          collections: newCollections,
        },
      };
    case successReqActionType(GET_CLIENT_VISITS):
      const [key] = Object.keys(state);
      return {
        ...state,
        [key]: {
          ...state[key],
          visits: action.payload,
        },
        visits: action.payload,
      };
    case successReqActionType(GET_CLIENT_MILESTONES):
      return {
        ...state,
        milestones: action.payload,
      };
    case successReqActionType(GET_CLIENT_WISHLIST):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          wishlist: action.payload,
        },
      };
    case successReqActionType(GET_CLIENT_REGISTRY):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          registry: action.payload,
        },
      };
    case successReqActionType(GET_CLIENT_ATTACHMENTS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          attachments: action.payload,
        },
      };
    case successReqActionType(DELETE_CLIENT_ATTACHMENTS):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          attachments: state[action.payload.uuid].attachments.filter(
            attachment => attachment.id !== action.payload.attachment_id
          ),
        },
      };
    case successReqActionType(GET_CARD_TRANSACTIONS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          cardPayments: action.payload,
        },
      };
    case successReqActionType(GET_CREDIT_PLANS):
      return {
        ...state,
        available_credit_plans: action.payload,
      };
    case successReqActionType(GET_CLIENT_STATEMENT_HISTORY):
      return {
        ...state,
        [action.clientUuid]: {
          ...state[action.clientUuid],
          statement_history: action.payload,
        },
      };
    case successReqActionType(GET_CLIENT_HISTORICAL_STATEMENT):
      return {
        ...state,
        clients_with_account: [...action.payload],
      };
    case successReqActionType(GET_CLIENT_ON_ACCOUNT_STATEMENTS):
      return {
        ...state,
        clients_with_account: [...(action.payload?.statement_data || [])],
        statement_name: action.payload?.statement_name || '',
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
