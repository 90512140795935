import * as Sentry from '@sentry/react';

import { isPreviewDeploy } from 'components/PreviewDeploy';

const SENTRY_IGNORE_ERRORS = ['ResizeObserver loop limit exceeded', 'Failed to fetch', 'ChunkLoadError'];

export const initSentry = (version: string) => {
  const dsn = process.env.SENTRY_DSN;

  if (dsn && process.env.SENTRY_ENABLED) {
    Sentry.init({
      dsn,
      release: `crystal-web@${version}`,
      ignoreErrors: SENTRY_IGNORE_ERRORS,
      autoSessionTracking: false,
      environment: 'development',
    });

    Sentry.configureScope(scope => {
      scope.setTag('version', process.env.VERSION);
      scope.setTag('isPreviewDeploy', isPreviewDeploy());

      scope.setContext('build', { ...global.build });
    });
  }
};
