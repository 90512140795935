import {
  DiamondCert,
  DiamondClarity,
  DiamondColor,
  DiamondCut,
  DiamondFancyColor,
  DiamondFluorescence,
  DiamondGirdle,
  DiamondPolish,
  DiamondSaturation,
  DiamondSearchFilters,
  DiamondShape,
  DiamondSymmetry,
} from './types';

import asscherIcon from './diamondShapeIcons/asscher.svg';
import baguetteIcon from './diamondShapeIcons/baguette.svg';
import brioletteIcon from './diamondShapeIcons/briolette.svg';
import bulletsIcon from './diamondShapeIcons/bullet.svg';
import calfIcon from './diamondShapeIcons/calf.svg';
import cushionBrilliantIcon from './diamondShapeIcons/cushion_brilliant.svg';
import cushionModifiedIcon from './diamondShapeIcons/cushion_mod.svg';
import emeraldIcon from './diamondShapeIcons/emerald.svg';
import europeanCutIcon from './diamondShapeIcons/euro_cut.svg';
import halfMoonIcon from './diamondShapeIcons/half_moon.svg';
import heartIcon from './diamondShapeIcons/heart.svg';
import hexagonalIcon from './diamondShapeIcons/hexagonal.svg';
import kiteIcon from './diamondShapeIcons/kite.svg';
import lozengeIcon from './diamondShapeIcons/lozenge.svg';
import marquiseIcon from './diamondShapeIcons/marquise.svg';
import octagonalIcon from './diamondShapeIcons/octagonal.svg';
import oldMinerIcon from './diamondShapeIcons/old_miner.svg';
import ovalIcon from './diamondShapeIcons/oval.svg';
import pearIcon from './diamondShapeIcons/pear.svg';
import pentagonalIcon from './diamondShapeIcons/pentagonal.svg';
import princessIcon from './diamondShapeIcons/princess.svg';
import radiantIcon from './diamondShapeIcons/radiant.svg';
import roseIcon from './diamondShapeIcons/rose.svg';
import roundIcon from './diamondShapeIcons/round.svg';
import shieldIcon from './diamondShapeIcons/shield.svg';
import squareIcon from './diamondShapeIcons/square.svg';
import sqEmeraldIcon from './diamondShapeIcons/sq_emerald.svg';
import squareRadiantIcon from './diamondShapeIcons/sq_radiant.svg';
import starIcon from './diamondShapeIcons/star.svg';
import taperedBulletIcon from './diamondShapeIcons/tapered.svg';
import taperedBaguetteIcon from './diamondShapeIcons/tapered_baguette.svg';
import trapezoidIcon from './diamondShapeIcons/trapezoid.svg';
import triangularIcon from './diamondShapeIcons/triangular.svg';
import trilliantIcon from './diamondShapeIcons/trilliant.svg';

export const shapeOptions = [
  {
    value: DiamondShape.Round,
    label: DiamondShape[DiamondShape.Round],
    icon: roundIcon,
  },
  {
    value: DiamondShape.Pear,
    label: DiamondShape[DiamondShape.Pear],
    icon: pearIcon,
  },
  {
    value: DiamondShape.Princess,
    label: DiamondShape[DiamondShape.Princess],
    icon: princessIcon,
  },
  {
    value: DiamondShape.Marquise,
    label: DiamondShape[DiamondShape.Marquise],
    icon: marquiseIcon,
  },
  {
    value: DiamondShape['Emerald Cut'],
    label: DiamondShape[DiamondShape['Emerald Cut']],
    icon: emeraldIcon,
  },
  {
    value: DiamondShape['Cushion Brilliant'],
    label: DiamondShape[DiamondShape['Cushion Brilliant']],
    icon: cushionBrilliantIcon,
  },
  {
    value: DiamondShape['Cushion Modified'],
    label: DiamondShape[DiamondShape['Cushion Modified']],
    icon: cushionModifiedIcon,
  },
  {
    value: DiamondShape.Asscher,
    label: DiamondShape[DiamondShape.Asscher],
    icon: asscherIcon,
  },
  {
    value: DiamondShape['Sq. Emerald'],
    label: DiamondShape[DiamondShape['Sq. Emerald']],
    icon: sqEmeraldIcon,
  },
  {
    value: DiamondShape.Oval,
    label: DiamondShape[DiamondShape.Oval],
    icon: ovalIcon,
  },
  {
    value: DiamondShape.Radiant,
    label: DiamondShape[DiamondShape.Radiant],
    icon: radiantIcon,
  },
  {
    value: DiamondShape.Heart,
    label: DiamondShape[DiamondShape.Heart],
    icon: heartIcon,
  },
  {
    value: DiamondShape['European Cut'],
    label: DiamondShape[DiamondShape['European Cut']],
    icon: europeanCutIcon,
  },
  {
    value: DiamondShape['Old Miner'],
    label: DiamondShape[DiamondShape['Old Miner']],
    icon: oldMinerIcon,
  },
  {
    value: DiamondShape.Baguette,
    label: DiamondShape[DiamondShape.Baguette],
    icon: baguetteIcon,
  },
  {
    value: DiamondShape.Briolette,
    label: DiamondShape[DiamondShape.Briolette],
    icon: brioletteIcon,
  },
  {
    value: DiamondShape.Bullets,
    label: DiamondShape[DiamondShape.Bullets],
    icon: bulletsIcon,
  },
  {
    value: DiamondShape.Calf,
    label: DiamondShape[DiamondShape.Calf],
    icon: calfIcon,
  },
  {
    value: DiamondShape['Half Moon'],
    label: DiamondShape[DiamondShape['Half Moon']],
    icon: halfMoonIcon,
  },
  {
    value: DiamondShape.Hexagonal,
    label: DiamondShape[DiamondShape.Hexagonal],
    icon: hexagonalIcon,
  },
  {
    value: DiamondShape.Kite,
    label: DiamondShape[DiamondShape.Kite],
    icon: kiteIcon,
  },
  {
    value: DiamondShape.Lozenge,
    label: DiamondShape[DiamondShape.Lozenge],
    icon: lozengeIcon,
  },
  {
    value: DiamondShape.Octagonal,
    label: DiamondShape[DiamondShape.Octagonal],
    icon: octagonalIcon,
  },
  {
    value: DiamondShape.Pentagonal,
    label: DiamondShape[DiamondShape.Pentagonal],
    icon: pentagonalIcon,
  },
  {
    value: DiamondShape.Rose,
    label: DiamondShape[DiamondShape.Rose],
    icon: roseIcon,
  },
  {
    value: DiamondShape.Shield,
    label: DiamondShape[DiamondShape.Shield],
    icon: shieldIcon,
  },
  {
    value: DiamondShape.Square,
    label: DiamondShape[DiamondShape.Square],
    icon: squareIcon,
  },
  {
    value: DiamondShape['Square Radiant'],
    label: DiamondShape[DiamondShape['Square Radiant']],
    icon: squareRadiantIcon,
  },
  {
    value: DiamondShape.Star,
    label: DiamondShape[DiamondShape.Star],
    icon: starIcon,
  },
  {
    value: DiamondShape['Tapered Baguette'],
    label: DiamondShape[DiamondShape['Tapered Baguette']],
    icon: taperedBaguetteIcon,
  },
  {
    value: DiamondShape['Tapered Bullet'],
    label: DiamondShape[DiamondShape['Tapered Bullet']],
    icon: taperedBulletIcon,
  },
  {
    value: DiamondShape.Trapezoid,
    label: DiamondShape[DiamondShape.Trapezoid],
    icon: trapezoidIcon,
  },
  {
    value: DiamondShape.Triangular,
    label: DiamondShape[DiamondShape.Triangular],
    icon: triangularIcon,
  },
  {
    value: DiamondShape.Trilliant,
    label: DiamondShape[DiamondShape.Trilliant],
    icon: trilliantIcon,
  },
  { value: DiamondShape.Other, label: DiamondShape[DiamondShape.Other] },
];

export const colorOptions = [
  { value: DiamondColor.D, label: DiamondColor[DiamondColor.D] },
  { value: DiamondColor.E, label: DiamondColor[DiamondColor.E] },
  { value: DiamondColor.F, label: DiamondColor[DiamondColor.F] },
  { value: DiamondColor.G, label: DiamondColor[DiamondColor.G] },
  { value: DiamondColor.H, label: DiamondColor[DiamondColor.H] },
  { value: DiamondColor.I, label: DiamondColor[DiamondColor.I] },
  { value: DiamondColor.J, label: DiamondColor[DiamondColor.J] },
  { value: DiamondColor.K, label: DiamondColor[DiamondColor.K] },
  { value: DiamondColor.L, label: DiamondColor[DiamondColor.L] },
  { value: DiamondColor.M, label: DiamondColor[DiamondColor.M] },
  { value: DiamondColor.N, label: DiamondColor[DiamondColor.N] },
  { value: DiamondColor.O, label: DiamondColor[DiamondColor.O] },
  { value: DiamondColor.P, label: DiamondColor[DiamondColor.P] },
  { value: DiamondColor.Q, label: DiamondColor[DiamondColor.Q] },
  { value: DiamondColor.R, label: DiamondColor[DiamondColor.R] },
  { value: DiamondColor.S, label: DiamondColor[DiamondColor.S] },
  { value: DiamondColor.T, label: DiamondColor[DiamondColor.T] },
  { value: DiamondColor.U, label: DiamondColor[DiamondColor.U] },
  { value: DiamondColor.V, label: DiamondColor[DiamondColor.V] },
  { value: DiamondColor.W, label: DiamondColor[DiamondColor.W] },
  { value: DiamondColor.X, label: DiamondColor[DiamondColor.X] },
  { value: DiamondColor.Y, label: DiamondColor[DiamondColor.Y] },
  { value: DiamondColor.Z, label: DiamondColor[DiamondColor.Z] },
];

export const fancyColorOptions = [
  { value: DiamondFancyColor.Red, label: DiamondFancyColor[DiamondFancyColor.Red] },
  { value: DiamondFancyColor['Orangy Red'], label: DiamondFancyColor[DiamondFancyColor['Orangy Red']] },
  { value: DiamondFancyColor['Reddish Orange'], label: DiamondFancyColor[DiamondFancyColor['Reddish Orange']] },
  { value: DiamondFancyColor.Orange, label: DiamondFancyColor[DiamondFancyColor.Orange] },
  { value: DiamondFancyColor['Yellowish Orange'], label: DiamondFancyColor[DiamondFancyColor['Yellowish Orange']] },
  { value: DiamondFancyColor['Yellow Orange'], label: DiamondFancyColor[DiamondFancyColor['Yellow Orange']] },
  { value: DiamondFancyColor['Orange Yellow'], label: DiamondFancyColor[DiamondFancyColor['Orange Yellow']] },
  { value: DiamondFancyColor['Orangy Yellow'], label: DiamondFancyColor[DiamondFancyColor['Orangy Yellow']] },
  { value: DiamondFancyColor.Yellow, label: DiamondFancyColor[DiamondFancyColor.Yellow] },
  { value: DiamondFancyColor['Greenish Yellow'], label: DiamondFancyColor[DiamondFancyColor['Greenish Yellow']] },
  { value: DiamondFancyColor['Green Yellow'], label: DiamondFancyColor[DiamondFancyColor['Green Yellow']] },
  { value: DiamondFancyColor['Yellow Green'], label: DiamondFancyColor[DiamondFancyColor['Yellow Green']] },
  { value: DiamondFancyColor['Yellowish Green'], label: DiamondFancyColor[DiamondFancyColor['Yellowish Green']] },
  { value: DiamondFancyColor.Green, label: DiamondFancyColor[DiamondFancyColor.Green] },
  { value: DiamondFancyColor['Bluish Green'], label: DiamondFancyColor[DiamondFancyColor['Bluish Green']] },
  { value: DiamondFancyColor['Blue Green'], label: DiamondFancyColor[DiamondFancyColor['Blue Green']] },
  { value: DiamondFancyColor['Green Blue'], label: DiamondFancyColor[DiamondFancyColor['Green Blue']] },
  { value: DiamondFancyColor['Greenish Blue'], label: DiamondFancyColor[DiamondFancyColor['Greenish Blue']] },
  { value: DiamondFancyColor.Blue, label: DiamondFancyColor[DiamondFancyColor.Blue] },
  { value: DiamondFancyColor['Violetish Blue'], label: DiamondFancyColor[DiamondFancyColor['Violetish Blue']] },
  { value: DiamondFancyColor['Bluish Violet'], label: DiamondFancyColor[DiamondFancyColor['Bluish Violet']] },
  { value: DiamondFancyColor.Violet, label: DiamondFancyColor[DiamondFancyColor.Violet] },
  { value: DiamondFancyColor.Purple, label: DiamondFancyColor[DiamondFancyColor.Purple] },
  { value: DiamondFancyColor['Reddish Purple'], label: DiamondFancyColor[DiamondFancyColor['Reddish Purple']] },
  { value: DiamondFancyColor['Red Purple'], label: DiamondFancyColor[DiamondFancyColor['Red Purple']] },
  { value: DiamondFancyColor['Purple Red'], label: DiamondFancyColor[DiamondFancyColor['Purple Red']] },
  { value: DiamondFancyColor['Purplish Red'], label: DiamondFancyColor[DiamondFancyColor['Purplish Red']] },
];

export const saturationOptions = [
  { value: DiamondSaturation['Fancy Light'], label: DiamondSaturation[DiamondSaturation['Fancy Light']] },
  { value: DiamondSaturation.Fancy, label: DiamondSaturation[DiamondSaturation.Fancy] },
  { value: DiamondSaturation['Fancy Intense'], label: DiamondSaturation[DiamondSaturation['Fancy Intense']] },
  { value: DiamondSaturation['Fancy Vivid'], label: DiamondSaturation[DiamondSaturation['Fancy Vivid']] },
  { value: DiamondSaturation['Fancy Deep'], label: DiamondSaturation[DiamondSaturation['Fancy Deep']] },
];

export const clarityOptions = [
  { value: DiamondClarity.FL, label: DiamondClarity[DiamondClarity.FL] },
  { value: DiamondClarity.IF, label: DiamondClarity[DiamondClarity.IF] },
  { value: DiamondClarity.VVS1, label: DiamondClarity[DiamondClarity.VVS1] },
  { value: DiamondClarity.VVS2, label: DiamondClarity[DiamondClarity.VVS2] },
  { value: DiamondClarity.VS1, label: DiamondClarity[DiamondClarity.VS1] },
  { value: DiamondClarity.VS2, label: DiamondClarity[DiamondClarity.VS2] },
  { value: DiamondClarity.SI1, label: DiamondClarity[DiamondClarity.SI1] },
  { value: DiamondClarity.SI2, label: DiamondClarity[DiamondClarity.SI2] },
  { value: DiamondClarity.I1, label: DiamondClarity[DiamondClarity.I1] },
  { value: DiamondClarity.I2, label: DiamondClarity[DiamondClarity.I2] },
  { value: DiamondClarity.I3, label: DiamondClarity[DiamondClarity.I3] },
];

export const cutOptions = [
  { value: DiamondCut.Poor, label: DiamondCut[DiamondCut.Poor] },
  { value: DiamondCut.Fair, label: DiamondCut[DiamondCut.Fair] },
  { value: DiamondCut.Good, label: DiamondCut[DiamondCut.Good] },
  {
    value: DiamondCut['Very Good'],
    label: DiamondCut[DiamondCut['Very Good']],
  },
  { value: DiamondCut.Excellent, label: DiamondCut[DiamondCut.Excellent] },
  { value: DiamondCut.Ideal, label: DiamondCut[DiamondCut.Ideal] },
];

export const polishOptions = [
  { value: DiamondPolish.Poor, label: DiamondPolish[DiamondPolish.Poor] },
  { value: DiamondPolish.Fair, label: DiamondPolish[DiamondPolish.Fair] },
  { value: DiamondPolish.Good, label: DiamondPolish[DiamondPolish.Good] },
  {
    value: DiamondPolish['Very Good'],
    label: DiamondPolish[DiamondPolish['Very Good']],
  },
  {
    value: DiamondPolish.Excellent,
    label: DiamondPolish[DiamondPolish.Excellent],
  },
  { value: DiamondPolish.Ideal, label: DiamondPolish[DiamondPolish.Ideal] },
];

export const symmetryOptions = [
  { value: DiamondSymmetry.Poor, label: DiamondSymmetry[DiamondSymmetry.Poor] },
  { value: DiamondSymmetry.Fair, label: DiamondSymmetry[DiamondSymmetry.Fair] },
  { value: DiamondSymmetry.Good, label: DiamondSymmetry[DiamondSymmetry.Good] },
  {
    value: DiamondSymmetry['Very Good'],
    label: DiamondSymmetry[DiamondSymmetry['Very Good']],
  },
  {
    value: DiamondSymmetry.Excellent,
    label: DiamondSymmetry[DiamondSymmetry.Excellent],
  },
  {
    value: DiamondSymmetry.Ideal,
    label: DiamondSymmetry[DiamondSymmetry.Ideal],
  },
];

export const fluorescenceOptions = [
  {
    value: DiamondFluorescence.None,
    label: DiamondFluorescence[DiamondFluorescence.None],
  },
  {
    value: DiamondFluorescence['Very Slight'],
    label: DiamondFluorescence[DiamondFluorescence['Very Slight']],
  },
  {
    value: DiamondFluorescence.Slight,
    label: DiamondFluorescence[DiamondFluorescence.Slight],
  },
  {
    value: DiamondFluorescence.Faint,
    label: DiamondFluorescence[DiamondFluorescence.Faint],
  },
  {
    value: DiamondFluorescence.Medium,
    label: DiamondFluorescence[DiamondFluorescence.Medium],
  },
  {
    value: DiamondFluorescence.Strong,
    label: DiamondFluorescence[DiamondFluorescence.Strong],
  },
  {
    value: DiamondFluorescence['Very Strong'],
    label: DiamondFluorescence[DiamondFluorescence['Very Strong']],
  },
];

export const certOptions = [
  {
    value: DiamondCert.GIA,
    label: DiamondCert[DiamondCert.GIA],
  },
  {
    value: DiamondCert.AGS,
    label: DiamondCert[DiamondCert.AGS],
  },
  {
    value: DiamondCert.EGL,
    label: DiamondCert[DiamondCert.EGL],
  },
  {
    value: DiamondCert.IGI,
    label: DiamondCert[DiamondCert.IGI],
  },
  {
    value: DiamondCert.HRD,
    label: DiamondCert[DiamondCert.HRD],
  },
  {
    value: DiamondCert.RDC,
    label: DiamondCert[DiamondCert.RDC],
  },
  {
    value: DiamondCert.CGL,
    label: DiamondCert[DiamondCert.CGL],
  },
  {
    value: DiamondCert.DCLA,
    label: DiamondCert[DiamondCert.DCLA],
  },
  {
    value: DiamondCert.GCAL,
    label: DiamondCert[DiamondCert.GCAL],
  },
  {
    value: DiamondCert.GHI,
    label: DiamondCert[DiamondCert.GHI],
  },
  {
    value: DiamondCert.DBGIS,
    label: DiamondCert[DiamondCert.DBGIS],
  },
  {
    value: DiamondCert.GSI,
    label: DiamondCert[DiamondCert.GSI],
  },
  {
    value: DiamondCert.GWLAB,
    label: DiamondCert[DiamondCert.GWLAB],
  },
  {
    value: DiamondCert.NGTC,
    label: DiamondCert[DiamondCert.NGTC],
  },
  {
    value: DiamondCert.PGS,
    label: DiamondCert[DiamondCert.PGS],
  },
  {
    value: DiamondCert.RAP,
    label: DiamondCert[DiamondCert.RAP],
  },
  {
    value: DiamondCert.NONE,
    label: DiamondCert[DiamondCert.NONE],
  },
];

export const girdleOptions = [
  {
    value: DiamondGirdle.XTN,
    label: DiamondGirdle[DiamondGirdle.XTN],
  },
  {
    value: DiamondGirdle.VTN,
    label: DiamondGirdle[DiamondGirdle.VTN],
  },
  {
    value: DiamondGirdle.TN,
    label: DiamondGirdle[DiamondGirdle.TN],
  },
  {
    value: DiamondGirdle.MD,
    label: DiamondGirdle[DiamondGirdle.MD],
  },
  {
    value: DiamondGirdle.STK,
    label: DiamondGirdle[DiamondGirdle.STK],
  },
  {
    value: DiamondGirdle.TK,
    label: DiamondGirdle[DiamondGirdle.TK],
  },
  {
    value: DiamondGirdle.VTK,
    label: DiamondGirdle[DiamondGirdle.VTK],
  },
  {
    value: DiamondGirdle.XTK,
    label: DiamondGirdle[DiamondGirdle.XTK],
  },
];

export const defaultDiamondSearchFilters: DiamondSearchFilters = {
  shape: [],
  carat: [null, null],
  color: [DiamondColor.D, DiamondColor.Z],
  fancyColor: [DiamondFancyColor.Red, DiamondFancyColor['Purplish Red']],
  saturation: [DiamondSaturation['Fancy Light'], DiamondSaturation['Fancy Vivid']],
  clarity: [DiamondClarity.FL, DiamondClarity.I3],
  cut: [DiamondCut.Poor, DiamondCut.Ideal],
  polish: [DiamondPolish.Poor, DiamondPolish.Ideal],
  symmetry: [DiamondSymmetry.Poor, DiamondSymmetry.Ideal],
  fluorescence: [DiamondFluorescence.None, DiamondFluorescence['Very Strong']],
  cert: [],
};

export const culetOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];
