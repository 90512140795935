import { JewelersMutualGetQuoteResponse } from 'services/jewelers-mutual-api';
import { InvoiceItem } from './invoice';

export interface JewelersMutualQuote {
  applicable_plan_duration: string;
  plan_retail_price: number;
  plan_sku: string;
}

export interface JewelersMutualError {
  code: string;
  description: string;
}

export interface CarePlan {
  created_date: string;
  sku_id: string;
  order_uuid: string;
  jm_transaction_id: string;
  plan_retail_price: number;
  jm_store_id: string;
  jm_plan_sku: string;
  jm_sale_id?: string;
  plan_expired: boolean;
  plan_duration: string;
  expiration_date: string;
  cancellation_transaction_id: string;
}

export interface JmStoreCode {
  jm_store_code: string;
  location: string;
}

export interface JmProductMap {
  sku_category_code: string;
  jm_product_code: string;
}

export interface JmSelectQuoteForSaleProps {
  closeModal: () => void;
  handleCreateSKUs: (selectedPlans: any) => void;
  responses: JewelersMutualGetQuoteResponse[] | null;
  items: InvoiceItem[] | null;
}

export interface JmErrorModalProps {
  closeModal: () => void;
  errorMessage: string;
}

export interface JmSelectSkuForQuoteModalProps {
  jmSkuData: any;
  orderItems: any;
  closeModal: () => void;
  handleQuoteRequested: (selectedSkus: InvoiceItem[]) => void;
  loading: boolean;
}

export enum JewelersMutualErrorCodes {
  QuoteNotFound = 'QUOTE_IS_NOT_FOUND',
}
export const JewelersMutualProductCodes = {
  Earrings: 'J01',
  Necklace: 'J02',
  Bracelet: 'J03',
  Ring: 'J04',
  BroachOrPin: 'J05',
  JewelrySets: 'J07',
  Chain: 'J08',
  Pendant: 'J09',
  CharmOrBead: 'J10',
  Strands: 'J11',
  JewelryMisc: 'J99',
  NewWatch: 'M04',
  PreOwnedWatch: 'M05',
  WatchMisc: 'M99',
} as const;

export const JewelersMutualProductCodeDescriptions = {
  J01: 'Earrings',
  J02: 'Necklace',
  J03: 'Bracelet',
  J04: 'Ring',
  J05: 'Broach/Pin',
  J07: 'Jewelry Sets',
  J08: 'Chain',
  J09: 'Pendant',
  J10: 'Charm/Bead',
  J11: 'Strands(Pearl or Bead)',
  J99: 'Jewelry Misc',
  M04: 'New Watch',
  M05: 'Pre-Owned Watch',
  M99: 'Watch Misc.',
} as const;
