export interface EcomVendorProductStatus {
  account_id: number;
  account_uuid: string;
  account_name: string;
  account_include_on_ecom: boolean;
  product_id: number;
  product_uuid: string;
  product_number: string;
  product_description: string;
  product_allow_backorder: boolean;
  product_quantity: number;
  product_price: number;
  online_price: number;
  is_master_product: boolean;
  variant_parent_id: number;
  product_include_on_ecom: boolean;
  product_hidden: boolean;
  product_synced: boolean;
  ecom_image_count: number;
  sku_id?: number;
  sku_uuid?: string;
  sku?: string;
  sku_description?: string;
  sku_quantity: number;
  sku_include_on_ecom?: boolean;
  product_type_id: number;
  product_type_uuid: string;
  product_type_name: string;
  product_type_include_on_ecom: boolean;
}

export interface EcomVendorProductStatusUpdate {
  account_uuid?: string;
  product_ids?: number[];
  include_on_ecom?: boolean;
  allow_backorder?: boolean;
  sku_uuid?: string;
  update_source: string;
}

export const EcomProductRowType = {
  Product: 'product',
  ProductBackorder: 'product_backorder',
  Sku: 'sku',
  Vendor: 'vendor',
} as const;

export interface EcomProductRow {
  name: string;
  account: string;
  includeOnEcom: boolean;
  ecomDisabled: boolean;
  productType?: string;
  quantity?: number;
  price?: string;
  online_price?: string;
  backorder: boolean | undefined;
  items?: EcomProductRow[];
  rowType: string;
  synced?: boolean;
  data: EcomVendorProductStatus;
}
