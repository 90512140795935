import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, FormGroup, Input, Textarea } from 'components/core';

import { AppState } from 'types/redux-types';

import { FormFields, FormFieldsData } from './types';

const Content = styled.div({
  display: 'flex',
  margin: '5vh auto',
  flexDirection: 'column',
  width: '75%',
  maxWidth: 750,
});

const Page = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'white',
  zIndex: 999,
  overflow: 'hidden',
});

const Close = styled.span({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '0.5em',
  right: '0.5em',
  height: 45,
  width: 45,
  cursor: 'pointer',
  fontSize: '1.5em',

  svg: {
    lineHeight: 1,
  },
});

const Header = styled.header({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2em',
});

const Footer = styled.footer({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const Logo = styled.img({
  width: 100,
  marginBottom: '1.5em',
});

const Title = styled.h2({
  color: '#394359',
  fontSize: 28,
  fontWeight: 500,
});

const Text = styled.p({
  color: '#94a0b3',
  fontSize: 16,
  marginBottom: 0,
});

const ApiResponse = styled(Text)<{ isVisible: boolean }>(props => ({
  textAlign: 'center',
  marginRight: '2em',
  visibility: props.isVisible ? 'visible' : 'hidden',
}));

const Form = styled.form({
  label: {
    fontSize: 11,
    textTransform: 'uppercase',
    fontWeight: 500,
    display: 'block',
    marginBottom: 5,
  },
});

const Comment = styled(Textarea)({
  minHeight: 200,
  resize: 'none',

  '::placeholder': {
    opacity: 0.5,
  },
});

const sendSentryErr = async ({ name, email, comments }: FormFieldsData) => {
  try {
    const endpoint = `https://sentry.io/api/0/projects/crystalhq/${process.env.SENTRY_PROJECT}/user-feedback/`;

    const body = {
      event_id: Sentry.lastEventId(),
      name: name || 'Unknown',
      email,
      comments,
    };

    const req = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `DSN ${process.env.SENTRY_DSN}`,
      },
      body: JSON.stringify(body),
    });

    return req.ok;
  } catch (error) {
    console.error(error);

    return false;
  }
};

const UserFeedbackPage = () => {
  const { user } = useSelector((state: AppState) => state.root);

  const initialState = {
    name: user ? `${user.firstName} ${user.lastName}` : '',
    email: user ? user.email : '',
    comments: '',
  };

  const [isVisible, setIsVisble] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [formFields, setFormFields] = useReducer((state, newState) => ({ ...state, ...newState }), { ...initialState });

  useEffect(() => {
    const timeout = hasSubmitted ? 3500 : 0;

    if (hasSubmitted || !isVisible) {
      // to fix broken app, wait some time and then reload on the root route
      setTimeout(() => (window.location.href = '/'), timeout);
    }
  }, [hasSubmitted, isVisible]);

  const handleChange = (name: FormFields) => (event: React.KeyboardEvent) => {
    const { value } = event.currentTarget as HTMLInputElement;

    setFormFields({ [name]: value });
  };

  const onSubmitCb = async event => {
    event.preventDefault();

    if ((await sendSentryErr(formFields)) !== true) {
      setHasErrored(true);
    } else {
      setHasSubmitted(true);
    }
  };

  const isFormMissingFields = Object.values(formFields).some(field => !(field as string).length);
  const isEmailValid = formFields.email.includes('@') && formFields.email.includes('.');

  return (
    <Page>
      <Content>
        <Close onClick={() => setIsVisble(false)}>
          <i className='fa-light fa-times' />
        </Close>
        <Header>
          <Logo alt='Crystal Inventory Logo' height='auto' src='/crystal-icon.png' />
          <Title>{"It looks like we're having issues."}</Title>
          <Text>{"Our team has been notified. If you'd like to help, tell us what happened below."}</Text>
        </Header>

        <Form>
          <FormGroup label='name' required>
            <Input
              disabled={initialState.name.length > 0 || hasSubmitted}
              onChange={handleChange('name')}
              value={formFields.name}
            />
          </FormGroup>
          <FormGroup label='email' required>
            <Input
              disabled={initialState.email.length > 0 || hasSubmitted}
              onChange={handleChange('email')}
              type='email'
              value={formFields.email}
            />
          </FormGroup>
          <FormGroup label='what happened?' required>
            <Comment
              disabled={hasSubmitted}
              onChange={handleChange('comments')}
              placeholder="I clicked on 'X' and then hit 'Confirm'"
              value={formFields.comments}
            />
          </FormGroup>
        </Form>

        <Footer>
          <ApiResponse isVisible={hasSubmitted || hasErrored}>
            {hasErrored && 'Sorry, your feedback could not be sent.'}
            {hasSubmitted && 'Thank you! Your feedback has been sent.'}
          </ApiResponse>
          <Button
            disabled={isFormMissingFields || !isEmailValid || hasSubmitted}
            onClick={onSubmitCb}
            type='submit'
            variant='primary'
          >
            {'Submit'}
          </Button>
        </Footer>
      </Content>
    </Page>
  );
};

export default UserFeedbackPage;
