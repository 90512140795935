import { successReqActionType } from '../actions/helpers';
import { CREATE_PAYMENT_TERM, GET_PAYMENT_TERMS, RESET_ALL_DATA, UPDATE_PAYMENT_TERM } from '../constants/action-types';

const initialState = {
  paymentTerms: [],
};

const reducer = (state = initialState, action) => {
  const { paymentTerms } = state;
  switch (action.type) {
    case successReqActionType(GET_PAYMENT_TERMS):
      return {
        ...state,
        paymentTerms: action.payload,
      };
    case successReqActionType(CREATE_PAYMENT_TERM):
      const addedNewPaymentTerms = [...paymentTerms, action.payload];
      return {
        ...state,
        paymentTerms: addedNewPaymentTerms,
      };
    case successReqActionType(UPDATE_PAYMENT_TERM):
      const updatedNewPaymentTerms = [...paymentTerms];
      const { payload } = action;
      const currentItem = updatedNewPaymentTerms.find(pt => pt.id === payload.id);
      const currentItemIndex = updatedNewPaymentTerms.findIndex(pt => pt.id === payload.id);
      const newItem = { ...currentItem, ...payload };
      updatedNewPaymentTerms.splice(currentItemIndex, 1, newItem);
      return {
        ...state,
        paymentTerms: updatedNewPaymentTerms,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
