/* eslint-disable no-unused-vars */
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

const PhoneRender = props => {
  const { phone } = props;
  const isUSNumber = phone => phone.includes('+1');
  let newPhone = phone;
  if (!phone.includes('+')) {
    newPhone = `+${phone}`;
  }
  if (isUSNumber(newPhone)) {
    return <>{formatPhoneNumber(newPhone) || phone}</>;
  }
  return <>{formatPhoneNumberIntl(newPhone) || phone}</>;
};

export default PhoneRender;
