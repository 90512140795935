import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export interface PercentInputProps {
  disabled?: boolean;
  placeholder?: string;
  value?: any;
  maskOptions?: {
    prefix?: string;
    suffix?: string;
    includeThousandsSeparator?: boolean;
    thousandsSeparatorSymbol?: string;
    allowDecimal?: boolean;
    decimalSymbol?: string;
    decimalLimit?: string;
    requireDecimal?: boolean;
    allowNegative?: boolean;
    allowLeadingZeroes?: boolean;
    integerLimit?: number;
  };
  onChange?: (e: any) => void;
  props?: any;
}

const defaultMaskOptions = {
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 3, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const PercentInput = ({ maskOptions = {}, ...props }: PercentInputProps) => {
  const percentMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });
  /* eslint-disable-next-line */
  return <MaskedInput className='form-control' placeholder='0.0%' mask={percentMask} {...props} />;
};

export default PercentInput;
