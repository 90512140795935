import { Checkbox } from 'components/core';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const TRANSFER_ITEMS_REPORT_FIELDS = [
  {
    name: 'Transfer #',
    key: 'transfer_id',
    id: 'transfer_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transfers/${row.transfer_uuid}`}
      >
        {row.transfer_id}
      </Link>
    ),
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-number') ? i18n.t('sku:stock-number') : 'Stock #',
    id: 'sku',
    key: 'sku',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.sku_uuid}`}>
        {row.sku}
      </Link>
    ),
  },
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
  },
  {
    name: 'Description',
    key: 'description',
    id: 'description',
    isDefault: true,
  },
  {
    name: 'Vendor Code',
    key: 'account_code',
    id: 'account_code',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Nonstock',
    id: 'nonstock',
    key: 'nonstock',
    isDefault: false,
    sortable: true,
    filterable: true,
    render: (row: any) => <Checkbox checked={row.non_stock} disabled />,
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity.toFixed(2),
  },
  {
    name: 'Retail',
    id: 'retail',
    key: 'retail',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.retail),
  },
  {
    name: 'Online Price',
    id: 'online_price',
    key: 'online_price',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.online_price),
  },
  {
    name: 'Cost',
    id: 'cost',
    key: 'cost',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    isRestricted: true,
    render: (row: ReportRow) => formatMoney(row.cost),
  },
];
