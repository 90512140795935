import { i18nNamespaces } from 'i18n';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const usePurchaseOrderReportFields = () => {
  const { t } = useTranslation([i18nNamespaces.Common, i18nNamespaces.Reports]);
  return [
    {
      name: t('reports:purchase-orders-string.purchase-order-number'),
      key: 'purchase_order_id',
      id: 'purchase_order_id',
      isDefault: true,
      sortable: true,
      filterable: true,
      type: 'LINK',
      render: (row: ReportRow) => (
        <Link
          className='table-row-link'
          id='app-link'
          onClick={e => e.stopPropagation()}
          to={`/purchase-orders/${row.purchase_order_uuid}`}
        >
          {row.purchase_order_id}
        </Link>
      ),
    },
    {
      name: t('reports:purchase-orders-string.product-number'),
      id: 'product_number',
      key: 'product_number',
      type: 'LINK',
      isDefault: true,
      sortable: true,
      filterable: true,
      render: (row: ReportRow) => (
        <Link
          className='table-row-link'
          id='app-link'
          onClick={e => e.stopPropagation()}
          to={`/products/${row.product_uuid}`}
        >
          {row.product_number}
        </Link>
      ),
    },
    {
      name: t('reports:purchase-orders-string.vendor-code'),
      key: 'account_code',
      id: 'account_code',
      isDefault: true,
      sortable: true,
      filterable: true,
    },
    {
      name: t('common:status'),
      key: 'status',
      id: 'status',
      isDefault: true,
      sortable: true,
      filterable: true,
      render: (row: ReportRow) => (row.close_date ? t('common:closed') : t('common:open')),
    },
    {
      name: t('reports:purchase-orders-string.order-date'),
      key: 'order_date',
      id: 'order_date',
      isDefault: true,
      sortable: true,
      filterable: true,
      render: (row: ReportRow) => row.order_date && formatLocalDate(row.order_date),
    },
    {
      name: t('common:close-date'),
      key: 'close_date',
      id: 'close_date',
      isDefault: true,
      sortable: true,
      filterable: true,
      render: (row: ReportRow) => row.close_date && formatLocalDate(row.close_date),
    },
    {
      name: t('reports:purchase-orders-string.quantity-ordered'),
      id: 'quantity_ordered',
      key: 'quantity_ordered',
      isDefault: true,
      sortable: true,
      filterable: true,
      type: 'NUMBER',
      render: (row: ReportRow) => row.quantity_ordered.toFixed(2),
    },
    {
      name: t('reports:purchase-orders-string.quantity-received'),
      id: 'quantity_received',
      key: 'quantity_received',
      isDefault: true,
      sortable: true,
      filterable: true,
      type: 'NUMBER',
      render: (row: ReportRow) => row.quantity_received.toFixed(2),
    },
    {
      name: t('reports:purchase-orders-string.retail'),
      id: 'retail',
      key: 'retail',
      type: 'NUMBER',
      isCurrency: true,
      isDefault: true,
      sortable: true,
      filterable: true,
      render: (row: ReportRow) => formatMoney(row.retail),
    },
    {
      name: t('reports:purchase-orders-string.online-price'),
      id: 'online_price',
      key: 'online_price',
      type: 'NUMBER',
      isCurrency: true,
      isDefault: false,
      sortable: true,
      filterable: true,
      render: (row: ReportRow) => formatMoney(row.online_price),
    },
    {
      name: t('reports:purchase-orders-string.cost'),
      id: 'cost',
      key: 'cost',
      type: 'NUMBER',
      isCurrency: true,
      isDefault: true,
      sortable: true,
      filterable: true,
      isRestricted: true,
      render: (row: ReportRow) => formatMoney(row.cost),
    },
    {
      name: t('reports:purchase-orders-string.item-received'),
      id: 'item_received',
      key: 'item_received',
      isDefault: false,
      sortable: true,
      filterable: true,
      render: (row: ReportRow) => (row.quantity_received ? 'Yes' : 'No'),
    },
    {
      name: t('reports:purchase-orders-string.retail-value'),
      id: 'received_value',
      key: 'received_value',
      type: 'NUMBER',
      isCurrency: true,
      isDefault: false,
      sortable: true,
      filterable: true,
      isRestricted: true,
      render: (row: ReportRow) => (row.quantity_received ? formatMoney(row.retail * row.quantity_received) : null),
    },
  ];
};
