import { makeStyles } from '@mui/styles';

export const getTableValue = (row: any, id: any) => {
  if (!id) return null;

  const indexes = id.split('.');
  let result = row[indexes[0]];

  for (let i = 1; i < indexes.length; i++) {
    if (result) {
      result = result[indexes[i]];
    }
  }
  return result;
};

export const descendingComparator = (a: any, b: any, orderBy: any) => {
  const aValue = getTableValue(a, orderBy);
  const bValue = getTableValue(b, orderBy);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: 'desc' | 'asc', orderBy: any) => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    if (a[0].isTotal) {
      return 1;
    }
    if (b[0].isTotal) {
      return -1;
    }
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const useTableStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
  flex: {
    display: 'flex',
  },
  buttonContainer: {
    marginTop: '8px',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  filterIcon: {
    marginLeft: 3,
    width: '15px',
    cursor: 'pointer',
  },
  loading: {
    margin: '15px',
    textAlign: 'center',
  },
});

export interface TableFieldConfig {
  name: string;
  id: string;
  sortable?: boolean;
  render?: (row: any) => any;
  type?: string;
}
