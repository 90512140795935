import { _apiRequest } from 'redux/actions/system';
import {
  ADD_SNAPSHOT,
  GET_INVENTORY_SNAPSHOT,
  GET_INVENTORY_SNAPSHOTS,
  RESET_SNAPSHOT,
  SET_INVENTORY_SNAPSHOT_FILTER,
  UPDATE_INVENTORY_SNAPSHOT,
} from 'redux/constants/action-types';
import history from 'utils/history';

export const getSnapshots = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_INVENTORY_SNAPSHOTS,
    dispatch,
    method: 'Get',
    path: `inventory/snapshots`,
    callback: () => {
      dispatch({ type: RESET_SNAPSHOT });
    },
  });
};

export const getSnapshot = snapshotId => async dispatch => {
  await _apiRequest({
    actionBase: GET_INVENTORY_SNAPSHOT,
    dispatch,
    method: 'Get',
    path: `inventory/snapshot?id=${snapshotId}`,
  });
};

export const addSnapshot = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_SNAPSHOT,
    dispatch,
    method: 'Post',
    path: `inventory/snapshot`,
    payload,
    callback: data => {
      if (data) {
        history.replace(`/inventory/${data}`);
      }
    },
  });

export const setSnapshotFilter = filter => ({
  type: SET_INVENTORY_SNAPSHOT_FILTER,
  payload: filter,
});

export const updateSnapshotName = (payload, snapshotId) => async dispatch => {
  await _apiRequest({
    actionBase: UPDATE_INVENTORY_SNAPSHOT,
    dispatch,
    method: 'Put',
    payload,
    path: `inventory/snapshot?id=${snapshotId}`,
    returnPayloadOnSuccess: true,
  });
};
