import i18n from 'i18next';

import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const COST_OF_GOODS_SOLD_REPORT_FIELDS = [
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.stock-category-code')
      ? i18n.t('reports:cost-of-goods-sold.columns.stock-category-code')
      : 'Stock Category Code',
    key: 'stock_category_code',
    id: 'stock_category_code',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.stock-category')
      ? i18n.t('reports:cost-of-goods-sold.columns.stock-category')
      : 'Stock Category',
    key: 'stock_category',
    id: 'stock_category',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/accounting/inventory-accounting/cost-of-goods-sold/details/${row.stock_category_id}`}
      >
        {row.stock_category}
      </Link>
    ),
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.starting-quantity')
      ? i18n.t('reports:cost-of-goods-sold.columns.starting-quantity')
      : 'Starting Quantity',
    key: 'starting_quantity',
    id: 'starting_quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.starting-retail')
      ? i18n.t('reports:cost-of-goods-sold.columns.starting-retail')
      : 'Starting Retail $',
    key: 'starting_retail',
    id: 'starting_retail',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.starting_retail) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.starting-cost')
      ? i18n.t('reports:cost-of-goods-sold.columns.starting-cost')
      : 'Starting Cost $',
    key: 'starting_cost',
    id: 'starting_cost',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.starting_cost) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.ending-quantity')
      ? i18n.t('reports:cost-of-goods-sold.columns.ending-quantity')
      : 'Ending Quantity',
    key: 'ending_quantity',
    id: 'ending_quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.ending-retail')
      ? i18n.t('reports:cost-of-goods-sold.columns.ending-retail')
      : 'Ending Retail $',
    key: 'ending_retail',
    id: 'ending_retail',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ending_retail) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.ending-cost')
      ? i18n.t('reports:cost-of-goods-sold.columns.ending-cost')
      : 'Ending Cost $',
    key: 'ending_cost',
    id: 'ending_cost',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ending_cost) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.total-closed-sales')
      ? i18n.t('reports:cost-of-goods-sold.columns.total-closed-sales')
      : 'Total Closed Sales $',
    key: 'total_sales',
    id: 'total_sales',
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.total_sales) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.total-deposits')
      ? i18n.t('reports:cost-of-goods-sold.columns.total-deposits')
      : 'Total Deposits $',
    key: 'deposits',
    id: 'deposits',
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.deposits) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.cost-of-goods-sold')
      ? i18n.t('reports:cost-of-goods-sold.columns.cost-of-goods-sold')
      : 'Cost of Goods Sold $',
    key: 'cost_of_goods_sold',
    id: 'cost_of_goods_sold',
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.cost_of_goods_sold) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.quantity-on-hand')
      ? i18n.t('reports:cost-of-goods-sold.columns.quantity-on-hand')
      : 'All Inventory - On Hand',
    key: 'quantity_on_hand',
    id: 'quantity_on_hand',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.quantity-on-hand-asset')
      ? i18n.t('reports:cost-of-goods-sold.columns.quantity-on-hand-asset')
      : 'Asset - On Hand',
    key: 'quantity_on_hand_asset',
    id: 'quantity_on_hand_asset',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.quantity-on-hand-memo')
      ? i18n.t('reports:cost-of-goods-sold.columns.quantity-on-hand-memo')
      : 'Memo - On Hand',
    key: 'quantity_on_hand_memo',
    id: 'quantity_on_hand_memo',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.quantity-on-sol')
      ? i18n.t('reports:cost-of-goods-sold.columns.quantity-on-sol')
      : 'All Inventory - On SO / Layaway',
    key: 'quantity_on_sol',
    id: 'quantity_on_sol',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.quantity-on-sol-asset')
      ? i18n.t('reports:cost-of-goods-sold.columns.quantity-on-sol-asset')
      : 'Asset - On SO / Layaway',
    key: 'quantity_on_sol_asset',
    id: 'quantity_on_sol_asset',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.columns.quantity-on-sol-memo')
      ? i18n.t('reports:cost-of-goods-sold.columns.quantity-on-sol-memo')
      : 'Memo - On SO / Layaway',
    key: 'quantity_on_sol_memo',
    id: 'quantity_on_sol_memo',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
];
