import { failReqActionType, successReqActionType } from '../actions/helpers';
import {
  ADD_ORG_TAXJAR_ACCOUNT,
  GET_ACTIVE_TAXJAR_ACCOUNT,
  GET_TAX_RATES_FOR_ZIP,
  REMOVE_ORG_TAXJAR_ACCOUNT,
} from '../constants/action-types';

const initialState = {
  message: '',
  activeAccount: '',
  rates: <any>{},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_TAX_RATES_FOR_ZIP):
      const responsePayload = JSON.parse(action.payload);
      if (responsePayload) {
        if (responsePayload?.message === 'Rates Fetch Failed') {
          // store the postal code that did not return rates in the rates section with a message
          const currentRates = state.rates;
          currentRates[responsePayload.postal] = 'No Rates';
          return {
            ...state,
            message: 'Failed To Fetch Rates',
          };
        }

        const currentRates = state.rates;
        currentRates[responsePayload.rate.zip] = { ...responsePayload.rate };

        return {
          ...state,
          rates: currentRates,
          message: '',
        };
      }

      return {
        ...state,
      };

    case successReqActionType(GET_ACTIVE_TAXJAR_ACCOUNT):
      const jsonData = JSON.parse(action.payload);

      if (jsonData.activeAccount) {
        return {
          ...state,
          activeAccount: jsonData.activeAccount,
        };
      }

      return {
        ...state,
      };
    case successReqActionType(ADD_ORG_TAXJAR_ACCOUNT):
      if (action.type === 'ADD_ORG_TAXJAR_ACCOUNT_SUCCESS') {
        const jsonData = JSON.parse(action.payload);

        if (jsonData.activeAccount) {
          return {
            ...state,
            activeAccount: jsonData.activeAccount,
            message: 'New Key Added',
          };
        }
        if (jsonData.message) {
          return {
            ...state,
            message: jsonData.message,
          };
        }
      }

      return {
        ...state,
      };
    case failReqActionType(ADD_ORG_TAXJAR_ACCOUNT):
      return {
        ...state,
        message: 'Custom Key Exists',
      };

    case successReqActionType(REMOVE_ORG_TAXJAR_ACCOUNT):
      if (action.type === 'REMOVE_ORG_TAXJAR_ACCOUNT_SUCCESS') {
        const jsonData = JSON.parse(action.payload);
        if (jsonData.message) {
          return {
            ...state,
            message: jsonData.message,
          };
        }
      }

      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
