import { isJewelersMutualEnabled, JmStrings } from 'integrations/jewelers-mutual/utils';
import { keyBy } from 'lodash';
import { JmProductMap, JmStoreCode } from 'types/api/jewelers-mutual';
import { Location } from 'types/api/location';
import { isVatEnabled } from 'types/localization';
import {
  ADD_LOCATION,
  ADD_MARKETING_SOURCE,
  ADD_TAX_CODE,
  AMQP_SERVER_CONNECTED,
  API_SERVER_CONNECTED,
  EDIT_LOCATION,
  EDIT_MARKETING_SOURCE,
  EDIT_TAX_CODE,
  GET_CHANGE_LOG,
  GET_JM_PRODUCT_MAP,
  GET_JM_STORE_CODES,
  GET_LOCATIONS,
  GET_MARKETING_SOURCES,
  GET_ORGANIZATION,
  GET_SETTINGS,
  GET_TAX_CODES,
  GET_TIME_CLOCK,
  GET_VIEW_HISTORY_LOG,
  HIDE_MENU,
  NEEDS_UPDATE,
  REQUEST_ABORT,
  REQUEST_END,
  REQUEST_START,
  RESET_ALL_DATA,
  RESET_CHANGE_LOG,
  RESET_TABLE_PAGE,
  RESET_VIEW_HISTORY_LOG,
  SET_FILTERED_ITEMS,
  SET_ORGANIZATION,
  SET_TABLE_INFO,
  SET_USER,
  TOGGLE_JM_INTEGRATION,
  TOGGLE_MENU,
  TOGGLE_TIME_CLOCK,
  UPDATE_JM_PRODUCT_MAP,
  UPDATE_SETTING,
} from '../constants/action-types';

import { failReqActionType, successReqActionType } from '../actions/helpers';

const packageJson = require('../../../package.json');

interface OrganizationState {
  locations: { [id: number]: Location };
  taxCodes: any;
  settings: any[];
  integrations: string[];
  marketing_sources: any;
}

interface RootState {
  activeTab: any;
  alert: null | any;
  changes: any[];
  isAPIConnected: boolean;
  isAMQPConnected: boolean;
  menuCollapsed: boolean;
  topBarCollapsed: boolean;
  notifications: any[];
  pagination: any;
  organization: OrganizationState;
  shouldUpdate: boolean;
  sort: any;
  user: null | any;
  version: string;
  viewHistoryLogs: null | any;
  requests: { [key: string]: any };
  jmStoreCodes: JmStoreCode[];
  jmProductMappings: JmProductMap[];
  timeClock: any | undefined;
}

const initialState: RootState = {
  user: null,
  organization: {
    locations: {},
    taxCodes: {},
    settings: [],
    integrations: [],
    marketing_sources: {},
  },
  isAPIConnected: true,
  isAMQPConnected: false,
  version: packageJson.version,
  menuCollapsed: false,
  topBarCollapsed: false,
  pagination: {},
  sort: {},
  activeTab: {},
  changes: [],
  viewHistoryLogs: null,
  alert: null,
  notifications: [],
  shouldUpdate: false,
  requests: {},
  jmStoreCodes: [],
  jmProductMappings: [],
  timeClock: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case API_SERVER_CONNECTED:
      return { ...state, isAPIConnected: action.payload };
    case AMQP_SERVER_CONNECTED:
      return { ...state, isAMQPConnected: action.payload };
    case NEEDS_UPDATE:
      return { ...state, shouldUpdate: action.payload };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        organization: {
          ...action.payload.organization,
          isVatEnabled: isVatEnabled(action.payload.organization.base_country),
        },
      };
    case successReqActionType(GET_TIME_CLOCK):
      return {
        ...state,
        timeClock: action.payload,
      };
    case successReqActionType(TOGGLE_TIME_CLOCK):
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case successReqActionType(GET_ORGANIZATION):
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: {
          ...action.payload,
          isVatEnabled: isVatEnabled(action.payload.base_country),
        },
      };
    case successReqActionType(GET_SETTINGS):
      return {
        ...state,
        settings: action.payload,
      };
    case successReqActionType(UPDATE_SETTING):
      const newSettings = [...state.organization.settings];
      newSettings.forEach((setting: any) => {
        if (setting.name === action.payload.name) {
          setting.selected = action.payload.selected;
        }
      });
      return {
        ...state,
        organization: {
          ...state.organization,
          settings: newSettings,
        },
      };
    case successReqActionType(GET_TAX_CODES):
      return {
        ...state,
        organization: {
          ...state.organization,
          taxCodes: keyBy(action.payload, 'id'),
        },
      };
    case successReqActionType(EDIT_TAX_CODE):
    case successReqActionType(ADD_TAX_CODE):
      return {
        ...state,
        organization: {
          ...state.organization,
          taxCodes: {
            ...state.organization.taxCodes,
            [action.payload.id]: action.payload,
          },
        },
      };
    case successReqActionType(GET_MARKETING_SOURCES):
      return {
        ...state,
        organization: {
          ...state.organization,
          marketing_sources: keyBy(action.payload, 'id'),
        },
      };
    case successReqActionType(EDIT_MARKETING_SOURCE):
    case successReqActionType(ADD_MARKETING_SOURCE):
      return {
        ...state,
        organization: {
          ...state.organization,
          marketing_sources: {
            ...state.organization.marketing_sources,
            [action.payload.id]: action.payload,
          },
        },
      };
    case successReqActionType(GET_LOCATIONS):
      return {
        ...state,
        organization: {
          ...state.organization,
          locations: keyBy(action.payload, 'id'),
        },
      };
    case successReqActionType(EDIT_LOCATION):
    case successReqActionType(ADD_LOCATION):
      return {
        ...state,
        organization: {
          ...state.organization,
          locations: {
            ...state.organization.locations,
            [action.payload.id]: action.payload,
          },
        },
      };
    case TOGGLE_MENU:
      return {
        ...state,
        menuCollapsed: action.payload.flag !== undefined ? action.payload.flag : !state.menuCollapsed,
        topBarCollapsed: false,
      };
    case HIDE_MENU:
      return {
        ...state,
        menuCollapsed: true,
      };
    case SET_TABLE_INFO:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [action.payload.page]: action.payload.data,
        },
      };
    case RESET_TABLE_PAGE:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [action.payload.page]: {
            ...state[action.payload.key][action.payload.page],
            ...action.payload.data,
          },
        },
      };
    case successReqActionType(GET_CHANGE_LOG):
      return { ...state, changes: action.payload };
    case RESET_CHANGE_LOG:
      return { ...state, changes: [] };
    case successReqActionType(GET_VIEW_HISTORY_LOG):
      return { ...state, viewHistoryLogs: action.payload };
    case RESET_VIEW_HISTORY_LOG:
      return { ...state, viewHistoryLogs: null };
    case SET_FILTERED_ITEMS:
      return {
        ...state,
        filteredItems: action.payload,
      };
    case REQUEST_START:
      const abortController = action.payload.abortController;
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.payload.actionBase]: {
            abortController,
          },
        },
      };
    case REQUEST_END:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.payload.actionBase]: {
            abortController: null,
          },
        },
      };
    case REQUEST_ABORT:
      state.requests[action.payload.actionBase]?.abortController?.abort();
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.payload.actionBase]: {
            abortController: null,
          },
        },
      };
    case successReqActionType(GET_JM_STORE_CODES):
      return {
        ...state,
        jmStoreCodes: action.payload.jm_store_codes,
      };

    case successReqActionType(GET_JM_PRODUCT_MAP):
      return {
        ...state,
        jmProductMappings: action.payload.jm_product_map,
      };
    case failReqActionType(UPDATE_JM_PRODUCT_MAP):
      window.showError('Failed To Update Product Map');
      return {
        ...state,
      };

    case successReqActionType(TOGGLE_JM_INTEGRATION):
      const integrationsContainsJM = isJewelersMutualEnabled(state.organization.integrations);
      if (action.payload.active) {
        if (!integrationsContainsJM) {
          return {
            ...state,
            organization: {
              ...state.organization,
              integrations: [...state.organization.integrations, JmStrings.JmIntegrationName],
            },
          };
        }
      }

      if (integrationsContainsJM) {
        return {
          ...state,
          organization: {
            ...state.organization,
            integrations: state.organization.integrations.filter(
              integString => integString !== JmStrings.JmIntegrationName
            ),
          },
        };
      }

      return {
        ...state,
      };

    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
