import { _apiRequest } from 'redux/actions/system';
import { ADD_GIFT_CARD, SET_GIFT_CARD } from 'redux/constants/action-types';
import * as Request from 'utils/request';

export const addGiftCard = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_GIFT_CARD,
    dispatch,
    method: 'Post',
    path: `gift`,
    payload,
    onFailure: resp => {
      if (resp.status === 400) {
        window.showError('A gift card already exists in the range you provided. Please try a different range');
      }
    },
  });

export const setGiftCard = payload => ({
  type: SET_GIFT_CARD,
  payload,
});

export const getGiftCard = giftCardId => async dispatch => {
  const response = await Request.Get(`gift?id=${giftCardId}`);
  if (response.data) {
    dispatch(setGiftCard(response.data));
  } else {
    console.error(response.error);
  }
};

export const updateGiftCard = (giftCardId, data) => async dispatch => {
  const response = await Request.Put(`gift`, data);

  if (response.data) {
    dispatch(getGiftCard(giftCardId));
  } else {
    console.error(response.error);
  }
};
