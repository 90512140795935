import { Grid, InputLabel } from '@mui/material';
import ListView from 'components/common/ListView';
import { FormGroup, Input, Select, Switch } from 'components/core';
import { debounce } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSettings, updateSetting } from 'redux/actions';
import { Setting } from 'types/api/setting';
import { setDocumentTitle } from 'utils/navigation-utils';

export enum AdminSetting {
  ClosedOrderEditWindow = 'CLOSED_ORDER_EDIT_WINDOW',
  DepositThreshold = 'DEPOSIT_THRESHOLD',
  AdvancedTaxEnabled = 'ENABLED_ADVANCED_TAX',
  ExistingTransactionPaymentEditWindow = 'EXISTING_TRANSACTION_PAYMENT_EDIT_WINDOW',
  ForeignExchangeRateBuffer = 'FOREIGN_EXCHANGE_RATE_BUFFER',
  DueDateOnReceipt = 'INCLUDE_DUE_DATE_ON_RECEIPT',
  HiddenLocationsOnDashboard = 'INCLUDE_HIDDEN_LOCATIONS_ON_DASHBOARD',
  RepairImageOnReceipt = 'INCLUDE_REPAIR_IMAGE_ON_RECEIPT',
  RepairsOnSalesDashboard = 'INCLUDE_REPAIRS_ON_SALES_DASHBOARD',
  ReturnToStockWindow = 'RETURN_TO_STOCK_WINDOW',
  SkuOnHoldWindow = 'SKU_ON_HOLD_WINDOW',
  ShouldDefaultSalesperson = 'SHOULD_SALESPERSON_DEFAULT_USER',
  ShowCostOnTransfers = 'SHOW_COST_ON_TRANSFERS',
  EnableMinimumClientPayment = 'ENABLE_MINIMUM_CLIENT_PAYMENT',
  MinimumClientPayment = 'MINIMUM_CLIENT_PAYMENT',
  ShowTocOnReceipt = 'SHOW_TOC_ON_RECEIPT',
  ExcludeNoImageItemsFromSync = 'EXCLUDE_NO_IMAGE_ITEMS',
  EnableDynamicPrint = 'ENABLE_DYNAMIC_PRINT',
  DefaultRepairMarginLaborCosts = 'DEFAULT_REPAIR_MARGIN_LABOR_COSTS',
  OpenJewelersMutualModalOnSkuAdd = 'AUTO_DISPLAY_JM_CARE_PLAN',
  FastSellerThreshold = 'FAST_SELLER_THRESHOLD',
  DisableClosedTransactionWithBalance = 'DISABLE_CLOSED_TRANSACTION_WITH_BALANCE',
  VendorCommissionRatesOverrideRules = 'VENDOR_COMMISSION_RATES_OVERRIDE_RULES',
  IncludeClientIdOnReceipts = 'INCLUDE_CLIENT_ID_ON_RECEIPTS',
  RepairPriceListEnabled = 'REPAIR_PRICE_LIST_ENABLED',
  RepairClosePromptEnabled = 'REPAIR_CLOSE_PROMPT_ENABLED',
  OpenReviewModalOnTransactionView = 'OPEN_REVIEW_MODAL_ON_TRANSACTION',
}

export interface SettingsProps {
  getSettings: () => void;
  updateSetting: (payload: { name: string; selected: number }) => void;
  settings: Setting[];
}

const SettingInterfaceTypes = {
  Select: 'select',
  Switch: 'switch',
  PercentageInput: 'percentage-input',
} as const;

const Settings = (props: SettingsProps) => {
  const { settings } = props;
  useEffect(() => {
    setDocumentTitle('Preferences');
    props.getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildPercentageInputValuesStore = () => {
    const percentageInputSettings = settings?.filter(setting => setting.type === SettingInterfaceTypes.PercentageInput);
    const valuesStore = {};
    percentageInputSettings.forEach(pis => {
      valuesStore[pis.name] = pis.selected;
    });
    return valuesStore;
  };
  const [percentageInputValues, setPercentageInputValues] = useState(buildPercentageInputValuesStore());

  useEffect(() => {
    setPercentageInputValues(buildPercentageInputValuesStore());
  }, [settings]);

  const handleChange = (key, value) => {
    const payload = {
      name: key,
      selected: value,
    };
    props.updateSetting(payload);
  };

  const enableMinimumPaymentSetting = settings?.find(
    setting => setting.name === AdminSetting.EnableMinimumClientPayment
  );

  const handleDebouncedSettingChange = debounce((event, setting) => {
    handleChange(setting.name, Number(event.target.value));
  }, 500);

  const handlePercentageInputSettingChange = (event, setting) => {
    const mutablePIV = { ...percentageInputValues };
    mutablePIV[setting.name] = event.target.value;
    handleDebouncedSettingChange(event, setting);
    setPercentageInputValues(mutablePIV);
  };

  if (!settings.length) return null;

  return (
    <ListView
      content={
        <Grid container direction='column' spacing={4} style={{ padding: 30 }}>
          {settings.map(setting => (
            <Grid key={setting.name} item xs>
              <InputLabel style={{ userSelect: 'none' }}>{setting.label}</InputLabel>
              {setting.type === SettingInterfaceTypes.Select && (
                <Select
                  defaultValue={{
                    value: setting.selected,
                    label: `${setting.selected} ${setting.unit || ''}`,
                  }}
                  isDisabled={
                    setting.name === AdminSetting.MinimumClientPayment && !enableMinimumPaymentSetting?.selected
                  }
                  menuPlacement='auto'
                  onChange={val => {
                    handleChange(setting.name, val.value);
                  }}
                  options={setting.options?.map(t => ({
                    value: t,
                    name: t,
                    label: `${t} ${setting.unit || ''}`,
                  }))}
                />
              )}
              {setting.type === SettingInterfaceTypes.Switch && (
                <Switch
                  checked={setting.selected === 1}
                  onChange={checked => handleChange(setting.name, checked ? 1 : 0)}
                />
              )}
              {setting.type === SettingInterfaceTypes.PercentageInput && (
                <FormGroup>
                  <Input
                    onChange={event => handlePercentageInputSettingChange(event, setting)}
                    type='number'
                    value={percentageInputValues[setting.name]}
                  />
                </FormGroup>
              )}
            </Grid>
          ))}
        </Grid>
      }
      title='Preferences'
    />
  );
};

const mapStateToProps = state => ({
  settings: state.root.organization.settings,
});

export default connect(mapStateToProps, {
  getSettings,
  updateSetting,
})(memo(Settings));
