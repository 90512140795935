import { successReqActionType } from '../actions/helpers';
import {
  ADD_INVOICE,
  EDIT_INVOICE,
  GET_INVOICE,
  RESET_ALL_DATA,
  SET_MERCH_PRODUCT_ID,
} from '../constants/action-types';

const initialState = {
  // A product created within receive flow
  productId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(ADD_INVOICE):
      return state;
    case successReqActionType(GET_INVOICE):
      return { ...state, [action.payload.uuid]: action.payload };
    case successReqActionType(EDIT_INVOICE):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          ...action.payload,
        },
      };
    case SET_MERCH_PRODUCT_ID:
      return { ...state, productId: action.payload };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
