import accounting from 'accounting-js';
import _ from 'lodash';
import { getStore } from 'redux/store';
import { TaxCode } from 'types/api/taxcode';
import { CurrencyCode } from 'types/localization';

export interface CurrencyOpts {
  precision?: number;
  symbol?: string;
  input?: boolean;
  format?: CurrencyFormatOpts;
}

export interface CurrencyFormatOpts {
  pos?: string;
  neg?: boolean;
  zero?: string;
}

const altCurrencies = [CurrencyCode.AED, CurrencyCode.EUR, CurrencyCode.GBP];

const isAltCurrency = currency => {
  return altCurrencies.includes(currency);
};

export const formatMoney = (
  text: string | number | null | undefined,
  overrideOpts: CurrencyOpts = {},
  currency?: CurrencyCode
) => {
  let currencyUsed = currency;

  if (!currencyUsed) {
    currencyUsed = getStore().getState().root.organization.currency;
  }

  const defaultOpts = {
    symbol: '$',
    precision: 2,
    format: {
      pos: '%s%v',
      neg: overrideOpts.input ? '-%s%v' : '(%s%v)',
      zero: '%s0.00',
    },
  } as any;

  // Handle formatting additional currencies
  if (isAltCurrency(currencyUsed)) {
    switch (currencyUsed) {
      case CurrencyCode.AED:
        defaultOpts.symbol = '';
        defaultOpts.format = {
          pos: '%s%v AED',
          neg: overrideOpts.input ? '-%s%v' : '(%s%v) AED',
          zero: '%s0.00 AED',
        };
        break;
      case CurrencyCode.EUR:
        defaultOpts.symbol = '€';
        break;
      case CurrencyCode.GBP:
        defaultOpts.symbol = '£';
        break;
      default:
        break;
    }
  }

  const opts = _.merge(defaultOpts, overrideOpts);

  return `${accounting.formatMoney(text, opts)}`;
};

export const unformatCurrency = (currencyString: string): number => accounting.unformat(currencyString);

export const getCurrencyValue = (value: string | number | null | undefined) => {
  if (!value) return null;

  return typeof value === 'number' ? value : unformatCurrency(value);
};

export const formatNumber = value => {
  return value.toLocaleString();
};

/*
    Function takes a currency string and returns a whole number
    string which is required for credit card processing API.

    Example "$15.38" => "1538"
  */
export const convertAmountToWholeNumber = amount => {
  if (!amount || typeof amount !== 'string') {
    return null;
  }

  const numericValue = getCurrencyValue(amount);

  if (!numericValue || numericValue < 0) return null;

  const wholeNumber = Math.round(numericValue * 100);

  return String(wholeNumber);
};

const TAX_RATE_ROUNDING_PLACES = 3;

export const formatTaxRate = (taxRate: number) => {
  return formatPercent(taxRate * 100, TAX_RATE_ROUNDING_PLACES);
};

export const formatPercent = (value: number, decimals = 2) => {
  return `${accounting.toFixed(value, decimals)}%`;
};

export const getTaxString = (t: TaxCode) => `${t.name} - ${formatTaxRate(t.taxRate)}`;

export const getTaxCodeOptions = (taxCodes: TaxCode[] = []) =>
  taxCodes
    .filter(taxCode => taxCode.active)
    .map(taxCode => ({
      id: taxCode.id,
      value: taxCode.id,
      label: getTaxString(taxCode),
    }));

export const formatZillionCurrency = (value: number) => {
  // Zillion does not take in decimals, multiply by 100 and remove all decimal points
  return Math.trunc(value * 100);
};
