import { Link } from 'react-router-dom';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const INVENTORY_ADJUSTMENT_REPORT_FIELDS = [
  {
    name: 'Stock #',
    id: 'stock_number',
    key: 'stock_number',
    type: 'LINK',
    render: (row: any) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row?.stock_uuid}`}>
        {row.stock_number}
      </Link>
    ),
    isDefault: true,
  },
  {
    name: 'Product #',
    key: 'product_number',
    id: 'product_number',
    render: (row: any) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row?.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
    type: 'LINK',
    isDefault: true,
  },
  {
    name: 'Description',
    key: 'description',
    id: 'description',
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Quantity',
    key: 'quantity',
    id: 'quantity',
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Cost',
    key: 'cost',
    id: 'cost',
    render: (row: ReportRow) => formatMoney(row.cost),
    type: 'CURRENCY',
    isDefault: true,
  },
  {
    name: 'Total Cost',
    key: 'total_cost',
    id: 'total_cost',
    render: (row: ReportRow) => formatMoney(row.total_cost),
    type: 'CURRENCY',
    isDefault: true,
  },
  {
    name: 'Retail',
    key: 'retail',
    id: 'retail',
    render: (row: ReportRow) => formatMoney(row.retail),
    type: 'CURRENCY',
    isDefault: true,
  },
  {
    name: 'Total Retail',
    key: 'total_retail',
    id: 'total_retail',
    render: (row: any) => formatMoney(row.total_retail),
    type: 'CURRENCY',
    isDefault: true,
  },
  {
    name: 'User First Name',
    key: 'user_first_name',
    id: 'user_first_name',
    filterable: true,
    isDefault: true,
  },
  {
    name: 'User Last Name',
    key: 'user_last_name',
    id: 'user_last_name',
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Notes',
    key: 'notes',
    id: 'notes',
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Date',
    key: 'date',
    id: 'date',
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.date),
    isDefault: true,
  },
];
