import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import {
  CREATE_APPRAISAL,
  GET_APPRAISAL,
  GET_APPRAISAL_ORDERS,
  GET_ORDERS_BY_TYPE,
  GET_ORDERS_BY_UUIDS,
  RESET_ALL_DATA,
  UPDATE_APPRAISAL,
  UPDATE_APPRAISAL_EXTRA_INFO,
  UPDATE_ORDER,
} from '../constants/action-types';

const initialState = {
  orders: {},
  appraisals: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_ORDERS_BY_TYPE):
      return { ...state, orders: keyBy(action.payload, 'uuid') };
    case successReqActionType(GET_ORDERS_BY_UUIDS):
      return { ...state, orders: keyBy(action.payload, 'uuid') };
    case successReqActionType(UPDATE_ORDER):
      return {
        ...state,
        [action.orderID]: {
          ...state[action.orderID],
          ...action.payload,
        },
      };
    case RESET_ALL_DATA:
      return initialState;
    case successReqActionType(GET_APPRAISAL_ORDERS):
      return {
        ...state,
        appraisals: keyBy(action.payload, 'uuid'),
      };
    case successReqActionType(CREATE_APPRAISAL):
      return {
        ...state,
        appraisals: {
          ...state.appraisals,
          [action.payload.uuid]: action.payload,
        },
      };
    case successReqActionType(GET_APPRAISAL):
    case successReqActionType(UPDATE_APPRAISAL):
      return {
        ...state,
        appraisals: {
          ...state.appraisals,
          [action.payload.uuid]: {
            ...state.appraisals[action.payload.uuid],
            ...action.payload,
          },
        },
      };
    case successReqActionType(UPDATE_APPRAISAL_EXTRA_INFO):
      return {
        ...state,
        appraisals: {
          ...state.appraisals,
          [action.payload.appraisal_uuid]: {
            ...state.appraisals[action.payload.appraisal_uuid],
            extra_name: action.payload.name,
            extra_street: action.payload.street,
            extra_state: action.payload.state,
            extra_city: action.payload.city,
            extra_country: action.payload.country,
            extra_email: action.payload.email,
            extra_phone: action.payload.phone,
            extra_postal: action.payload.postal,
            extra_request: action.payload.request,
            extra_ap_form: action.payload.appraisal_form,
            extra_ins_company: action.payload.insurance_company,
            extra_ins_policy_type: action.payload.insurance_policy_type,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
