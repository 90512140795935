import { Grid, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import { memo, PropsWithChildren } from 'react';

interface GridProps extends PropsWithChildren {
  styles?: any;
}

export enum GridGap {
  ExtraSmall = 'space.50',
  Small = 'space.100',
  Medium = 'space.200',
  Large = 'space.300',
  ExtraLarge = 'space.400',
}

const CrystalGrid = ({ children, styles }: GridProps) => {
  const responsiveStyles = xcss({
    [media.above.xs]: { gridTemplateColumns: 'repeat(2, 1fr)' },
    [media.above.sm]: { gridTemplateColumns: 'repeat(3, 1fr)' },
    [media.above.md]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [media.above.xl]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    ...styles,
  });

  return (
    <Grid gap={GridGap.Medium} xcss={responsiveStyles}>
      {children}
    </Grid>
  );
};

export default memo(CrystalGrid);
