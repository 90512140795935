import { _apiRequest } from 'redux/actions/system';
import {
  ADD_ROLE,
  EDIT_PERMISSION,
  EDIT_ROLE,
  EDIT_ROLE_PERMISSION,
  GET_LAYOUTS,
  GET_PERMISSIONS,
  GET_ROLES,
  SELECT_LAYOUT,
} from 'redux/constants/action-types';

export const getRoles = () => async dispatch =>
  _apiRequest({
    actionBase: GET_ROLES,
    dispatch,
    method: 'Get',
    path: 'roles',
  });

export const addRole = role => async dispatch =>
  _apiRequest({
    actionBase: ADD_ROLE,
    dispatch,
    method: 'Post',
    path: `roles`,
    payload: role,
  });

export const editRole = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_ROLE,
    dispatch,
    method: 'Put',
    path: `roles`,
    payload,
  });

export const editRolePermission = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_ROLE_PERMISSION,
    dispatch,
    method: 'Put',
    path: `roles`,
    payload,
  });

export const getPermissions = () => async dispatch =>
  _apiRequest({
    actionBase: GET_PERMISSIONS,
    dispatch,
    method: 'Get',
    path: 'permissions',
  });

export const editPermission = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_PERMISSION,
    dispatch,
    method: 'Put',
    path: `permissions`,
    payload,
  });

export const getLayouts = () => async dispatch => {
  await _apiRequest({
    actionBase: GET_LAYOUTS,
    dispatch,
    method: 'Get',
    path: 'admin/layouts',
  });
};

export const selectLayout = payload => async dispatch => {
  await _apiRequest({
    actionBase: SELECT_LAYOUT,
    dispatch,
    method: 'Put',
    path: `admin/layout`,
    payload,
    returnPayloadOnSuccess: true,
  });
};
