import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { Deployment } from 'utils/deployment';

const apiAuthHeader = headers => {
  const token = Cookies.get('x-auth-token');

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};

export const baseApiV2 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${Deployment.hostBase}/v2`,
    prepareHeaders: apiAuthHeader,
  }),
  reducerPath: 'crystalApiV2',
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
