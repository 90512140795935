import { css } from '@emotion/css';
import ReactSwitch from 'react-switch';
import { Theme } from 'styles/theme';

interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  onChange?: any | undefined;
  offLabel?: string;
  onLabel?: string;
}

const Switch = (props: SwitchProps) => {
  const { checked, onChange, disabled = false, offLabel, onLabel } = props;

  const Dimensions = {
    width: 28,
    height: 16,
  };

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        user-select: none;

        span {
          font-size: ${Theme.font.size.small};
        }
      `}
    >
      {offLabel && (
        <span
          className={css`
            margin-right: 10px;
          `}
        >
          {offLabel}
        </span>
      )}

      <ReactSwitch
        checked={checked}
        checkedIcon={false}
        disabled={disabled}
        height={Dimensions.height}
        offColor={Theme.switch.offColor}
        onChange={onChange}
        onColor={Theme.switch.onColor}
        uncheckedIcon={false}
        width={Dimensions.width}
      />

      {onLabel && (
        <span
          className={css`
            margin-left: 10px;
          `}
        >
          {onLabel}
        </span>
      )}
    </div>
  );
};

export default Switch;
