import { Link } from 'react-router-dom';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const REPAIRS_REPORT_FIELDS = [
  {
    name: 'Transaction Date',
    id: 'transaction_date',
    key: 'transaction_date',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.transaction_date),
  },
  {
    name: 'Close Date',
    id: 'close_date',
    key: 'close_date',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.close_date),
  },
  {
    name: 'Due Date',
    id: 'due_date',
    key: 'due_date',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.due_date),
  },
  {
    name: 'Transaction #',
    id: 'transaction_id',
    key: 'transaction_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transactions/${row.order_uuid}`}
      >
        {row.transaction_id}
      </Link>
    ),
  },
  {
    name: 'Job Type',
    id: 'job_type',
    key: 'job_type',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Client Name',
    id: 'customer_name',
    key: 'customer_name',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_name}
      </Link>
    ),
  },
  {
    name: 'Note',
    key: 'note',
    id: 'note',
    render: (row: ReportRow) => row.note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Internal Note',
    key: 'internal_note',
    id: 'internal_note',
    render: (row: ReportRow) => row.internal_note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Description of Item',
    key: 'description_of_item',
    id: 'description_of_item',
    render: (row: ReportRow) => row.description_of_item,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Scope of Work',
    key: 'scope_of_work',
    id: 'scope_of_work',
    render: (row: ReportRow) => row.scope_of_work,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson Name',
    key: 'salesperson_name',
    id: 'salesperson_name',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Location',
    key: 'location_name',
    id: 'location_name',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Status',
    key: 'status',
    id: 'status',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Most Recent Status',
    key: 'status_name',
    id: 'status_name',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Est. Insurance Value',
    key: 'estimated_value',
    id: 'estimated_value',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.estimated_value || 0),
  },
  {
    name: 'Est. To Repair',
    key: 'estimate_to_repair',
    id: 'estimate_to_repair',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.estimate_to_repair || 0),
  },
  {
    name: 'Under Warranty',
    key: 'under_warranty',
    id: 'under_warranty',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Item Image',
    key: 'primary_attachment',
    id: 'primary_attachment',
    type: 'IMAGE',
    isDefault: false,
    render: (row: ReportRow) =>
      row?.primary_attachment && (
        <img
          alt='product'
          id='product-image'
          src={row.primary_attachment}
          style={{ maxHeight: '100px', minHeight: '50px' }}
        />
      ),
  },
];
