import { deepForEach, TElement } from './childrenDeepMap';
import { isTab, isTabPanel } from './elementTypes';

export function getTabsCount(children: TElement[]) {
  let tabCount = 0;
  deepForEach(children, (child: TElement) => {
    if (isTab(child)) tabCount++;
  });

  return tabCount;
}

export function getPanelsCount(children: TElement[]) {
  let panelCount = 0;
  deepForEach(children, (child: TElement) => {
    if (isTabPanel(child)) panelCount++;
  });

  return panelCount;
}
