import { successReqActionType } from '../actions/helpers';
import {
  ADD_PRODUCT,
  DELETE_PRODUCT_ATTACHMENTS,
  DELETE_PRODUCT_IMAGE,
  EDIT_DIAMOND_ATTRIBUTES,
  EDIT_PRODUCT,
  EDIT_PRODUCT_META,
  GET_DIAMOND_ATTRIBUTES,
  GET_MERCH_RECEIVED_HISTORY,
  GET_PRODUCT,
  GET_PRODUCT_ATTACHMENTS,
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_IMAGES,
  GET_PRODUCT_INVENTORY,
  GET_PRODUCT_MOVEMENT,
  GET_PRODUCT_SALES,
  GET_PRODUCT_TRANSACTIONS,
  GET_PRODUCT_WISHLIST,
  GET_PURCHASE_HISTORY,
  GET_REORDER_PRODUCTS,
  GET_TAG,
  GET_TAGS,
  GET_WARRANTY_ITEMS,
  RESET_ALL_DATA,
  RESET_NEW_PRODUCT,
  SET_ATTRIBUTE_MAP,
  SET_STANDARD_ATTRIBUTES,
} from '../constants/action-types';

const initialState = {
  products: {},
  // Flag that is set on ADD_PRODUCT and cleared on RESET_NEW_PRODUCT_ID
  newProduct: null,
  // Retrieving an individual product pulls more data
  completeProducts: {},
  // Data needed for printing SKU tag labels on `/print?type=tag` route
  tag: {},
  tags: [],
  reorderItems: [],
  warrantyItems: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_NEW_PRODUCT:
      return {
        ...state,
        newProduct: null,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.uuid]: {
            ...state.products[action.payload.uuid],
            ...action.payload,
          },
        },
        newProduct: action.payload,
      };
    case EDIT_PRODUCT:
      if (action.imageUpdate) {
        return {
          ...state,
          completeProducts: {
            ...state.completeProducts,
            [action.payload.uuid]: {
              ...state.completeProducts[action.payload.uuid],
              bucketKey: action.payload.bucketKey,
            },
          },
        };
      }

      const product = state.products[action.payload.uuid];
      const completeProduct = state.completeProducts[action.payload.uuid];
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.uuid]: {
            ...product,
            ...action.payload,
            account: action.vendor || (product && product.account),
          },
        },
        completeProducts: {
          ...state.completeProducts,
          [action.payload.uuid]: {
            ...completeProduct,
            ...action.payload,
            account: action.vendor || (completeProduct && completeProduct.account),
          },
        },
      };
    case EDIT_PRODUCT_META:
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.payload.uuid]: {
            ...state.completeProducts[action.payload.uuid],
            ...action.payload,
          },
        },
      };
    case successReqActionType(SET_STANDARD_ATTRIBUTES):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            ...action.payload,
          },
        },
      };
    case successReqActionType(GET_PRODUCT):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.payload.uuid]: {
            ...state.completeProducts[action.payload.uuid],
            ...action.payload,
          },
        },
      };
    case successReqActionType(GET_PRODUCT_INVENTORY):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            items: action.payload,
          },
        },
      };
    case successReqActionType(GET_PRODUCT_SALES):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            sales: action.payload,
          },
        },
      };
    case successReqActionType(GET_PRODUCT_TRANSACTIONS):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            transactions: action.payload,
          },
        },
      };
    case successReqActionType(GET_PRODUCT_WISHLIST):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            wishlist: action.payload,
          },
        },
      };
    case successReqActionType(GET_PRODUCT_MOVEMENT):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            movement: action.payload,
          },
        },
      };
    case successReqActionType(GET_DIAMOND_ATTRIBUTES):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            diamond: action.payload,
          },
        },
      };
    case successReqActionType(EDIT_DIAMOND_ATTRIBUTES):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            diamond: action.payload,
          },
        },
      };
    case successReqActionType(GET_PRODUCT_ATTACHMENTS):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            fullAttachments: action.payload,
          },
        },
      };
    case successReqActionType(DELETE_PRODUCT_ATTACHMENTS):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.payload.uuid]: {
            ...state.completeProducts[action.payload.uuid],
            attachments: state.completeProducts[action.payload.uuid].attachments.filter(
              attachment => attachment.id !== action.payload.attachment_id
            ),
          },
        },
      };
    case successReqActionType(GET_PRODUCT_IMAGES):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            images: action.payload,
          },
        },
      };
    case successReqActionType(DELETE_PRODUCT_IMAGE):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.payload.uuid]: {
            ...state.completeProducts[action.payload.uuid],
            images: state.completeProducts[action.payload.uuid].images.filter(
              attachment => attachment.id !== action.payload.attachment_id
            ),
          },
        },
      };
    case successReqActionType(GET_PRODUCT_ATTRIBUTES):
      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            attributes: action.payload,
          },
        },
      };
    case successReqActionType(GET_PURCHASE_HISTORY):
      return {
        ...state,
        purchaseHistory: action.payload,
      };
    case successReqActionType(GET_MERCH_RECEIVED_HISTORY):
      return {
        ...state,
        merchReceivedHistory: action.payload,
      };
    case SET_ATTRIBUTE_MAP:
      const attributeMap = {};

      for (let i = 0; i < action.payload.length; i++) {
        const attribute = action.payload[i];
        attributeMap[attribute.attributeID] = attribute.value;
      }

      return {
        ...state,
        completeProducts: {
          ...state.completeProducts,
          [action.uuid]: {
            ...state.completeProducts[action.uuid],
            attributeMap,
          },
        },
      };
    case successReqActionType(GET_TAG):
      return {
        ...state,
        tag: action.payload,
      };
    case successReqActionType(GET_TAGS):
      return {
        ...state,
        tags: action.payload,
      };
    case successReqActionType(GET_REORDER_PRODUCTS):
      return {
        ...state,
        reorderItems: action.payload,
      };
    case successReqActionType(GET_WARRANTY_ITEMS):
      return {
        ...state,
        warrantyItems: action.payload,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
