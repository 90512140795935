import { formatMoney, formatPercent } from 'utils/money';

export const SALES_BY_LOCATION_REPORT_FIELDS = [
  {
    name: 'Location',
    key: 'location',
    id: 'location',
    isDefault: true,
  },
  {
    name: '# of Transactions',
    key: 'transaction_count',
    id: 'transaction_count',
    type: 'NUMBER',
    isDefault: true,
  },
  {
    name: 'Avg. Transaction Value',
    key: 'atv',
    id: 'atv',
    type: 'CURRENCY',
    isDefault: true,
    render: row => formatMoney(row.transaction_count === 0 ? 0 : row.ext_price / row.transaction_count),
  },
  {
    name: 'Quantity',
    key: 'quantity',
    id: 'quantity',
    type: 'NUMBER',
    isDefault: true,
  },
  {
    name: 'Retail',
    key: 'retail',
    id: 'retail',
    type: 'CURRENCY',
    isDefault: true,
    render: row => formatMoney(row.retail || 0),
  },
  {
    name: 'Discount',
    key: 'discount',
    id: 'discount',
    type: 'CURRENCY',
    isDefault: true,
    render: row => formatMoney(row.discount || 0),
  },
  {
    name: 'Ext. Price',
    key: 'ext_price',
    id: 'ext_price',
    type: 'CURRENCY',
    isDefault: true,
    render: row => formatMoney(row.ext_price || 0),
  },
  {
    name: 'Tax',
    key: 'tax',
    id: 'tax',
    type: 'CURRENCY',
    isDefault: false,
    render: row => formatMoney(row.tax || 0),
  },
  {
    name: 'Total (incl. tax)',
    key: 'total',
    id: 'total',
    type: 'CURRENCY',
    isDefault: false,
    render: row => formatMoney(row.total || 0),
  },
  {
    name: 'Cost',
    key: 'cost',
    id: 'cost',
    type: 'CURRENCY',
    isDefault: true,
    render: row => formatMoney(row.cost || 0),
  },
  {
    name: 'Job Cost',
    key: 'job_cost',
    id: 'job_cost',
    type: 'CURRENCY',
    isDefault: false,
    render: row => formatMoney(row.job_cost || 0),
  },
  {
    name: 'Gross Profit',
    key: 'gross_profit',
    id: 'gross_profit',
    type: 'CURRENCY',
    isDefault: true,
    render: row => formatMoney(row.gross_profit || 0),
  },
  {
    name: 'Gross Profit %',
    key: 'gross_profit_pct',
    id: 'gross_profit_pct',
    type: 'CURRENCY',
    isDefault: true,
    render: row => formatPercent(row.ext_price === 0 ? 0 : (row.gross_profit / row.ext_price) * 100),
  },
];
