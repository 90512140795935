import { CSSProperties, MouseEvent, ReactNode } from 'react';

interface SlideDrawerProps {
  className?: string;
  show: boolean;
  children: ReactNode;
  onBackdropClick?: (e: MouseEvent) => void;
  needBackdrop: boolean;
  props?: any;
  style?: CSSProperties;
}

const SlideDrawer = ({ className, show, children, onBackdropClick, needBackdrop, ...props }: SlideDrawerProps) => (
  <div className={`crystal-slide-drawer-wrapper ${className || ''}`}>
    <div className={`crystal-slide-drawer ${show ? 'drawer-opened' : 'drawer-closed'}`} {...props}>
      {children}
    </div>
    {/* eslint-disable-next-line */}
    {needBackdrop && show && <div className='crystal-slider-backdrop' onClick={onBackdropClick || (() => {})} />}
  </div>
);

export default SlideDrawer;
