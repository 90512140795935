import { InventoryCaseStock } from 'components/Inventory/InventoryCases/services/inventory-cases-api';
import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { translate } from 'utils/translation';

export const INVENTORY_CASE_SUMMARY_FIELDS = [
  {
    name: translate('inventory', 'inventory-cases.inventory-case', 'Inventory Case'),
    id: 'name',
    key: 'name',
    isDefault: true,
    overrideCellWidth: true,
  },
  {
    name: translate('inventory', 'inventory-cases.number-of-items', 'Number of Items'),
    key: 'stock_quantity',
    id: 'stock_quantity',
    isDefault: true,
    overrideCellWidth: true,
    render: row => row.stock_quantity || 0,
  },
];

export const INVENTORY_CASE_DETAILS_FIELD = [
  {
    name: translate('inventory', 'inventory-cases.columns.stock-number', 'Stock Number'),
    key: 'stock_number',
    id: 'stock_number',
    type: 'LINK',
    render: (row: InventoryCaseStock) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.uuid}`}>
        {row.stock_number}
      </Link>
    ),
    isDefault: true,
  },
  {
    name: translate('inventory', 'inventory-cases.columns.description', 'Description'),
    key: 'description',
    id: 'description',
    isDefault: true,
  },
  {
    name: translate('inventory', 'inventory-cases.columns.location', 'Location'),
    key: 'location_name',
    id: 'location_name',
    isDefault: true,
  },
  {
    name: translate('inventory', 'inventory-cases.columns.product-number', 'Product Number'),
    key: 'product_number',
    id: 'product_number',
    type: 'LINK',
    render: (row: InventoryCaseStock) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
    isDefault: true,
  },
  {
    name: translate('inventory', 'inventory-cases.columns.vendor', 'Vendor'),
    key: 'account_name',
    id: 'account_name',
  },
  {
    name: translate('inventory', 'inventory-cases.columns.quantity', 'Quantity'),
    key: 'quantity',
    id: 'quantity',
    type: 'NUMBER',
    render: (row: InventoryCaseStock) => row.quantity,
    isDefault: true,
  },
  {
    name: translate('inventory', 'inventory-cases.columns.retail', 'Retail'),
    key: 'retail',
    id: 'retail',
    type: 'NUMBER',
    isCurrency: true,
    render: (row: InventoryCaseStock) => formatMoney(row.retail),
    isDefault: true,
  },
  {
    name: translate('inventory', 'inventory-cases.columns.cost', 'Cost'),
    key: 'cost',
    id: 'cost',
    type: 'NUMBER',
    isCurrency: true,
    render: (row: InventoryCaseStock) => formatMoney(row.cost),
    isDefault: true,
  },
];
