import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const WISHLIST_REPORT_FIELDS = [
  {
    name: 'Date Added',
    id: 'created',
    key: 'created',
    sortable: true,
    filterable: true,
    type: 'DATE',
    isDefault: true,
    render: (row: ReportRow) => formatDate(row.created),
  },
  {
    name: 'Client Name',
    id: 'customer_name',
    key: 'customer_name',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_name}
      </Link>
    ),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
  },
  {
    name: 'Vendor Name',
    id: 'vendor_name',
    key: 'vendor_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
  },
  {
    name: 'Description',
    id: 'description',
    key: 'description',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson',
    id: 'salespeople',
    key: 'salespeople',
    isDefault: true,
    sortable: false,
    filterable: false,
  },
  {
    name: 'Client Total Sales',
    id: 'customer_total_sales',
    key: 'customer_total_sales',
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
    align: 'right',
    render: (row: ReportRow) => formatMoney(row.customer_total_sales),
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category-code')
      ? i18n.t('sku:stock-category-code')
      : 'Stock Category Code',
    id: 'sku_category_code',
    key: 'sku_category_code',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category') ? i18n.t('sku:stock-category') : 'Stock Category',
    id: 'sku_category',
    key: 'sku_category',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Item Type',
    id: 'item_type',
    key: 'item_type',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    key: 'note',
    id: 'note',
    render: (row: ReportRow) => row.note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Street',
    key: 'street',
    id: 'street',
    render: (row: any) => row.street,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Apt / Suite / Other',
    key: 'street_2',
    id: 'street_2',
    render: (row: any) => row.street_2,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'City',
    key: 'city',
    id: 'city',
    render: (row: any) => row.city,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'State',
    key: 'state',
    id: 'state',
    render: (row: any) => row.state,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'ZIP / Postal',
    key: 'postal',
    id: 'postal',
    render: (row: any) => row.postal,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Do Not Mail',
    key: 'do_not_mail',
    id: 'do_not_mail',
    render: (row: ReportRow) => (row.do_not_mail ? 'Yes' : ''),
    isDefault: false,
    sortable: true,
    filterable: true,
  },
];
