import { MyUser } from 'types/api/user';
import { IntercomFunction } from 'types/integrations';

const INTERCOM_API_BASE = 'https://api-iam.intercom.io';
const INTERCOM_APP_ID = 'gfpxlqzz';

export const captureUserData = (user: MyUser) => {
  if (window.Intercom) {
    window.Intercom(IntercomFunction.Boot, {
      api_base: INTERCOM_API_BASE,
      app_id: INTERCOM_APP_ID,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      user_id: user.uuid,
      company: {
        name: user.organization?.name,
        company_id: user.organization?.instanceID,
      },
    });
  }
};

export const handleIntercom = (fnKeys: IntercomFunction[]) => {
  if (!window.Intercom) return;

  fnKeys.forEach(key => {
    window.Intercom(key);
  });
};

export const initIntercom = () => {
  // conditionally load Intercom
  if (isIntercomEnabled()) {
    const script = document.createElement('script');
    script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/gfpxlqzz';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
    document.head.append(script);
  }
};

// conditional activation of intercom messenger
// can also be enabled by setting 'show_intercom_messenger' key in local storage
// we want to not have intercom show up when we are testing in cypress
const isIntercomEnabled = () => {
  const hasOverride = localStorage.getItem('show_intercom_messenger');

  if (hasOverride !== null) {
    return JSON.parse(hasOverride);
  }

  return process.env.INTERCOM_ENABLED;
};
