import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';
import { Theme } from 'styles/theme';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: Theme.colors.primary.base,
      light: Theme.colors.primary.base,
      dark: Theme.colors.primary.base,
    },
  },
  components: {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          fontSize: 24,
        },
        viewTransitionContainer: {},
      },
    },
  },
});

export default muiTheme;
