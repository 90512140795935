import { Link } from 'react-router-dom';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney, formatPercent } from 'utils/money';

export const COLLECTION_REPORT_FIELDS = [
  {
    name: 'Client #',
    id: 'client_record_id',
    key: 'client_record_id',
    type: 'LINK',
    filterable: true,
    render: (row: any) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row?.client_uuid}`}
      >
        {row.client_record_id}
      </Link>
    ),
    isDefault: true,
  },
  {
    name: 'Last Name',
    key: 'client_last_name',
    id: 'client_last_name',
    filterable: true,
    isDefault: true,
    sortable: true,
  },
  {
    name: 'First Name',
    key: 'client_first_name',
    id: 'client_first_name',
    filterable: true,
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Credit Plan Name',
    key: 'credit_plan_name',
    id: 'credit_plan_name',
    render: (row: any) => row.credit_plan_name,
    filterable: true,
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Interest Rate',
    key: 'credit_plan.interest_rate',
    id: 'credit_plan.interest_rate',
    render: (row: any) => (row.interest_rate ? formatPercent(row.interest_rate * 100) : ''),
    isDefault: true,
  },
  {
    name: 'On Account Amount',
    key: 'balance_original',
    id: 'balance_original',
    type: 'NUMBER',
    render: (row: any) => formatMoney(row.balance_original),
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Amount Collected',
    key: 'balance_paid',
    id: 'balance_paid',
    type: 'NUMBER',
    render: (row: any) => formatMoney(row.balance_paid),
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Total Due',
    key: 'balance',
    id: 'balance',
    type: 'NUMBER',
    render: (row: any) => formatMoney(row.balance),
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Amount Due',
    key: 'balance_due',
    id: 'balance_due',
    type: 'NUMBER',
    render: (row: any) => formatMoney(row.balance_due),
    isDefault: true,
  },
  {
    name: 'Last Payment Date',
    id: 'last_payment_date',
    key: 'last_payment_date',
    type: 'DATE',
    filterable: true,
    render: (row: any) => formatLocalDate(row.last_payment_date, 'l HH:mm:ss'),
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Last Payment Amount',
    key: 'last_payment_amount',
    id: 'last_payment_amount',
    type: 'NUMBER',
    render: (row: any) => (row.last_payment_amount !== null ? formatMoney(row.last_payment_amount) : ''),
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Days Since Last Payment',
    key: 'last_payment_days_since',
    id: 'last_payment_days_since',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
  },
  {
    name: '0 - 30',
    id: 'as_of_balance_0_to_30',
    key: 'as_of_balance_0_to_30',
    sortable: true,
    filterable: true,
    render: (row: any) => formatMoney(row.as_of_balance_0_to_30),
    isDefault: true,
  },
  {
    name: '31 - 60',
    id: 'as_of_balance_31_to_60',
    key: 'as_of_balance_31_to_60',
    sortable: true,
    filterable: true,
    render: (row: any) => formatMoney(row.as_of_balance_31_to_60),
    isDefault: true,
  },
  {
    name: '61 - 90',
    id: 'as_of_balance_61_to_90',
    key: 'as_of_balance_61_to_90',
    sortable: true,
    filterable: true,
    render: (row: any) => formatMoney(row.as_of_balance_61_to_90),
    isDefault: true,
  },
  {
    name: '91 - 120',
    id: 'as_of_balance_91_to_120',
    key: 'as_of_balance_91_to_120',
    sortable: true,
    filterable: true,
    render: (row: any) => formatMoney(row.as_of_balance_91_to_120),
    isDefault: true,
  },
  {
    name: '121+',
    id: 'as_of_balance_beyond',
    key: 'as_of_balance_beyond',
    sortable: true,
    filterable: true,
    render: (row: any) => formatMoney(row.as_of_balance_beyond),
    isDefault: true,
  },
];
