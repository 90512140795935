import Lozenge from '@atlaskit/lozenge';
import { css } from '@emotion/css';
import classNames from 'classnames';
import { Button } from 'components/core';
import { memo, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface ListViewProps {
  button?: ReactElement | JSX.Element | false | null;
  className?: string;
  content: ReactElement | JSX.Element | false;
  extra?: ReactElement | JSX.Element | false | null;
  hideBackground?: boolean;
  icon?: ReactElement | JSX.Element | false | null;
  onBack?: () => void;
  onBackBack?: () => void;
  parentUrl?: string;
  status?: any;
  title?: string;
}

const ListView = ({
  button,
  className,
  content,
  hideBackground,
  icon,
  onBack,
  onBackBack,
  extra,
  status,
  title,
  parentUrl,
}: ListViewProps) => {
  const contentClasses = classNames({
    'pane-content': true,
    'pane-content-background': !hideBackground,
  });

  // Wrapper component that will use react-router Link for routing
  // opposed to using the custom goBack function that does not preserve browser history
  const PreviousPageButton = ({ parentUrl, children }) =>
    parentUrl ? <Link to={parentUrl}>{children}</Link> : children;

  return (
    <div className={`list-view-container ${className ?? ''}`}>
      <div className='pane-header'>
        <div className='pane-header-title'>
          {onBackBack && (
            <Button className='icon-only back-btn' onClick={onBackBack} size='sm' variant='light'>
              <i
                className='fa-solid fa-chevron-double-left back-link'
                style={{
                  fontWeight: 500,
                  fontSize: 15,
                }}
              />
            </Button>
          )}
          {(onBack || parentUrl) && (
            <PreviousPageButton parentUrl={parentUrl}>
              <Button className='icon-only back-btn' onClick={onBack || (() => null)} size='sm' variant='light'>
                <i
                  className='fa-solid fa-chevron-left back-link'
                  style={{
                    fontWeight: 500,
                    fontSize: 15,
                  }}
                />
              </Button>
            </PreviousPageButton>
          )}
          <div
            style={{
              marginLeft: onBack ? 10 : 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h2 className='pane-title'>{title}</h2>
            {icon && <div className='list-view-status-container'>{icon}</div>}
            {status &&
              (status.component || (
                <div
                  className={css`
                    margin-left: 10px;
                    margin-top: -5px;
                    user-select: none;
                  `}
                >
                  <Lozenge
                    appearance={
                      status?.variant === 'danger' ? 'removed' : status?.variant === 'success' ? 'success' : undefined
                    }
                    isBold
                  >
                    {status.label}
                  </Lozenge>
                </div>
              ))}
          </div>
        </div>
        <div className='pane-actions'>{button}</div>
      </div>
      <div className={contentClasses}>{content}</div>
      {extra}
    </div>
  );
};

export default memo(ListView);
