import cx from 'clsx';
import { ReactElement, ReactFragment, ReactNode, useEffect, useRef, useState } from 'react';

export interface TAccordion {
  title: string;
  className?: string;
  children: ReactElement | ReactFragment | ReactNode | string | number;
  defaultActive?: boolean;
}

function Accordion({ title, className, children, defaultActive = false }: TAccordion) {
  const content = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (defaultActive && content.current) {
      setMaxHeight(content.current.scrollHeight);
    }
  }, [defaultActive]);

  function toggleAccordion() {
    if (maxHeight) {
      setMaxHeight(0);
    } else if (content.current) {
      setMaxHeight(content.current.scrollHeight);
    }
  }

  return (
    <div className={cx('accordion__section', className)}>
      {/* eslint-disable-next-line */}
      <button className='accordion' onClick={toggleAccordion}>
        <div className='accordion__title'>{title}</div>
        <i
          className={cx('accordion__icon fas fa-chevron-down', {
            rotate: !!maxHeight,
          })}
        />
      </button>
      <div ref={content} className='accordion__content' style={{ maxHeight }}>
        <div className='accordion__text'>{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
