import { keyBy } from 'lodash';

import { GET_LAYOUTS, RESET_ALL_DATA, SELECT_LAYOUT } from '../constants/action-types';

import { successReqActionType } from '../actions/helpers';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_LAYOUTS):
      return {
        ...state,
        ...keyBy(action.payload, 'id'),
      };
    case successReqActionType(SELECT_LAYOUT):
      const newLayouts = Object.values({ ...state });
      newLayouts.forEach(layout => {
        if (layout.type === action.payload.type) {
          layout.selected = false;
          if (layout.id === action.payload.id) {
            layout.selected = true;
          }
        }
      });
      return { ...state, ...keyBy(newLayouts, 'id') };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
