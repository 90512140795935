import { _apiRequest } from 'redux/actions/system';

import {
  ADD_ORG_TAXJAR_ACCOUNT,
  GET_ACTIVE_TAXJAR_ACCOUNT,
  GET_TAX_RATES_FOR_ZIP,
  REMOVE_ORG_TAXJAR_ACCOUNT,
} from 'redux/constants/action-types';

export const getTaxRatesForZip = postal => async dispatch => {
  const payload = {
    postalCode: postal,
  };

  _apiRequest({
    actionBase: GET_TAX_RATES_FOR_ZIP,
    payload,
    dispatch,
    method: 'Post',
    path: `taxjar/location-rates`,
  });
};

export const getActiveTaxjarAccount = () => async dispatch =>
  _apiRequest({
    actionBase: GET_ACTIVE_TAXJAR_ACCOUNT,
    dispatch,
    method: 'Get',
    path: `taxjar/account`,
  });

export const addOrganizationTaxJarAccount = taxJarAPIKey => async dispatch => {
  const payload = {
    apiKey: taxJarAPIKey,
  };

  _apiRequest({
    actionBase: ADD_ORG_TAXJAR_ACCOUNT,
    payload,
    dispatch,
    method: 'Put',
    path: `taxjar/account`,
  });
};

export const removeOrganizationTaxJarAccount = () => async dispatch => {
  _apiRequest({
    actionBase: REMOVE_ORG_TAXJAR_ACCOUNT,
    dispatch,
    method: 'Delete',
    path: `taxjar/account`,
  });
};
