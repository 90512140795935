import {
  ADD_CLIENT,
  ADD_CLIENT_ADDRESS,
  ADD_CLIENT_COLLECTION,
  ADD_CLIENT_EMAIL,
  ADD_CLIENT_PHONE,
  ADD_CLIENT_REFERRALS,
  ADD_CLIENT_RELATIONSHIPS,
  ADD_ON_ACCOUNT_STATEMENTS,
  ADD_STORE_CREDIT,
  ADD_WISHLIST_ITEM,
  APPLY_CLIENT_INTEREST_DUE,
  CREATE_CLIENT_REGISTRY,
  DEACTIVATE_CLIENT_REFERRALS,
  DEACTIVATE_CLIENT_RELATIONSHIPS,
  DELETE_CLIENT_MILESTONES,
  EDIT_CLIENT_ADDRESS,
  EDIT_CLIENT_COLLECTION,
  EDIT_CLIENT_EMAIL,
  EDIT_CLIENT_METADATA,
  EDIT_CLIENT_NAME,
  EDIT_CLIENT_PHONE,
  EDIT_CLIENT_PRIMARY_FIELD,
  GET_CLIENT,
  GET_CLIENTS_WITH_ACCOUNT,
  GET_CLIENT_COLLECTIONS,
  GET_CLIENT_HISTORICAL_STATEMENT,
  GET_CLIENT_HISTORY,
  GET_CLIENT_INTEREST_DUE,
  GET_CLIENT_MILESTONES,
  GET_CLIENT_ON_ACCOUNT_STATEMENTS,
  GET_CLIENT_PAYMENT,
  GET_CLIENT_REFERRALS,
  GET_CLIENT_REFUND,
  GET_CLIENT_REGISTRY,
  GET_CLIENT_RELATIONSHIPS,
  GET_CLIENT_REWARDS_HISTORY,
  GET_CLIENT_STATEMENT_HISTORY,
  GET_CLIENT_VISITS,
  GET_CLIENT_WISHLIST,
  GET_CREDIT_PLANS,
  GET_ITEM_HISTORY,
  GET_STORE_CREDITS,
  MERGE_CLIENTS,
  REMOVE_WISHLIST_ITEM,
  SEND_BULK_EMAIL,
  SEND_EMAIL,
  SET_CLIENT_MILESTONES,
  SET_CLIENT_PAGE_CLIENT_ID,
  SET_CLIENT_SEARCH_TYPE,
  SET_CLIENT_VISITS,
  SET_REGISTRY_ON_ORDER,
  UPDATE_CLIENT_MILESTONES,
  UPDATE_CLIENT_REGISTRY,
  UPDATE_CLIENT_VISITS,
  UPDATE_WISHLIST_ITEM,
} from 'redux/constants/action-types';

import { _apiRequest } from 'redux/actions/system';
import history from 'utils/history';
import { PrintType } from 'utils/print';
import * as Request from 'utils/request';
import { successReqActionType } from './helpers';

export const setClientSearchType = value => ({
  type: SET_CLIENT_SEARCH_TYPE,
  value,
});

export const getClient =
  (clientId, cb?: (data: any) => void, view = false) =>
  async dispatch =>
    _apiRequest({
      actionBase: GET_CLIENT,
      dispatch,
      method: 'Get',
      path: `client?id=${clientId}&view=${view}`,
      callback: data => {
        cb && cb(data);
      },
      noContentError: 'Client not found',
      unauthorizedError: 'You are not allowed to view this client',
    });

export const getClientAccounts =
  (date: string, clientId?: string | null, cb?: (data: any) => void) => async (dispatch: any) =>
    _apiRequest({
      actionBase: GET_CLIENTS_WITH_ACCOUNT,
      dispatch,
      method: 'Get',
      path: `client/accounts${clientId ? `?id=${clientId}` : ''}${
        date ? `${clientId ? `&date=${date}` : `date=${date}`}` : ''
      }`,
      callback: data => {
        cb && cb(data);
      },
      noContentError: 'No Client Accounts',
      unauthorizedError: 'You are not allowed to view this client account',
    });

export const createOnAccountStatements =
  (
    date: string,
    statementName?: string,
    clientIds?: string,
    notificationTitle?: string,
    notificationMessage?: string | null,
    cb?: (data: any) => void
  ) =>
  async (dispatch: any) => {
    const payload = {
      date,
      ids: clientIds,
      statement_name: statementName,
      print_type: PrintType.OnAccountStatements,
      notification_title: notificationTitle,
      notification_message: notificationMessage,
    };
    _apiRequest({
      actionBase: ADD_ON_ACCOUNT_STATEMENTS,
      dispatch,
      method: 'Post',
      path: 'client/on-account-statements',
      payload,
      callback: data => {
        cb && cb(data);
      },
    });
  };

export const getClientRewardsHistory = (clientID: string, cb?: (data: any) => void) => async (dispatch: any) =>
  _apiRequest({
    actionBase: GET_CLIENT_REWARDS_HISTORY,
    dispatch,
    method: 'Get',
    path: `client/rewards-history?clientId=${clientID}`,
    callback: data => {
      cb && cb(data);
    },
  });

export const getClientInterestDue = (clientID: string, cb?: (data: any) => void) => async (dispatch: any) =>
  _apiRequest({
    actionBase: GET_CLIENT_INTEREST_DUE,
    dispatch,
    method: 'Get',
    path: `client/interest-due?clientId=${clientID}`,
    callback: data => {
      cb && cb(data);
    },
  });

export const applyClientInterestDue = (clientID: string, cb?: (response?: any) => void) => async (dispatch: any) => {
  const response = await _apiRequest({
    actionBase: APPLY_CLIENT_INTEREST_DUE,
    dispatch,
    method: 'Post',
    path: `client/interest-due?clientId=${clientID}`,
  });
  cb && cb(response);
};

export const mergeClients = payload => async dispatch =>
  _apiRequest({
    actionBase: MERGE_CLIENTS,
    dispatch,
    method: 'Post',
    payload,
    path: `clients/merge`,
    metaDispatch: { uuid: payload.uuid },
  });

export const addClient = (payload, cb, onConflict) => async dispatch => {
  const response = await _apiRequest({
    actionBase: ADD_CLIENT,
    dispatch,
    method: 'Post',
    path: `client`,
    payload,
  });

  if (response.error) {
    console.error(response.error);
    cb && cb(response);
    return;
  }

  if (response.status === 200) {
    history.replace(`/clients/${response.data.uuid}`);
  } else if (response.status === 409) {
    onConflict && onConflict();
  }
};

export const addClientEmail = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_CLIENT_EMAIL,
    dispatch,
    method: 'Post',
    payload,
    path: `client/email`,
    notification: 'Client email added',
    metaDispatch: { uuid: payload.uuid },
  });

export const editClientEmail = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_CLIENT_EMAIL,
    dispatch,
    method: 'Put',
    payload,
    path: `client/email`,
    returnPayloadOnSuccess: true,
    notification: 'Client email updated',
  });

export const addClientPhone = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_CLIENT_PHONE,
    dispatch,
    method: 'Post',
    payload,
    path: `client/phone`,
    notification: 'Client phone added',
    metaDispatch: { uuid: payload.uuid },
  });

export const editClientPhone = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_CLIENT_PHONE,
    dispatch,
    method: 'Put',
    payload,
    path: `client/phone`,
    returnPayloadOnSuccess: true,
    notification: 'Client phone updated',
  });

export const sendEmail = payload => async dispatch =>
  _apiRequest({
    actionBase: SEND_EMAIL,
    dispatch,
    method: 'Post',
    payload,
    path: 'email',
    returnPayloadOnSuccess: true,
    onSuccess: () => {
      window.showSuccess('Sent successfully');
    },
  });

export const sendBulkEmail = payload => async dispatch =>
  _apiRequest({
    actionBase: SEND_BULK_EMAIL,
    dispatch,
    method: 'Post',
    payload,
    path: 'email/bulk',
    returnPayloadOnSuccess: true,
    onSuccess: () => {
      window.showSuccess('Sent successfully');
    },
  });

export const addClientAddress = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_CLIENT_ADDRESS,
    dispatch,
    method: 'Post',
    payload,
    path: `client/address`,
    notification: 'Client address added',
    metaDispatch: { uuid: payload.uuid },
  });

export const editClientAddress = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_CLIENT_ADDRESS,
    dispatch,
    method: 'Put',
    payload,
    path: `client/address`,
    returnPayloadOnSuccess: true,
    notification: 'Client address updated',
  });

export const getClientHistory = clientID => async dispatch =>
  _apiRequest({
    actionBase: GET_CLIENT_HISTORY,
    dispatch,
    method: 'Get',
    path: `client/orders?id=${clientID}`,
    metaDispatch: { clientID },
    unauthorizedError: "You are not allowed to view this client's sales history",
  });

export const getItemHistory = (clientID, status, canceled) => async dispatch =>
  _apiRequest({
    actionBase: GET_ITEM_HISTORY,
    dispatch,
    method: 'Get',
    path: `client/items?id=${clientID}&status=${status}`,
    metaDispatch: { clientID, canceled },
    unauthorizedError: "You are not allowed to view this client's sales history",
  });

export const getStoreCredits = clientID => async dispatch =>
  _apiRequest({
    actionBase: GET_STORE_CREDITS,
    dispatch,
    method: 'Get',
    path: `client/credits?id=${clientID}`,
    metaDispatch: { clientID },
    unauthorizedError: "You are not allowed to view this client's sales history",
  });

export const addStoreCredit = (clientID, payload) => async dispatch => {
  await _apiRequest({
    actionBase: ADD_STORE_CREDIT,
    dispatch,
    method: 'Post',
    payload,
    path: `client/credit`,
    metaDispatch: { clientID },
    notification: 'New store credit is added',
  }).then(() => {
    return dispatch(getClient(clientID));
  });
};

export const getClientRelationships = (clientID, customerId, isReferral) => async dispatch =>
  _apiRequest({
    actionBase: isReferral ? GET_CLIENT_REFERRALS : GET_CLIENT_RELATIONSHIPS,
    dispatch,
    method: 'Get',
    path: `client/relationships?id=${customerId}${isReferral ? '&referral=true' : ''}`,
    metaDispatch: { clientID },
    unauthorizedError: `You are not allowed to view this client's ${isReferral ? 'referrals' : 'relationships'}`,
  });

export const addClientRelationships = (clientID, customerId, relatedToIds, isReferral) => async dispatch =>
  _apiRequest({
    actionBase: isReferral ? ADD_CLIENT_REFERRALS : ADD_CLIENT_RELATIONSHIPS,
    dispatch,
    method: 'Post',
    payload: {
      customer_id: isReferral ? relatedToIds[0] : customerId,
      referral: !!isReferral,
      related_to: isReferral ? [customerId] : relatedToIds,
    },
    path: `client/relationship`,
    metaDispatch: { clientID },
    unauthorizedError: `You are not allowed to edit this client's ${isReferral ? 'referrals' : 'relationships'}`,
  }).then(() => {
    if (isReferral) {
      window.showSuccess('Saved!');
    } else {
      return dispatch(getClientRelationships(clientID, customerId, isReferral));
    }
  });

export const removeClientRelationship = (clientID, customerId, relatedToId, isReferral) => async dispatch => {
  const payload = isReferral ? { relationship_id: relatedToId } : { customer_id: customerId, related_to: relatedToId };

  return _apiRequest({
    actionBase: isReferral ? DEACTIVATE_CLIENT_REFERRALS : DEACTIVATE_CLIENT_RELATIONSHIPS,
    dispatch,
    method: 'Put',
    payload: {
      active: false,
      ...payload,
    },
    path: `client/relationship`,
    metaDispatch: { clientID },
    unauthorizedError: `You are not allowed to deactivate this client's ${isReferral ? 'referrals' : 'relationships'}`,
  })
    .then(() => {
      window.showSuccess('Success!');
      return dispatch(getClientRelationships(clientID, customerId, isReferral));
    })
    .catch(() => {
      window.showError('Permission denied');
    });
};

export const editClientMetadata = payload => async dispatch => {
  const response = await _apiRequest({
    actionBase: EDIT_CLIENT_METADATA,
    dispatch,
    method: 'Put',
    payload,
    path: `client`,
    returnPayloadOnSuccess: true,
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (response.status === 200) {
    window.showSuccess('Client updated');

    dispatch(getClient(payload.uuid));
  }
};

export const createClientRegistry = (uuid, payload) => async dispatch => {
  const response = await _apiRequest({
    actionBase: CREATE_CLIENT_REGISTRY,
    dispatch,
    method: 'Post',
    path: `client/registry`,
    payload,
    metaDispatch: { uuid },
  });

  dispatch({
    uuid,
    type: successReqActionType(GET_CLIENT_REGISTRY),
    payload: response.data,
  });
};

export const getClientRegistry = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_CLIENT_REGISTRY,
    dispatch,
    method: 'Get',
    path: `client/registry?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const getClientRegistryById = id => async dispatch =>
  _apiRequest({
    actionBase: SET_REGISTRY_ON_ORDER,
    dispatch,
    method: 'Get',
    path: `client/registry-by-id?id=${id}`,
  });

export const removeRegistry = () => ({
  type: successReqActionType(SET_REGISTRY_ON_ORDER),
  payload: null,
});

export const updateClientRegistry = (uuid, payload) => async dispatch => {
  const response = await _apiRequest({
    actionBase: UPDATE_CLIENT_REGISTRY,
    dispatch,
    method: 'Put',
    path: `client/registry`,
    payload,
    metaDispatch: { uuid },
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (response.status === 200) {
    dispatch(getClientRegistry(uuid));
  }
};

export const getClientWishlist = (id, uuid) => async dispatch =>
  _apiRequest({
    actionBase: GET_CLIENT_WISHLIST,
    dispatch,
    method: 'Get',
    path: `client/wishlist?id=${id}`,
    metaDispatch: { uuid },
  });

export const addWishlistItem = payload => async dispatch => {
  const response = await Request.Get(`product?id=${payload.wishlist.id}&basic=1`);

  if (response.error) {
    console.error(response.error);
    return;
  }

  await _apiRequest({
    actionBase: ADD_WISHLIST_ITEM,
    dispatch,
    method: 'Put',
    payload,
    path: `client`,
  });
};

export const updateWishlistItem = (payload, uuid) => async dispatch => {
  const response = await _apiRequest({
    actionBase: UPDATE_WISHLIST_ITEM,
    dispatch,
    method: 'Put',
    payload,
    path: `client/wishlist`,
    metaDispatch: {
      reference: { note: payload.note, owners: payload.owners },
      uuid,
    },
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  window.showSuccess('Saved');
};

export const removeWishlistItem = (payload, index, uuid) => async dispatch => {
  await _apiRequest({
    actionBase: REMOVE_WISHLIST_ITEM,
    dispatch,
    method: 'Put',
    payload,
    path: `client`,
    metaDispatch: { index, uuid },
  });
};

export const editClientName = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_CLIENT_NAME,
    dispatch,
    method: 'Put',
    payload,
    path: `client`,
    returnPayloadOnSuccess: true,
    notification: 'Client name updated',
  });

export const editClientPrimaryField = payload => ({
  type: EDIT_CLIENT_PRIMARY_FIELD,
  payload,
});

export const setClientPageClientId = payload => ({
  type: SET_CLIENT_PAGE_CLIENT_ID,
  payload,
});

export const getClientCollections = clientID => async dispatch =>
  _apiRequest({
    actionBase: GET_CLIENT_COLLECTIONS,
    dispatch,
    method: 'Get',
    path: `client/collections?id=${clientID}`,
    metaDispatch: { clientID },
    unauthorizedError: "You are not allowed to view this client's collections",
  });

export const addClientCollection = (payload, uuid) => async dispatch =>
  _apiRequest({
    actionBase: ADD_CLIENT_COLLECTION,
    dispatch,
    method: 'Post',
    payload,
    path: `client/collection`,
    metaDispatch: { uuid },
    onSuccess: () => {
      dispatch(getClient(uuid));
      dispatch(getClientCollections(uuid));
    },
  });

export const editClientCollection = (payload, uuid) => async dispatch =>
  _apiRequest({
    actionBase: EDIT_CLIENT_COLLECTION,
    dispatch,
    method: 'Put',
    payload,
    path: `client/collection`,
    metaDispatch: { uuid },
    onSuccess: () => {
      dispatch(getClient(uuid));
      dispatch(getClientCollections(uuid));
    },
  });

export const getClientPayment = (id, isCollection) => async dispatch =>
  _apiRequest({
    actionBase: GET_CLIENT_PAYMENT,
    dispatch,
    method: 'Get',
    path: `client/payment?id=${id}&collection=${isCollection}`,
    unauthorizedError: "You are not allowed to view this client's collections",
  });

// default to get ClientS > Client > Refund
export const getClientStoreData =
  (clientID, type = 7) =>
  async dispatch =>
    _apiRequest({
      actionBase: GET_CLIENT_REFUND,
      dispatch,
      method: 'Get',
      path: `orders?customerID=${clientID}&type=${type}`,
      metaDispatch: { clientID },
      unauthorizedError: "You are not allowed to view this client's sales history",
    });

export const getClientVisits = id => dispatch =>
  _apiRequest({
    actionBase: GET_CLIENT_VISITS,
    dispatch,
    method: 'Get',
    path: `client/visits?id=${id}`,
    returnPayloadOnSuccess: false,
  });

export const updateClientVisits = (payload, callback) => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_CLIENT_VISITS,
    dispatch,
    method: 'Put',
    path: `client/visit`,
    payload,
    returnPayloadOnSuccess: false,
    callback,
  });

export const setClientVisits = (payload, callback) => async dispatch =>
  _apiRequest({
    actionBase: SET_CLIENT_VISITS,
    dispatch,
    method: 'Post',
    path: `client/visit`,
    payload,
    returnPayloadOnSuccess: false,
    callback,
  });

export const getClientMilestones = id => async dispatch =>
  _apiRequest({
    actionBase: GET_CLIENT_MILESTONES,
    dispatch,
    method: 'Get',
    path: `client/milestones?id=${id}`,
  });

export const updateClientMilestones = (payload, callback) => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_CLIENT_MILESTONES,
    dispatch,
    method: 'Put',
    path: `client/milestone`,
    payload,
    callback,
  });

export const setClientMilestones = (payload, callback) => async dispatch =>
  _apiRequest({
    actionBase: SET_CLIENT_MILESTONES,
    dispatch,
    method: 'Post',
    path: `client/milestone`,
    payload,
    callback,
  });

export const deleteClientMilestones = (payload, callback) => async dispatch =>
  _apiRequest({
    actionBase: DELETE_CLIENT_MILESTONES,
    dispatch,
    method: 'Delete',
    path: `client/milestone`,
    payload,
    callback,
  });

export const getCreditPlans = () => async dispatch =>
  _apiRequest({
    actionBase: GET_CREDIT_PLANS,
    dispatch,
    method: 'Get',
    path: `credit-plans`,
  });

export const getClientStatementHistory = (clientId: number, clientUuid: string) => async (dispatch: any) =>
  _apiRequest({
    actionBase: GET_CLIENT_STATEMENT_HISTORY,
    dispatch,
    method: 'Get',
    path: `client/statement-history?id=${clientId}`,
    metaDispatch: { clientUuid },
    unauthorizedError: "You are not allowed to view this client's statement history",
  });

export const getClientHistoricalStatement = (statementUuid: string, clientUuid: string) => async (dispatch: any) =>
  _apiRequest({
    actionBase: GET_CLIENT_HISTORICAL_STATEMENT,
    dispatch,
    method: 'Get',
    path: `client/statement-history/${statementUuid}`,
    metaDispatch: { clientUuid },
    unauthorizedError: "You are not allowed to view this client's statement history",
  });

export const getOnAccountStatements = (statementUuid: string) => async (dispatch: any) =>
  _apiRequest({
    actionBase: GET_CLIENT_ON_ACCOUNT_STATEMENTS,
    dispatch,
    method: 'Get',
    path: `client/on-account-statements/${statementUuid}`,
  });
