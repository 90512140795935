import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MultiOrderPayload {
  details: any;
  payments: any[];
  startingOrder: any;
}

interface MultiOrderState {
  completed: boolean;
  details: any | null;
  orders: any[];
  payments: any[];
}

export const multiOrderSlice = createSlice({
  initialState: {
    completed: false,
    details: null,
    orders: [],
    payments: [],
  } as MultiOrderState,
  name: 'multiOrder',
  reducers: {
    setMultiOrder: (state, action: PayloadAction<MultiOrderPayload>) => ({
      completed: false,
      details: action.payload.details,
      orders: [action.payload.startingOrder],
      payments: action.payload.payments,
    }),
    addOrder: (state, action: PayloadAction<any>) => ({
      ...state,
      orders: state.orders.concat(action.payload),
    }),
    addStock: (state, action: PayloadAction<{ orderIndex: number; stock: any }>) => {
      const order = state.orders[action.payload.orderIndex];

      if (!order) {
        return state;
      }

      const stocks = [...(order.skus || [])];
      stocks.push(action.payload.stock);
      state.orders[action.payload.orderIndex].skus = stocks;

      return state;
    },
    addPayment: (state, action: PayloadAction<{ orderIndex: number; payment: any }>) => {
      const order = state.orders[action.payload.orderIndex];

      if (!order) {
        return state;
      }

      const payments = [...(order.payments || [])];
      payments.push(action.payload.payment);
      state.orders[action.payload.orderIndex].payments = payments;

      return state;
    },
    complete: (state, action: PayloadAction<string[]>) => ({
      ...state,
      orders: action.payload,
      completed: true,
    }),
    editStock: (state, action: PayloadAction<{ orderIndex: number; stock: any }>) => {
      const order = state.orders[action.payload.orderIndex];

      if (!order) {
        return state;
      }

      const stock = action.payload.stock;
      const itemIndex = order.skus?.findIndex(s => s.id === stock.id);

      if (itemIndex === -1) {
        return state;
      }

      order.skus[itemIndex] = stock;

      return state;
    },
    removeOrderAtIndex: (state, action: PayloadAction<number>) => ({
      ...state,
      orders: [...state.orders.filter((v, i) => i !== action.payload)],
    }),
    removeLinkedProduct: (state, action: PayloadAction<{ orderIndex: number }>) => {
      const order = state.orders[action.payload.orderIndex];

      if (!order) {
        return state;
      }

      const skus = order.skus;

      if (order.reference) {
        const linkedProductIndex = order.skus?.findIndex(s => s.isLinkedProduct);
        if (linkedProductIndex !== -1) {
          skus.splice(linkedProductIndex, 1);
        }
      }

      state.orders[action.payload.orderIndex] = {
        ...state.orders[action.payload.orderIndex],
        skus,
        reference: undefined,
        vendor: undefined,
      };

      return state;
    },
    removeStock: (state, action: PayloadAction<{ orderIndex: number; uuid: string }>) => {
      const order = state.orders[action.payload.orderIndex];

      if (!order) {
        return state;
      }

      const stocks = [...(order.skus || [])];
      const stockIndex = stocks.findIndex(s => s.uuid === action.payload.uuid);

      if (stockIndex === -1) {
        return state;
      }

      stocks.splice(stockIndex, 1);
      state.orders[action.payload.orderIndex].skus = stocks;

      return state;
    },
    reset: () => ({ completed: false, details: null, orders: [], payments: [] }),
    updateDetails: (state, action: PayloadAction<any>) => ({
      ...state,
      details: { ...action.payload },
    }),
    updateOrder: (state, action: PayloadAction<{ orderIndex: number; update: any }>) => {
      const orderIndex = action.payload.orderIndex;
      const order = state.orders[orderIndex];

      if (!order) {
        return state;
      }

      state.orders[orderIndex] = { ...state.orders[orderIndex], ...action.payload.update };

      return state;
    },
    updatePayments: (state, action: PayloadAction<any>) => ({
      ...state,
      payments: action.payload,
    }),
  },
});
