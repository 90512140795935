import { successReqActionType } from '../actions/helpers';
import { ADD_MEMO_ITEM, CREATE_MEMO, GET_MEMO, GET_MEMOS } from '../constants/action-types';

const initialState = {
  memos: [],
  completeMemos: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_MEMOS):
      return {
        ...state,
        memos: action.payload,
      };
    case successReqActionType(ADD_MEMO_ITEM):
      const NewItems = [...state.completeMemos[action.uuid].items, action.payload];
      return {
        ...state,
        completeMemos: {
          ...state.completeMemos,
          [action.uuid]: {
            ...state.completeMemos[action.uuid],
            items: NewItems,
          },
        },
      };
    case successReqActionType(CREATE_MEMO):
    case successReqActionType(GET_MEMO):
      return {
        ...state,
        completeMemos: {
          ...state.completeMemos,
          [action.payload.uuid]: {
            ...state.completeMemos[action.payload.uuid],
            ...action.payload,
          },
        },
      };
    default:
      return { ...state };
  }
};

export default reducer;
