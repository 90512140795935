import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const MERCH_ITEMS_REPORT_FIELDS = [
  {
    name: 'Date',
    id: 'merch_date',
    key: 'merch_date',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatDate(row.merch_date),
  },
  {
    name: 'Merch #',
    id: 'merch_id',
    key: 'merch_id',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/receives/${row.merch_uuid}`}
      >
        {row.merch_id}
      </Link>
    ),
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-number') ? i18n.t('stock-number') : 'Stock Number UI',
    id: 'sku',
    key: 'sku',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.sku_uuid}`}>
        {row.sku}
      </Link>
    ),
  },
  {
    name: 'Description',
    id: 'description',
    key: 'description',
    sortable: true,
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
  },
  {
    name: 'Vendor Name',
    id: 'vendor_name',
    key: 'vendor_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Vendor Code',
    id: 'vendor_code',
    key: 'vendor_code',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category') ? i18n.t('sku:stock-category') : 'Stock Category UI',
    id: 'sku_category',
    key: 'sku_category',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category-code')
      ? i18n.t('sku:stock-category-code')
      : 'Stock Category UI',
    id: 'sku_category_code',
    key: 'sku_category_code',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Memo',
    id: 'memo',
    key: 'memo',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Retail',
    id: 'retail',
    key: 'retail',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.retail),
  },
  {
    name: 'Online Price',
    id: 'online_price',
    key: 'online_price',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.online_price),
  },
  {
    name: 'Ext. Price',
    id: 'ext_price',
    key: 'ext_price',
    type: 'NUMBER',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.ext_price),
  },
  {
    name: 'Cost',
    id: 'cost',
    key: 'cost',
    type: 'NUMBER',
    sortable: true,
    filterable: true,
    isRestricted: true,
    render: (row: ReportRow) => formatMoney(row.cost),
  },
  {
    name: 'Ext. Cost',
    id: 'ext_cost',
    key: 'ext_cost',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.ext_cost),
  },
  {
    name: 'Invoice #',
    id: 'invoice_number',
    key: 'invoice_number',
    type: 'STRING',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    id: 'note',
    key: 'note',
    type: 'STRING',
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:on-hand-qty') ? i18n.t('sku:on-hand-qty') : 'On Hand Qty.',
    id: 'on_hand_qty',
    key: 'on_hand_qty',
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:serial-number') ? i18n.t('sku:serial-number') : 'Serial #',
    id: 'serial_number',
    key: 'serial_number',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'STRING',
  },
  {
    name: i18n.hasResourceBundle('en', 'product:upc') ? i18n.t('product:upc') : 'UPC',
    id: 'upc',
    key: 'upc',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'STRING',
  },
  {
    name: i18n.hasResourceBundle('en', 'product:cert-number') ? i18n.t('product:cert-number') : 'Cert #',
    id: 'cert_number',
    key: 'cert_number',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'STRING',
  },
  {
    name: i18n.hasResourceBundle('en', 'common:location') ? i18n.t('common:location') : 'Location',
    id: 'location_name',
    key: 'location_name',
    sortable: true,
    filterable: true,
  },
];
