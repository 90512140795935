import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';

interface AccountsReceivableItem {
  balance: number;
  balance_since_cutoff: number;
  balance_at_cutoff: number;
  customer_record_id: number;
  customer_uuid: string;
  customer_first_name: string;
  customer_last_name: string;
  layaway_balance: number;
  layaway_balance_since_cutoff: number;
  layaway_balance_at_cutoff: number;
}

export const ACCOUNTS_RECEIVABLE_BALANCE_REPORT_FIELDS = [
  {
    name: 'Client Name',
    id: 'client_name',
    key: 'client_name',
    filterable: true,
    render: (row: AccountsReceivableItem) =>
      i18n.hasResourceBundle('en', 'formats:full-name-format')
        ? i18n.t('formats:full-name-format', { firstName: row.customer_first_name, lastName: row.customer_last_name })
        : `${row.customer_first_name} ${row.customer_last_name}`,
  },
  {
    name: 'Client #',
    key: 'customer_uuid',
    id: 'customer_uuid',
    render: (row: AccountsReceivableItem) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row?.customer_uuid}`}
      >
        {row.customer_record_id}
      </Link>
    ),
    type: 'LINK',
  },
  {
    name: 'Current Balance',
    key: 'balance',
    id: 'balance',
    filterable: true,
    render: (row: AccountsReceivableItem) => formatMoney(row.balance),
    type: 'NUMBER',
  },
  {
    name: 'AR Since As Of',
    key: 'balance_since_cutoff',
    id: 'balance_since_cutoff',
    filterable: true,
    render: (row: AccountsReceivableItem) => formatMoney(row.balance_since_cutoff),
    type: 'NUMBER',
  },
  {
    name: 'AR At As Of',
    key: 'balance_at_cutoff',
    id: 'balance_at_cutoff',
    filterable: true,
    render: (row: AccountsReceivableItem) => formatMoney(row.balance_at_cutoff),
    type: 'NUMBER',
  },
  {
    name: 'Layaway Balance',
    key: 'layaway_balance',
    id: 'layaway_balance',
    filterable: true,
    render: (row: AccountsReceivableItem) => formatMoney(row.layaway_balance),
    type: 'NUMBER',
  },
  {
    name: 'Layaway Balance Since As Of',
    key: 'layaway_balance_since_cutoff',
    id: 'layaway_balance_since_cutoff',
    filterable: true,
    render: (row: AccountsReceivableItem) => formatMoney(row.layaway_balance_since_cutoff),
    type: 'NUMBER',
  },
  {
    name: 'Layaway Balance At As Of',
    key: 'layaway_balance_at_cutoff',
    id: 'layaway_balance_at_cutoff',
    filterable: true,
    render: (row: AccountsReceivableItem) => formatMoney(row.layaway_balance_at_cutoff),
    type: 'NUMBER',
  },
];
