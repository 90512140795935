import cx from 'clsx';
import { CSSProperties, ReactNode } from 'react';

export const ButtonSize = { Small: 'sm', Medium: 'md', Large: 'lg' } as const;

export type ButtonProps = ({ children?: JSX.Element | string } | { label?: string }) & {
  id?: string;
  block?: boolean;
  className?: string;
  disabled?: boolean;
  target?: string;
  htmltype?: string;
  href?: string;
  icon?: string | ReactNode | null;
  margin?: number;
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'danger' | 'link' | 'light' | 'dark' | 'success' | 'white';
  onClick?: (event: any) => void;
  children?: any | undefined;
  style?: CSSProperties;
};

const Button = ({
  className,
  children,
  type,
  block = false,
  href,
  disabled = false,
  variant = 'primary',
  icon,
  size = 'md',
  ...props
}: ButtonProps) => {
  const classNameText = cx(
    `btn btn-${variant} btn-${size}`,
    className,
    block && 'btn-block',
    disabled && 'btn-disabled'
  );
  return href ? (
    <a className={classNameText} href={href} {...props}>
      {icon || ''}
      {children}
    </a>
  ) : (
    // eslint does not like the way we are handling the type attribute here
    // error is: 'The button type attribute must be specified by a static string or a trivial ternary expression'
    // however, it is that, so I'm hiding this error
    /* eslint-disable-next-line */
    <button type={type ? type : 'button'} className={classNameText} disabled={disabled} {...props}>
      {icon || ''}
      {children}
    </button>
  );
};

export default Button;
