import moment from 'moment';

export const formatDate = (date, format = 'l') => {
  if (!date) return undefined;

  return moment(date).format(format);
};

export const formatLocalDate = (date, format = 'l') => {
  if (!date) return null;

  return moment(date).utc().format(format);
};

export const formatHours = hours => {
  if (!hours) return null;

  const components = String(hours).split('.');

  if (!components || !Array.isArray(components) || components.length !== 2) return null;

  const minutes = Math.trunc((components[1] / 100) * 60);
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${components[0] || ''}:${formattedMinutes}`.substring(0, 5);
};

export const filterWordingFormatter = (dateOption, timeFrame) => {
  switch (dateOption?.value) {
    case 'yesterday':
      return 'than the day prior';
    case 'today':
      return 'than yesterday';
    case 'this-week':
      return 'than last week';
    case 'last-week':
      return 'than the week prior';
    case 'month':
      return 'than last month';
    case 'last-month':
      return 'than the month prior';
    case 'this-year':
      return 'than the last year';
    case `qtd`:
      return 'than last quarter';
    case 'ytd':
      return 'year to date';
    case 'mtd':
      return 'month to date';
    case 'last-year':
      return 'than the year prior';
    case 'custom':
      return `${formatDate(timeFrame[0])} to ${formatDate(timeFrame[1])}`;
    default:
      return '';
  }
};

export const formatTimeRemaining = duration => {
  // Hours, minutes and seconds
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }

  ret += `${mins}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;
  return ret;
};
