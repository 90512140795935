export enum CurrencyCode {
  AED = 'AED',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum CountryCode {
  US = 'US',
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  CN = 'CN',
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  IE = 'IE',
  IN = 'IN',
  IT = 'IT',
  JP = 'JP',
  KR = 'KR',
  MX = 'MX',
  RU = 'RU',
}

const vatExcludedCountries = [CountryCode.US];

export const phoneCountries = [
  CountryCode.US,
  CountryCode.AU,
  CountryCode.BR,
  CountryCode.CA,
  CountryCode.CN,
  CountryCode.DE,
  CountryCode.ES,
  CountryCode.FR,
  CountryCode.GB,
  CountryCode.IE,
  CountryCode.IN,
  CountryCode.IT,
  CountryCode.JP,
  CountryCode.KR,
  CountryCode.MX,
  CountryCode.RU,
];

export const isVatEnabled = country => {
  return !vatExcludedCountries.includes(country);
};

export enum Locale {
  EN_US = 'en-us',
  EN_IE = 'en-ie',
}
