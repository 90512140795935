import { successReqActionType } from '../actions/helpers';
import { ADD_CASE, EDIT_CASE, GET_CASE, GET_CASES, RESET_ALL_DATA } from '../constants/action-types';

const initialState = {
  cases: [],
  currentCase: null,
};

const reducer = (state = initialState, action) => {
  const { cases } = state;
  switch (action.type) {
    case successReqActionType(GET_CASES):
      return {
        ...state,
        cases: action.payload,
      };
    case successReqActionType(GET_CASE):
      return {
        ...state,
        currentCase: action.payload,
      };
    case successReqActionType(EDIT_CASE):
      const newCases = [...cases];
      const newCaseIndex = newCases.findIndex(c => c.id === action.payload.id);
      if (action.payload && action.payload.active === false) {
        newCases.splice(newCaseIndex, 1);
      } else {
        newCases.splice(newCaseIndex, 1, action.payload);
      }
      return {
        ...state,
        cases: newCases,
      };
    case successReqActionType(ADD_CASE):
      const addNewCases = [...cases, action.payload];
      return {
        ...state,
        cases: addNewCases,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
