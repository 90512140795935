/* eslint-disable import/no-anonymous-default-export */
import { successReqActionType } from '../actions/helpers';
import {
  ADD_GIFT_CARD,
  RESET_ALL_DATA,
  RESET_SEARCH_RESULTS,
  SEARCH_CLIENTS,
  SEARCH_GIFT_CARDS,
  SEARCH_HIDDEN_CLIENTS,
  SEARCH_INVOICES,
  SEARCH_ORDERS,
  SEARCH_PRODUCTS,
  SEARCH_PURCHASE_ORDERS,
  SEARCH_REPAIRS,
  SEARCH_SKUS,
  SEARCH_VENDORS,
  SET_BRANDS_SEARCH,
  SET_CLIENT_SEARCH_FILTERS,
  SET_CLIENT_SEARCH_TYPE,
  SET_PRODUCT_SEARCH_FILTERS,
  SET_SEARCH_KEY,
  SET_SEARCH_PRODUCTS,
  SET_SKU_SEARCH_FILTERS,
  SET_TRADE_IN_SEARCH,
} from '../constants/action-types';

const initialState = {
  brandsSearch: {},
  collectionsSearch: {},
  vendors: [],
  products: [],
  clients: [],
  hiddenClients: [],
  clientSearch: {},
  productSearch: {},
  skus: [],
  skuSearch: {},
  orders: [],
  giftCards: [],
  invoices: [],
  purchaseOrders: [],
  tradeInSearch: {},
  clientSearchType: 1,
  key: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(SEARCH_VENDORS):
      return { ...state, vendors: action.payload };
    case successReqActionType(SEARCH_GIFT_CARDS):
      return { ...state, giftCards: action.payload };
    case successReqActionType(ADD_GIFT_CARD):
      return { ...state, giftCards: action.payload };
    case successReqActionType(SEARCH_ORDERS):
      return { ...state, orders: action.payload };
    case successReqActionType(SEARCH_CLIENTS):
      return { ...state, clients: action.payload };
    case successReqActionType(SEARCH_HIDDEN_CLIENTS):
      return { ...state, hiddenClients: action.payload };
    case successReqActionType(SEARCH_SKUS):
      return { ...state, skus: action.payload };
    case successReqActionType(SEARCH_PRODUCTS):
      return { ...state, products: action.payload };
    case successReqActionType(SEARCH_INVOICES):
      return { ...state, invoices: action.payload };
    case successReqActionType(SEARCH_PURCHASE_ORDERS):
      return { ...state, purchaseOrders: action.payload };
    case successReqActionType(SEARCH_REPAIRS):
      return { ...state, orders: action.payload };
    case SET_CLIENT_SEARCH_FILTERS:
      return { ...state, clientSearch: action.payload };
    case SET_PRODUCT_SEARCH_FILTERS:
      return { ...state, productSearch: action.payload };
    case SET_SKU_SEARCH_FILTERS:
      return { ...state, skuSearch: action.payload };
    case SET_SEARCH_PRODUCTS:
      return { ...state, products: action.payload };
    case RESET_SEARCH_RESULTS:
      return {
        ...state,
        [action.searchType]: [],
        [action.searchFilter || '']: {},
      };
    case SET_CLIENT_SEARCH_TYPE:
      return { ...state, clientSearchType: action.value };
    case SET_SEARCH_KEY:
      return { ...state, key: action.value };
    case SET_TRADE_IN_SEARCH:
      return { ...state, tradeInSearch: action.payload };
    case SET_BRANDS_SEARCH:
      return { ...state, brandsSearch: action.payload };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
