import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import {
  ADD_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_ALL,
  RESET_ALL_DATA,
} from '../constants/action-types';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_PAYMENT_METHODS):
      return { ...state, ...keyBy(action.payload, 'id') };
    case successReqActionType(GET_PAYMENT_METHODS_ALL):
      return { ...state, paymentMethodsAll: action.payload };
    case successReqActionType(EDIT_PAYMENT_METHOD):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };
    case successReqActionType(GET_PAYMENT_METHOD):
    case successReqActionType(ADD_PAYMENT_METHOD):
      return { ...state, [action.payload.id]: action.payload };
    case RESET_ALL_DATA:
      return {};
    default:
      return state;
  }
};

export default reducer;
