import {
  apiBodyMapper,
  ApiResponseTransform,
  apiResponseTransform,
  apiUrl,
  baseApiV1,
  HttpMethod,
} from 'services/base-api-v1';
import { JewelersMutualQuote } from 'types/api/jewelers-mutual';

enum JewelersMutualPath {
  Root = 'jm',
  GetQuote = 'quote',
  CreateSale = 'sale/create',
  GetOrderCarePlans = 'order/{orderUUID}/care-plans',
}

export interface JewelersMutualGetQuoteRequest {}

export interface JewelersMutualCreateSaleRequest {}

export interface JewelersMutualGetOrderCarePlansRequest {}

export interface JewelersMutualGetQuoteResponse {
  crystal_sku: string;
  quotes: JewelersMutualQuote[];
}

export interface JewelersMutualGetQuoteRequestResponse {
  responses: JewelersMutualGetQuoteResponse[];
}

export interface JewelersMutualCreateSaleResponse {}

export interface JewelersMutualGetOrderCarePlansRequestResponse {
  jm_order_care_plans: any;
}

const jmPath = apiUrl(JewelersMutualPath.Root);

const postJewelersMutualGetQuoteQuery = (body: JewelersMutualGetQuoteRequest) => ({
  url: jmPath(JewelersMutualPath.GetQuote),
  method: HttpMethod.Post,
  body: apiBodyMapper(body),
});

const postJewelersMutualCreateSaleMutation = (body: JewelersMutualCreateSaleRequest) => ({
  url: jmPath(JewelersMutualPath.CreateSale),
  method: HttpMethod.Post,
  body: apiBodyMapper(body),
});

const getJewelersMutualOrderCarePlansQuery = (orderId: any) => ({
  url: jmPath(JewelersMutualPath.GetOrderCarePlans.replace('{orderUUID}', orderId)),
  method: HttpMethod.Get,
});

export const jewelersMutualApi = baseApiV1.injectEndpoints({
  endpoints: build => ({
    postJewelersMutualGetQuoteQuery: build.mutation<JewelersMutualGetQuoteRequestResponse, any>({
      query: postJewelersMutualGetQuoteQuery,
      transformResponse: apiResponseTransform as ApiResponseTransform<JewelersMutualGetQuoteRequestResponse>,
    }),
    postJewelersMutualCreateSaleMutation: build.mutation<JewelersMutualCreateSaleResponse, any>({
      query: postJewelersMutualCreateSaleMutation,
      transformResponse: apiResponseTransform as ApiResponseTransform<JewelersMutualCreateSaleResponse>,
    }),
    getJewelersMutualOrderCarePlansQuery: build.mutation<JewelersMutualGetOrderCarePlansRequestResponse, any>({
      query: getJewelersMutualOrderCarePlansQuery,
      transformResponse: apiResponseTransform as ApiResponseTransform<JewelersMutualGetOrderCarePlansRequestResponse>,
    }),
  }),
});

export const {
  usePostJewelersMutualGetQuoteQueryMutation,
  usePostJewelersMutualCreateSaleMutationMutation,
  useGetJewelersMutualOrderCarePlansQueryMutation,
} = jewelersMutualApi;
