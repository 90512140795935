import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { defaultDiamondSearchFilters } from 'components/BrowseInventoryRefresh/data/diamondSearchOptions';
import {
  AttributeValue,
  CheckMap,
  DiamondSearchFilters,
  ProductAttribute,
  ProductType,
  SearchType,
} from 'components/BrowseInventoryRefresh/data/types';
import { cloneDeep, keyBy } from 'lodash';
import { _apiRequest } from '../actions';
import {
  BROWSE_PRODUCTS,
  GET_ATTRIBUTES,
  GET_PRODUCT_TYPES,
  GET_VENDORS,
  SEARCH_VENDORS,
} from '../constants/action-types';

export interface ProductsRequest {
  type: number;
  attributes?: AttributeValue[];
  location_ids?: number[];
  category_ids?: number[];
  minPrice?: string | number | null;
  maxPrice?: string | number | null;
  stock?: number;
  product_type: number;
  shape?: number[];
  carat?: number[];
  cut?: number[];
  color?: number[];
  fancy_color?: number[];
  saturation?: number[];
  clarity?: number[];
  polish?: number[];
  symmetry?: number[];
  fluorescence?: number[];
  cert?: number[];
  e_commerce: number;
  preowned: number;
}

export interface BrowseInventoryState {
  filters: AttributeValue[];
  checkMap: CheckMap;
  vendors: any;
  productTypes: { [key: string]: ProductType };
  productAttributes: ProductAttribute[];
  productAttributesMap: any;
  selectedVendorId: number | null;
  selectedLocationId: any[];
  categoryIds: any[];
  products: any[];
  hasSearched: boolean;
  stock: number;
  minPrice: string | number | null;
  maxPrice: string | number | null;
  searchType: SearchType;
  sort: any;
  pagination: any;
  listViewEnabled: boolean;
  diamondSearchFilters: DiamondSearchFilters;
  productsLoading: 'idle' | 'pending';
  productsCurrentRequestId?: string;
  productsError: SerializedError | null;
  eCommerce: number;
  preowned: number;
}

const initialState: BrowseInventoryState = {
  checkMap: {},
  diamondSearchFilters: defaultDiamondSearchFilters,
  filters: [],
  hasSearched: false,
  minPrice: null,
  maxPrice: null,
  productAttributes: [],
  productAttributesMap: {},
  products: [],
  productTypes: {},
  searchType: 0,
  selectedLocationId: [],
  categoryIds: [],
  selectedVendorId: null,
  sort: '',
  pagination: {},
  stock: 1,
  vendors: {},
  listViewEnabled: false,
  productsLoading: 'idle',
  productsCurrentRequestId: undefined,
  productsError: null,
  eCommerce: 0,
  preowned: 0,
};

export const fetchProducts = createAsyncThunk(
  'browseInventory/fetchProducts',
  async (payload: ProductsRequest, { dispatch }) => {
    const response = await _apiRequest({
      actionBase: BROWSE_PRODUCTS,
      dispatch,
      method: 'Post',
      payload,
      path: `products`,
      unauthorizedError: 'You are not allowed to view these items',
    });
    return response.data;
  }
);

const browseInventorySlice = createSlice({
  name: 'browseInventory',
  initialState,
  reducers: {
    setVendors: (state, action) => {
      const vendors = keyBy(action.payload, 'id');
      state.vendors = vendors;
    },
    setProductTypes: (state, action) => {
      const productTypes = keyBy(action.payload, 'id');
      state.productTypes = productTypes;
    },
    setAttributes: (state, action) => {
      const { productAttributes, vendorId } = action.payload;
      state.productAttributes = productAttributes;
      state.selectedVendorId = vendorId;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocationId = action.payload;
    },
    setECommerceFilter: (state, action) => {
      state.eCommerce = action.payload;
    },
    setCategoryIds: (state, action) => {
      state.categoryIds = action.payload;
    },
    setBrowseInventoryFilters: (state, action) => {
      const { filters, checkMap } = action.payload;
      state.filters = filters;
      state.checkMap = checkMap;
    },
    setDiamondSearchFilters: (state, action) => {
      const filters = cloneDeep(action.payload.filters);
      state.diamondSearchFilters = filters;
    },
    setListViewEnabled: (state, action: PayloadAction<boolean>) => {
      state.listViewEnabled = action.payload;
    },
    setBrowseInventorySort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    updateBrowseInventoryFilters: (state, action: PayloadAction<Partial<BrowseInventoryState>>) => {
      const newState: BrowseInventoryState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    resetBrowseInventoryFilters: () => initialState,
    resetAllData: () => initialState,
    setTableInfo: (state, action: PayloadAction<{ page: string; key: string; data: any }>) => {
      const { page, key, data } = action.payload;
      const newState = {
        ...state,
        [key]: {
          ...state[key],
          [page]: data,
        },
      };
      return newState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.pending, (state, { meta }) => {
        if (state.productsLoading === 'idle') {
          state.productsLoading = 'pending';
          state.productsCurrentRequestId = meta.requestId;
        }
      })
      .addCase(fetchProducts.fulfilled, (state, { payload, meta }) => {
        const { requestId } = meta;
        if (state.productsLoading === 'pending' && state.productsCurrentRequestId === requestId) {
          state.productsLoading = 'idle';
          state.products = payload;
          state.productsCurrentRequestId = undefined;
          state.hasSearched = true;
        }
      })
      .addCase(fetchProducts.rejected, (state, { error, meta }) => {
        const { requestId } = meta;
        if (state.productsLoading === 'pending' && state.productsCurrentRequestId === requestId) {
          state.productsLoading = 'idle';
          state.products = [];
          state.productsError = error;
          state.productsCurrentRequestId = undefined;
          state.hasSearched = true;
        }
      });
  },
});

const { actions, reducer } = browseInventorySlice;

export const {
  setVendors,
  setProductTypes,
  setAttributes,
  setSelectedLocation,
  setCategoryIds,
  setBrowseInventoryFilters,
  setListViewEnabled,
  setBrowseInventorySort,
  setDiamondSearchFilters,
  setTableInfo,
  updateBrowseInventoryFilters,
  resetBrowseInventoryFilters,
  resetAllData,
} = actions;

export default reducer;

export const fetchProductTypes = () => async dispatch => {
  const response = await _apiRequest({
    actionBase: GET_PRODUCT_TYPES,
    dispatch,
    method: 'Get',
    path: `types`,
  });
  dispatch(setProductTypes(response.data));
};

export const searchVendors = text => async dispatch => {
  const response = await _apiRequest({
    actionBase: SEARCH_VENDORS,
    dispatch,
    method: 'Post',
    path: 'vendor/search',
    payload: { text },
  });
  dispatch(setVendors(response.data));
};

export const fetchProductAttributes = (vendorId, general) => async dispatch => {
  const response = await _apiRequest({
    actionBase: GET_ATTRIBUTES,
    dispatch,
    method: 'Get',
    path: `product/attributes?type=${vendorId}&general=${general}`,
    metaDispatch: { vendorId },
  });
  dispatch(setAttributes({ productAttributes: response.data, vendorId }));
};

export const fetchVendors = searchable => async dispatch => {
  const response = await _apiRequest({
    actionBase: GET_VENDORS,
    dispatch,
    method: 'Get',
    path: `vendors?searchable=${searchable || 0}`,
  });
  dispatch(setVendors(response.data));
};
