import { combineReducers } from '@reduxjs/toolkit';
import taxJarReducer from './taxJarReducer';

// bundle reducers for various integrations here
// don't forget to update the AppState type in types/redux-tyoes with your new reducer as well
const reducer = combineReducers({
  taxJar: taxJarReducer,
});

export default reducer;
