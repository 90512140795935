import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const ON_ACCOUNT_REPORT_FIELDS = [
  {
    name: 'Client #',
    key: 'customer_id',
    id: 'customer_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_id}
      </Link>
    ),
  },
  {
    name: 'First Name',
    key: 'first_name',
    id: 'first_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last Name',
    key: 'last_name',
    id: 'last_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Store Credit',
    key: 'store_credit',
    id: 'store_credit',
    render: (row: ReportRow) => formatMoney(row.store_credit || 0),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'On Account Amount',
    key: 'total_amount',
    id: 'total_amount',
    render: (row: ReportRow) => formatMoney(row.total_amount || 0),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Amount Collected',
    key: 'amount_collected',
    id: 'amount_collected',
    render: (row: ReportRow) => formatMoney(row.amount_collected || 0),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Amount Due',
    key: 'amount_due',
    id: 'amount_due',
    render: (row: ReportRow) => formatMoney(row.amount_due || 0),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
];
