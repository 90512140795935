import { FullStory, init, isInitialized } from '@fullstory/browser';
import { MyUser } from 'types/api/user';

export const initFullStory = () => {
  if (!process.env.FULLSTORY_ENABLED) {
    return;
  }

  init({ orgId: 'o-20MGEP-na1' });
};

export const identifyUser = ({ firstName, lastName, email, organization, role, uuid }: MyUser) => {
  if (!process.env.FULLSTORY_ENABLED || !FullStory || !isInitialized()) {
    return;
  }

  FullStory('setIdentity', {
    uid: uuid,
    properties: {
      email,
      displayName: `${firstName} ${lastName}`,
      organization: organization?.name,
      organizationFullName: organization?.full_name,
      role: role?.name,
      version: process.env.VERSION,
    },
  });
};
