import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import { ADD_GIFT_CARD, RESET_ALL_DATA, SET_GIFT_CARD } from '../constants/action-types';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GIFT_CARD:
      return { ...state, gift: action.payload };
    case successReqActionType(ADD_GIFT_CARD):
      const giftCards = action.payload;
      return {
        ...keyBy(giftCards, 'id'),
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
