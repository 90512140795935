import { _apiRequest } from 'redux/actions/system';
import {
  ADD_SKU,
  ADD_SKU_ADJUSTMENT,
  ADD_SKU_COMPONENT,
  EDIT_DIAMOND_ATTRIBUTES,
  EDIT_SKU,
  GET_DIAMOND_ATTRIBUTES,
  GET_GENERAL_SKUS,
  GET_SKU,
  GET_SKU_COMPONENTS,
  GET_SKU_SALES,
  GET_SKU_SCANS,
  GET_SKU_STOCK,
  GET_SKU_TRANSACTIONS,
  UPDATE_INVOICE_SKU,
  UPDATE_SKU_COMPONENT,
  UPDATE_SKU_SCAN,
} from 'redux/constants/action-types';
import history from 'utils/history';

export const addSku = payload => async dispatch => {
  const response = await _apiRequest({
    actionBase: ADD_SKU,
    dispatch,
    method: 'Post',
    path: `sku`,
    payload,
  });

  if (response.data) {
    history.push(`/skus/${response.data.uuid}`);
  }
};

export const editSku = (payload, cb?: () => void) => async dispatch => {
  await _apiRequest({
    actionBase: EDIT_SKU,
    dispatch,
    method: 'Put',
    path: 'sku',
    returnPayloadOnSuccess: true,
    payload,
    callback: () => {
      window.showSuccess('Stock # updated');
      cb && cb();
    },
  });
};

export const getSku = (id, cb?: (data: any) => void) => async dispatch => {
  _apiRequest({
    actionBase: GET_SKU,
    dispatch,
    method: 'Get',
    path: `sku?id=${id}`,
    callback: data => {
      cb && cb(data);
    },
  });
};

export const getGeneralSkus = () => async dispatch =>
  _apiRequest({
    actionBase: GET_GENERAL_SKUS,
    dispatch,
    method: 'Get',
    path: `skus?general=1`,
  });

export const getSkuTransactions = id => async dispatch =>
  _apiRequest({
    actionBase: GET_SKU_TRANSACTIONS,
    dispatch,
    method: 'Get',
    path: `sku/transactions?id=${id}`,
  });

export const getSkuScans = (uuid, id) => async dispatch =>
  _apiRequest({
    actionBase: GET_SKU_SCANS,
    dispatch,
    method: 'Get',
    path: `sku/scans?id=${uuid}&inventoryId=${id || 0}`,
    metaDispatch: { uuid },
  });

export const getSkuComponents = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_SKU_COMPONENTS,
    dispatch,
    method: 'Get',
    path: `sku/components?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const updateSkuScan = (payload, cb?: () => void) => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_SKU_SCAN,
    dispatch,
    method: 'Put',
    path: `inventory/scan`,
    payload,
    callback: () => {
      cb && cb();
    },
    returnPayloadOnSuccess: true,
  });

export const getSkuStock = (id, uuid) => async dispatch =>
  _apiRequest({
    actionBase: GET_SKU_STOCK,
    dispatch,
    method: 'Get',
    path: `item/stock?id=${id}`,
    metaDispatch: { uuid },
  });

export const getSkuSales = id => async dispatch =>
  _apiRequest({
    actionBase: GET_SKU_SALES,
    dispatch,
    method: 'Get',
    path: `sku/sales?id=${id}`,
  });

export const getDiamondAttributes = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_DIAMOND_ATTRIBUTES,
    dispatch,
    method: 'Get',
    path: `product/diamond?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const editDiamondAttributes = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_DIAMOND_ATTRIBUTES,
    dispatch,
    method: 'Put',
    path: `product/diamond`,
    payload,
    metaDispatch: { uuid: payload.uuid },
    returnPayloadOnSuccess: true,
  });

export const addSkuComponent = (payload, uuid) => async dispatch => {
  await _apiRequest({
    actionBase: ADD_SKU_COMPONENT,
    dispatch,
    method: 'Post',
    payload,
    path: 'sku/component',
    notification: 'SKU component added',
    metaDispatch: { uuid },
    handleStatus: status => {
      switch (status) {
        case 417:
          window.showNotification(
            'error',
            'Quantity Not Available',
            `The quantity you requested for this Stock # is not available at this location. Please transfer the item in order to complete the request.`
          );

          break;
        default:
          break;
      }
    },
  });
  dispatch(getSku(uuid, undefined));
  dispatch(getSkuComponents(uuid));
};

export const updateSkuComponent = (payload, uuid) => async dispatch => {
  await _apiRequest({
    actionBase: UPDATE_SKU_COMPONENT,
    dispatch,
    method: 'Put',
    payload,
    path: 'sku/component',
    metaDispatch: { uuid },
  });

  dispatch(getSku(uuid, undefined));
  dispatch(getSkuComponents(uuid));
};

export const addSkuAdjustment = (payload, uuid) => async dispatch => {
  const { sku_id } = payload;

  return _apiRequest({
    actionBase: ADD_SKU_ADJUSTMENT,
    dispatch,
    method: 'Post',
    path: `sku/adjustment`,
    payload,
    onSuccess: () => {
      dispatch(getSkuTransactions(uuid));
      dispatch(getSkuStock(sku_id, uuid));
    },
  });
};

export const updateInvoiceSku = payload => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_INVOICE_SKU,
    dispatch,
    method: 'Put',
    payload,
    path: 'invoice/sku',
  });
