import { Link } from 'react-router-dom';
import { formatDate, formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const GIFT_CARDS_REPORT_FIELDS = [
  {
    name: 'Card Number',
    id: 'card_number',
    key: 'card_number',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/gift-cards/${row.card_uuid}`}
      >
        {row.card_number}
      </Link>
    ),
  },
  {
    name: 'Card Type',
    id: 'card_type',
    key: 'card_type',
    sortable: true,
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Amount',
    id: 'amount',
    key: 'amount',
    render: (row: ReportRow) => formatMoney(row.amount || 0),
    sortable: true,
    filterable: true,
    isDefault: true,
    type: 'NUMBER',
  },
  {
    name: 'Redeemed',
    id: 'redeemed',
    key: 'redeemed',
    render: (row: ReportRow) => formatMoney(row.redeemed || 0),
    sortable: true,
    filterable: true,
    isDefault: true,
    type: 'NUMBER',
  },
  {
    name: 'Balance',
    id: 'balance',
    key: 'balance',
    render: (row: ReportRow) => formatMoney(row.balance || 0),
    sortable: true,
    filterable: true,
    isDefault: true,
    type: 'NUMBER',
  },
  {
    name: 'Expiration Date',
    id: 'expiration_date',
    key: 'expiration_date',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatLocalDate(row.expiration_date),
  },
  {
    name: 'Created On',
    id: 'created',
    key: 'created',
    type: 'DATE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatDate(row.created),
  },
];
