import { Component, MouseEvent, ReactNode } from 'react';
import { getTabsCount } from '../tab-helpers/count';
import UncontrolledTabs, { UncontrolledTabsProps } from './UncontrolledTabs';

const MODE_CONTROLLED = 0;
const MODE_UNCONTROLLED = 1;

interface TElement {
  type?: { tabsRole: string };
  props: { children: ReactNode[] };
}

interface TabsProps {
  onSelect?: (index: number, last?: number, event?: MouseEvent) => boolean | void;
  focus?: boolean;
  children?: TElement[];
  direction?: 'rtl' | 'ltr';
  className?: string | string[];
  defaultFocus?: boolean;
  tabsRole?: string;
  defaultIndex?: number;
  disabledTabClassName?: string;
  disableUpDownKeys?: boolean;
  domRef?: () => void;
  forceRenderTabPanel?: boolean;
  selectedIndex?: number | string | null;
  selectedTabClassName?: string;
  selectedTabPanelClassName?: string;
  environment?: object;
  props?: any;
}

interface TabsState {
  selectedIndex?: number;
  mode?: number;
  focus?: boolean;
}

export default class Tabs extends Component<TabsProps, TabsState> {
  /* eslint-disable-next-line */
  static defaultProps = {
    defaultFocus: false,
    forceRenderTabPanel: false,
    selectedIndex: null,
    defaultIndex: null,
    environment: null,
    disableUpDownKeys: false,
  };

  constructor(props: TabsProps) {
    super(props);

    this.state = Tabs.copyPropsToState(this.props, {}, props.defaultFocus);
  }

  static getDerivedStateFromProps(props: TabsProps, state: TabsState) {
    return Tabs.copyPropsToState(props, state);
  }

  static getModeFromProps(props: TabsProps) {
    return props.selectedIndex === null ? MODE_UNCONTROLLED : MODE_CONTROLLED;
  }

  handleSelected = (index: number, last?: number, event?: MouseEvent) => {
    const { onSelect } = this.props;
    const { mode } = this.state;

    // Call change event handler
    if (typeof onSelect === 'function') {
      // Check if the change event handler cancels the tab change
      if (onSelect(index, last, event) === false) return;
    }

    const state: TabsState = {
      // Set focus if the change was triggered from the keyboard
      focus: event && event.type === 'keydown',
    };

    if (mode === MODE_UNCONTROLLED) {
      // Update selected index
      state.selectedIndex = index;
    }

    this.setState(state);
  };

  // preserve the existing selectedIndex from state.
  // If the state has not selectedIndex, default to the defaultIndex or 0
  static copyPropsToState(props: TabsProps, state: TabsState, focus = false) {
    if (
      process.env.NODE_ENV !== 'production' &&
      state.mode !== undefined &&
      state.mode !== Tabs.getModeFromProps(props)
    ) {
      throw new Error(
        `Switching between controlled mode (by using \`selectedIndex\`) and uncontrolled mode is not supported in \`Tabs\`.
For more information about controlled and uncontrolled mode of react-tabs see the README.`
      );
    }

    const newState: TabsState = {
      focus,
      mode: Tabs.getModeFromProps(props),
    };

    if (newState.mode === MODE_UNCONTROLLED) {
      const maxTabIndex = getTabsCount(props.children as TElement[]) - 1;
      let selectedIndex = null;

      if (state.selectedIndex != null) {
        /* eslint-disable-next-line */
        // @ts-ignore
        selectedIndex = Math.min(state.selectedIndex, maxTabIndex);
      } else {
        /* eslint-disable-next-line */
        // @ts-ignore
        selectedIndex = props.defaultIndex || 0;
      }
      /* eslint-disable-next-line */
      // @ts-ignore
      newState.selectedIndex = selectedIndex;
    }

    return newState;
  }

  render() {
    const { children, defaultIndex, defaultFocus, ...props } = this.props;
    const { focus, selectedIndex } = this.state;

    props.focus = focus;
    props.onSelect = this.handleSelected;

    if (selectedIndex != null) {
      props.selectedIndex = selectedIndex;
    }
    /* eslint-disable-next-line */
    // @ts-ignore
    return <UncontrolledTabs {...(props as unknown as UncontrolledTabsProps)}>{children}</UncontrolledTabs>;
  }
}

type TTabs = typeof Tabs & { tabsRole: string };

(Tabs as TTabs).tabsRole = 'Tabs';
