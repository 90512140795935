import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import {
  ADD_TRANSFER,
  ADD_TRANSFER_ITEM,
  EDIT_TRANSFER,
  GET_TRANSFER,
  GET_TRANSFERS,
  RESET_ALL_DATA,
  SEARCH_TRANSFERS,
  SET_TRANSFER_ITEMS,
} from '../constants/action-types';

const initialState = {
  items: [],
  transfers: {},
  currentTransfer: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_TRANSFER):
      return {
        ...state,
        currentTransfer: action.payload,
      };
    case successReqActionType(GET_TRANSFERS):
      return {
        ...state,
        transfers: keyBy(action.payload, 'id'),
      };
    case successReqActionType(SEARCH_TRANSFERS):
      return { ...state, transfers: keyBy(action.payload, 'id') };
    case successReqActionType(ADD_TRANSFER_ITEM):
      const ATIItems = [...state.currentTransfer.items, action.payload];
      return {
        ...state,
        currentTransfer: { ...state.currentTransfer, items: ATIItems },
      };
    case successReqActionType(EDIT_TRANSFER):
      return {
        ...state,
        currentTransfer: { ...state.currentTransfer, ...action.payload },
      };
    case successReqActionType(ADD_TRANSFER):
      return { ...state, items: [] };
    case SET_TRANSFER_ITEMS:
      return { ...state, items: action.payload };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
