import { flatMap, property } from 'lodash';
import { Moment } from 'moment';
import { AccountingBucket } from 'types/api/accounting-bucket';
import { AccountingMonth } from 'types/api/reports';
import { _apiRequest } from '.';

export interface UpdateReceiptBucketAction {
  accounting_bucket_id: number | string;
  orderItemID: number;
}

export interface UpdateAccountingDateAction {
  key: string;
  date: Moment;
}

/* Action Types */
export const ADD_ACCOUNTING_BUCKET = 'ADD_ACCOUNTING_BUCKET';
export const EDIT_ACCOUNTING_BUCKET = 'EDIT_ACCOUNTING_BUCKET';
export const GET_ACCOUNTING_BUCKETS = 'GET_ACCOUNTING_BUCKETS';

export const GET_ACCOUNTING_MONTH = 'GET_ACCOUNTING_MONTH';
export const GET_ACCOUNTING_MONTHS = 'GET_ACCOUNTING_MONTHS';
export const EDIT_ACCOUNTING_MONTH = 'EDIT_ACCOUNTING_MONTH';

export const EDIT_DAILY_RECEIPT_BUCKET = 'EDIT_DAILY_RECEIPT_BUCKET';
export const GET_DAILY_RECEIPTS = 'GET_DAILY_RECEIPTS';
export const UPDATE_DAILY_RECEIPT = 'UPDATE_DAILY_RECEIPT';

export const GET_END_OF_DAY_SALES = 'GET_END_OF_DAY_SALES';
export const GET_END_OF_DAY_PAYMENTS = 'GET_END_OF_DAY_PAYMENTS';

export const ADD_HEADCOUNT = 'ADD_HEADCOUNT';
export const DELETE_HEADCOUNT = 'DELETE_HEADCOUNT';
export const GET_HEADCOUNT = 'GET_HEADCOUNT';
export const UPDATE_HEADCOUNT = 'UPDATE_HEADCOUNT';

export const UPDATE_ACCOUNTING_DATE = 'UPDATE_ACCOUNTING_DATE';

export const GET_DAILY_SUMMARY = 'GET_DAILY_SUMMARY';
export const GET_DAILY_SUMMARY_TIEOUT = 'GET_DAILY_SUMMARY_TIEOUT';

export const GET_CLOSE_REGISTER = 'GET_CLOSE_REGISTER';
export const POST_CLOSE_REGISTER = 'POST_CLOSE_REGISTER';
export const PUT_CLOSE_REGISTER = 'PUT_CLOSE_REGISTER';

export const GET_ACCOUNTING_PREFERENCES = 'GET_ACCOUNTING_PREFERENCES';
export const UPDATE_ACCOUNTING_PREFERENCE = 'UPDATE_ACCOUNTING_PREFERENCE';

export const CREATE_OR_UPDATE_ACCOUNTING_PAYOUT = 'CREATE_OR_UPDATE_ACCOUNTING_PAYOUT';

/* Actions */
export const getAccountingBuckets = () => async dispatch =>
  _apiRequest({
    actionBase: GET_ACCOUNTING_BUCKETS,
    dispatch,
    method: 'Get',
    path: `accounting/accounting-buckets`,
  });

export const addAccountingBucket = ab => async dispatch =>
  _apiRequest({
    actionBase: ADD_ACCOUNTING_BUCKET,
    dispatch,
    method: 'Post',
    payload: ab,
    path: `accounting/accounting-buckets`,
    onFailure: response => {
      if (response.status === 409) {
        window.showError('Item already exists');
      }
    },
  });

export const editAccountingBucket = (payload: AccountingBucket) => async dispatch =>
  _apiRequest({
    actionBase: EDIT_ACCOUNTING_BUCKET,
    dispatch,
    method: 'Put',
    path: `accounting/accounting-buckets/${payload?.id}`,
    payload,
    returnPayloadOnSuccess: true,
    onSuccess: () => {
      window.showSuccess('Saved');

      // Parent types may result in line items being updated, refetch items to ensure they are updated if so
      if (!payload.parent_name) {
        dispatch(getAccountingBuckets());
      }
    },
    onFailure: response => {
      if (response.status === 409) {
        window.showError('Item already exists');
      }
    },
  });

export const editAccountingBucketSortOrder = (payload: AccountingBucket[]) => async dispatch => {
  await Promise.all(
    payload.map(bucket =>
      _apiRequest({
        actionBase: EDIT_ACCOUNTING_BUCKET,
        dispatch,
        method: 'Put',
        path: `accounting/accounting-buckets/${bucket?.id}`,
        payload: bucket,
        onFailure: response => {
          if (response.status === 409) {
            window.showError('Item already exists');
          }
        },
      })
    )
  ).then(responses => {
    const errorArray = responses.filter(response => response.data !== 'OK');
    if (errorArray.length === 0) {
      window.showSuccess('Saved');
      dispatch(getAccountingBuckets());
    } else {
      window.showError('There was an error');
    }
  });
};

export const getAccountingMonths = (locationID: number) => async dispatch =>
  _apiRequest({
    actionBase: GET_ACCOUNTING_MONTHS,
    dispatch,
    method: 'Get',
    path: `accounting/accounting-months?location=${locationID}`,
  });

export const getAccountingMonth = (payload: { month: number; year: number; locationID: number }) => async dispatch =>
  _apiRequest({
    actionBase: GET_ACCOUNTING_MONTH,
    dispatch,
    method: 'Get',
    path: `accounting/accounting-months?month=${payload?.month}&year=${payload?.year}&location=${payload.locationID}`,
  });

export const editAccountingMonth =
  (payload: Partial<AccountingMonth>, callback?: (response: any) => any) => async dispatch =>
    _apiRequest({
      actionBase: EDIT_ACCOUNTING_MONTH,
      dispatch,
      method: payload.id ? 'Put' : 'Post',
      path: payload.id ? `accounting/accounting-months/${payload.id}` : `accounting/accounting-months`,
      payload,
      callback,
    });

export const getDailyReceipts = payload => async dispatch =>
  _apiRequest({
    actionBase: GET_DAILY_RECEIPTS,
    dispatch,
    method: 'Get',
    path: `accounting/daily-receipts?day=${payload.day}&location_id=${payload.locationId}`,
  });

export const getDailySummaryTieout = payload => async dispatch =>
  _apiRequest({
    actionBase: GET_DAILY_SUMMARY_TIEOUT,
    dispatch,
    method: 'Get',
    path: `accounting/daily-summary/tieout?day=${payload.day}&location_id=${payload.locationId}`,
  });

export const getEndOfDaySales = payload => async dispatch =>
  _apiRequest({
    actionBase: GET_END_OF_DAY_SALES,
    dispatch,
    method: 'Get',
    path: `accounting/end-of-day/sales?day=${payload.day}`,
  });

export const getEndOfDayPayments = payload => async dispatch =>
  _apiRequest({
    actionBase: GET_END_OF_DAY_PAYMENTS,
    dispatch,
    method: 'Get',
    path: `accounting/end-of-day/payments?day=${payload.day}`,
  });

export const getHeadcountByLocation =
  ({ date, locationId }, callback?: () => void) =>
  async dispatch =>
    _apiRequest({
      actionBase: GET_HEADCOUNT,
      dispatch,
      method: 'Get',
      path: `headcount?date=${date}&location_id=${locationId}`,
      callback,
    });

export const addOrUpdateHeadcount = (payload, callback?: () => void) => async dispatch =>
  _apiRequest({
    actionBase: payload.id ? UPDATE_HEADCOUNT : ADD_HEADCOUNT,
    dispatch,
    method: payload.id ? 'Put' : 'Post',
    path: payload.id ? `headcount/${payload.id}` : `headcount`,
    payload,
    callback,
  });

export const deleteHeadcount = (id: string) => async dispatch =>
  _apiRequest({
    actionBase: DELETE_HEADCOUNT,
    dispatch,
    method: 'Delete',
    path: `headcount/${id}`,
  });

export const updateDailyReceiptBucket = (payload: UpdateReceiptBucketAction) => async dispatch => {
  dispatch({ type: UPDATE_DAILY_RECEIPT, payload });
  return dispatch(editDailyReceiptBucket(payload));
};

export const editDailyReceiptBucket = (payload: UpdateReceiptBucketAction) => async dispatch =>
  _apiRequest({
    actionBase: EDIT_DAILY_RECEIPT_BUCKET,
    dispatch,
    method: 'Put',
    path: `order/item`,
    payload,
    returnPayloadOnSuccess: true,
  });

export const updateAccountingDate = (payload: UpdateAccountingDateAction) => async dispatch => {
  dispatch({ type: UPDATE_ACCOUNTING_DATE, payload });
};

export const getDailySummary = () => async (dispatch, ownState) => {
  const { locationIds, monthSelected } = ownState().reports.accountingSummary;
  const locations = flatMap(locationIds, property('value'));

  await _apiRequest({
    actionBase: GET_DAILY_SUMMARY,
    dispatch,
    method: 'Get',
    path: `accounting/daily-summary?location_id=${locations[0]}&month=${
      monthSelected.month() + 1
    }&year=${monthSelected.year()}`,
    metaDispatch: { key: 'accountingSummary' },
  });
};

export const getCloseRegister = payload => async dispatch =>
  _apiRequest({
    actionBase: GET_CLOSE_REGISTER,
    dispatch,
    method: 'Get',
    path: `accounting/daily-register?date=${payload.date}&location_id=${payload.id}`,
  });

export const postCloseRegister = payload => async dispatch =>
  _apiRequest({
    actionBase: POST_CLOSE_REGISTER,
    dispatch,
    method: 'Post',
    path: 'accounting/daily-register',
    payload,
    callback: () => {
      dispatch(getCloseRegister({ date: payload.date, id: payload.location_id }));
    },
  });

export const putCloseRegister = payload => async dispatch =>
  _apiRequest({
    actionBase: PUT_CLOSE_REGISTER,
    dispatch,
    method: 'Put',
    path: 'accounting/daily-register',
    payload,
    callback: () => {
      dispatch(getCloseRegister({ date: payload.date, id: payload.location_id }));
    },
  });

export const getAccountingPreferences = () => async dispatch =>
  _apiRequest({
    actionBase: GET_ACCOUNTING_PREFERENCES,
    dispatch,
    method: 'Get',
    path: 'accounting/preferences',
  });

export const updateAccountingPreference = payload => async dispatch => {
  if (payload.id === undefined) {
    return;
  }

  return _apiRequest({
    actionBase: UPDATE_ACCOUNTING_PREFERENCE,
    dispatch,
    method: 'Put',
    path: `accounting/preferences/${payload.id}`,
    payload,
  });
};
