import { createTheme } from '@mui/material/styles';

export type ThemeColorMode = 'light' | 'dark' | 'auto';

const primaryColor = '#575bdc';

export const Theme = {
  colors: {
    primary: {
      base: primaryColor,
      hover: '#494bc5',
      light: '#f2f0fe',
      active: '#17149d',
    },
    success: '#53c28c',
    danger: '#f44336',
    dangerHover: '#c62828',
    neutral: {
      base: '#f1f2f4',
      text: '#1b2848',
      hover: '#dddfe4',
      active: '#b4b9c4',
      disabled: '#f9f9f9',
      disabledText: '#888888',
    },
  },
  button: {
    borderRadius: '0.25rem',
    size: {
      small: '2rem',
      medium: '2.5rem',
      large: '3rem',
    },
  },
  font: {
    size: {
      extraSmall: '0.77rem',
      small: '0.83rem',
      medium: '0.9rem',
      large: '1rem',
    },
  },
  switch: {
    offColor: '#bbbbbb',
    onColor: primaryColor,
  },
};

const muiTheme = createTheme({
  palette: {
    primary: {
      main: Theme.colors.primary.base,
    },
    success: {
      main: Theme.colors.success,
    },
    error: {
      main: Theme.colors.danger,
    },
  },
});

export default muiTheme;
