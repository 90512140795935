import { useAppDispatch } from 'app/hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { clearJmPlanSkus, getProduct } from 'redux/actions';
import { usePostJewelersMutualCreateSaleMutationMutation } from 'services/jewelers-mutual-api';
import { InvoiceItem } from 'types/api/invoice';
import { JewelersMutualProductCodes } from 'types/api/jewelers-mutual';
import { AppState } from 'types/redux-types';

export const JmStrings = {
  JmNonStockSkuDescription: 'JM® CARE PLAN',
  PlanNotSelected: 'None',
  RequestSuccess: 'success',
  RequestError: 'error',
  SaleIncomplete: 'SALE NOT COMPLETED',
  JmIntegrationName: 'jewelers-mutual',
} as const;

export const RadioSelectIndexes = {
  KeySelector: 'planSku',
  Selector: 'description',
} as const;

export const getOrderHasCarePlan = (orderItems: any[]) => {
  return orderItems?.find(orderItem => orderItem.description.includes(JmStrings.JmNonStockSkuDescription));
};

export const getJmCarePlanNonstockSku = nonstockSkus => {
  return nonstockSkus?.find(nonstockSku => nonstockSku.description === JmStrings.JmNonStockSkuDescription);
};

export const isJewelersMutualEnabled = (integrations: string[]) => {
  return integrations?.includes(JmStrings.JmIntegrationName);
};

export const useGetJmProductType = () => {
  const dispatch = useAppDispatch();

  const { jmProductMappings } = useSelector((state: AppState) => ({
    jmProductMappings: state.root.jmProductMappings,
  }));

  const getJmProductCode = (sku: InvoiceItem): Promise<string> => {
    const skuProductUUID = sku.productUUID;
    return new Promise((resolve, reject) => {
      dispatch(
        getProduct(skuProductUUID, productData => {
          const associatedProductCode = productData.category.code;
          const jmProductCode = jmProductMappings.find(
            jmProdMap => jmProdMap.sku_category_code === associatedProductCode
          )?.jm_product_code;
          if (jmProductCode !== undefined) {
            resolve(jmProductCode);
            return;
          }
          reject();
        })
      );
    });
  };

  const getJmProductType = (jmProductCode: string) => {
    switch (jmProductCode) {
      case JewelersMutualProductCodes.Earrings:
      case JewelersMutualProductCodes.Necklace:
      case JewelersMutualProductCodes.Bracelet:
      case JewelersMutualProductCodes.Ring:
      case JewelersMutualProductCodes.BroachOrPin:
      case JewelersMutualProductCodes.JewelrySets:
      case JewelersMutualProductCodes.Chain:
      case JewelersMutualProductCodes.Pendant:
      case JewelersMutualProductCodes.CharmOrBead:
      case JewelersMutualProductCodes.Strands:
      case JewelersMutualProductCodes.JewelryMisc:
        return 'JEWELRY';
      case JewelersMutualProductCodes.NewWatch:
      case JewelersMutualProductCodes.PreOwnedWatch:
      case JewelersMutualProductCodes.WatchMisc:
        return 'WATCHES';
      default:
        return 'NO MATCHING PRODUCT CODE';
    }
  };

  return { getJmProductCode, getJmProductType };
};

export const useCreateJmSale = () => {
  const dispatch = useAppDispatch();
  const [createJmSale] = usePostJewelersMutualCreateSaleMutationMutation();

  // load necessary info in from state:
  const state = useSelector((state: AppState) => ({
    order: state.newOrder.sale,
    clientForm: state.newOrder.clientForm,
    locations: state.root.organization.locations,
    selectedJmPlans: state.newOrder.jm,
    customers: state.clients,
    jmStoreCodes: state.root.jmStoreCodes,
  }));
  // destructure state
  const { order, clientForm, locations, selectedJmPlans, customers, jmStoreCodes } = state;

  const { getJmProductCode } = useGetJmProductType();

  const buildCreateCarePlanPayloads = async (): Promise<any[]> => {
    // get location tax rate:
    const locationId = clientForm.location.value;
    const currentLocation = locations.find(location => location.id === locationId);
    const currentLocationTaxRate = currentLocation?.taxRate;

    // get JmStoreCode for current location
    const jmStoreCodeString = jmStoreCodes?.find(
      jmStoreCode => jmStoreCode.location === currentLocation?.uuid
    )?.jm_store_code;

    if (selectedJmPlans.length > 0 && currentLocationTaxRate && order) {
      // fetch order assoc customer
      const customer = Object.values(customers).find(c => c?.id === order?.customer?.id);

      // customer contact methods (optional):
      // fetch contact methods
      const customerPrimaryAddress =
        customer?.addresses.length === 1 ? customer.addresses[0] : customer?.addresses.find(a => a.primary === true);
      const customerPrimaryPhone =
        customer?.phones.length === 1 ? customer.phones[0] : customer?.phones.find(p => p.primary === true);
      const customerPrimaryEmail =
        customer?.emails.length === 1 ? customer.emails[0] : customer?.emails.find(e => e.primary === true);

      return Promise.all(
        selectedJmPlans.map(async selectedJmPlan => {
          // build transaction ID
          const transactionId = `${order.recordID.split('-')[2]}-${selectedJmPlan.itemSku}`;

          // load associated order item by sku
          const associatedOrderItem: any = order?.items?.find(
            (orderItem: any) => orderItem.sku === selectedJmPlan.itemSku
          );

          const jmProductCode = await getJmProductCode(associatedOrderItem);

          // construct payload
          const jmPayload = {
            sale_transaction_date: order.date,
            customer: {
              first_name: customer?.firstName ?? '',
              last_name: customer?.lastName ?? '',
              address: {
                street: customerPrimaryAddress?.street ?? '',
                city: customerPrimaryAddress?.city ?? '',
                state: customerPrimaryAddress?.state ?? '',
                zip: customerPrimaryAddress?.postal ?? '',
              },
              email: customerPrimaryEmail?.email ?? '',
              phone_number: customerPrimaryPhone?.phone ?? '',
            },
            transaction_details: {
              // Unique transaction ID for each Jm Care Plan is build here
              // composed of order UUID + the sku of the item targeted by the plan
              jm_transaction_id: transactionId,
              item_description: associatedOrderItem?.description,
              store_id: jmStoreCodeString,
              product_price: associatedOrderItem?.subtotal,
              sales_tax: associatedOrderItem?.tax,
              plan_sku: selectedJmPlan.planSku,
              item_sku: selectedJmPlan.itemSku,
              plan_retail_price: selectedJmPlan.planRetail,
              plan_sales_tax: Math.ceil(selectedJmPlan.planRetail * currentLocationTaxRate * 100) / 100,
              product_code: jmProductCode,
              // DEV - TODO: indicator and brand are optional fields, consider adding them
              // indicator refers to "if an item was purchased from the jeweler or only appraised by them prior to coverage"
              // I do not believe we keep track of this information ... ?
              // Brand could be looked up through vendor code I think, but I'll hve to put that off for later
              // will need to either fetch order item or modify the fetch order call to include vendorId
              indicator: '',
              brand: '',
            },
          };

          const crystalPayload = {
            created_date: order.date,
            sku_id: selectedJmPlan.itemSku,
            plan_retail_price: selectedJmPlan.planRetail,
            order_uuid: order.uuid,
            jm_transaction_id: transactionId,
            jm_store_id: jmStoreCodeString,
            jm_plan_sku: selectedJmPlan.planSku,
          };

          const finalPayload = {
            jm_payload: jmPayload,
            crystal_payload: crystalPayload,
          };
          return finalPayload;
        })
      );
    }
    return [];
  };

  const [createCarePlansStatus, setCreateCarePlansStatus] = useState('');

  async function CreateJmCarePlans() {
    const carePlanPayloads = await buildCreateCarePlanPayloads();
    for (let i = 0; i < carePlanPayloads.length; i++) {
      const payload = carePlanPayloads[i];
      // eslint-disable-next-line
      const response = await createJmSale(payload);
      if (response && 'data' in response) {
        // good response case
        setCreateCarePlansStatus(JmStrings.RequestSuccess);
      } else {
        // unexpected error case
        setCreateCarePlansStatus(JmStrings.RequestError);
      }
    }
    dispatch(clearJmPlanSkus());
  }

  return { CreateJmCarePlans, createCarePlansStatus, setCreateCarePlansStatus };
};
