import {
  ADD_TRANSFER,
  ADD_TRANSFER_ITEM,
  EDIT_TRANSFER,
  GET_TRANSFER,
  GET_TRANSFERS,
  SET_TRANSFER_ITEMS,
} from 'redux/constants/action-types';

import { _apiRequest } from 'redux/actions/system';
import history from 'utils/history';

import { successReqActionType } from './helpers';

export const addTransfer = (payload, cb) => async dispatch =>
  _apiRequest({
    actionBase: ADD_TRANSFER,
    dispatch,
    method: 'Post',
    path: `transfer`,
    payload,
    callback: data => {
      cb && cb(data);

      const { uuid } = data;
      if (!uuid) return;

      history.replace(`/transfers/${uuid}`);
    },
  });

export const editTransfer = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_TRANSFER,
    dispatch,
    method: 'Put',
    path: `transfer`,
    payload,
    returnPayloadOnSuccess: true,
    onSuccess: () => {
      window.showSuccess('Saved');
    },
  });

export const getTransfer = (id, cb?) => async dispatch =>
  _apiRequest({
    actionBase: GET_TRANSFER,
    dispatch,
    method: 'Get',
    path: `transfer?id=${id}`,
    callback: data => {
      cb && cb(data);
    },
  });

export const resetTransfer = () => ({
  type: successReqActionType(GET_TRANSFER),
  payload: null,
});

export const getTransfers = status => async dispatch =>
  _apiRequest({
    actionBase: GET_TRANSFERS,
    dispatch,
    method: 'Get',
    path: `transfers?status=${status}`,
  });

export const resetTransfers = () => ({
  type: successReqActionType(GET_TRANSFERS),
  payload: {},
});

export const setTransferItems = payload => ({
  type: SET_TRANSFER_ITEMS,
  payload,
});

export const addTransferItems = (transferId, items) => async dispatch => {
  await Promise.all(
    items.map(item => {
      const { cost, description, id, nonstock, quantity, sku } = item;
      return _apiRequest({
        actionBase: ADD_TRANSFER_ITEM,
        dispatch,
        method: 'Post',
        path: `transfer/item`,
        payload: {
          cost,
          description,
          transferID: transferId,
          id,
          nonstock,
          quantity,
          sku,
        },
        metaDispatch: { transferId },
        handleStatus: status => {
          switch (status) {
            case 417:
              window.showNotification(
                'error',
                'Quantity Not Available',
                `The quantity you requested for this Stock # (${item.sku}) is not available at the source location. It has not been added to the transfer.`
              );
              break;
            default:
              break;
          }
        },
      });
    })
  );
};
