import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const DAILY_PAYMENTS_REPORT_FIELDS = [
  {
    name: 'Payment Type',
    key: 'payment_type',
    id: 'payment_type',
    sortable: true,
  },
  {
    name: 'Transaction #',
    key: 'transaction_number',
    id: 'transaction_number',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transactions/${row.order_uuid}`}
      >
        {row.transaction_number}
      </Link>
    ),
    sortable: true,
    filterable: true,
    type: 'LINK',
  },
  {
    name: 'Salesperson',
    key: 'salesperson',
    id: 'salesperson',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Status',
    key: 'status',
    id: 'status',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Client #',
    key: 'customer_number',
    id: 'customer_number',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_number}
      </Link>
    ),
    sortable: true,
    filterable: true,
    type: 'LINK',
  },
  {
    name: 'Client Name',
    key: 'customer_name',
    id: 'customer_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Amount',
    key: 'amount',
    id: 'amount',
    sortable: true,
    filterable: true,
    isCurrency: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.amount),
  },
];
