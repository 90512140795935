import cx from 'clsx';
import { CSSProperties } from 'react';

export interface SpinnerProps {
  className?: string;
  variant?: string;
  type?: string;
  size?: string;
  props?: any;
  style?: CSSProperties;
}

const Spinner = ({ className, variant = 'primary', type = 'border', size = 'md', ...props }: SpinnerProps) => (
  <div className={cx(`spinner-border-${size} text-${variant} spinner-${type}`, className)} {...props} />
);

export default Spinner;
