import {
  ADD_LOCATION,
  ADD_TAX_CODE,
  AMQP_SERVER_CONNECTED,
  API_SERVER_CONNECTED,
  CLOSE_MODAL,
  EDIT_LOCATION,
  EDIT_TAX_CODE,
  GET_CHANGE_LOG,
  GET_LOCATIONS,
  GET_SETTINGS,
  GET_TAX_CODES,
  GET_VIEW_HISTORY_LOG,
  HIDE_MENU,
  NEEDS_UPDATE,
  OPEN_MODAL,
  REQUEST_ABORT,
  REQUEST_END,
  REQUEST_START,
  RESET_ALL_DATA,
  RESET_CHANGE_LOG,
  RESET_TABLE_PAGE,
  RESET_VIEW_HISTORY_LOG,
  SET_DASHBOARD_FILTERS,
  SET_DASHBOARD_TAB,
  SET_TABLE_INFO,
  SET_USER,
  SUBMIT_FEEDBACK,
  TOGGLE_MENU,
  UPDATE_SETTING,
} from 'redux/constants/action-types';
// used for _apiRequest
import * as Request from 'utils/request';
import {
  failReqActionType,
  noContentReqActionType,
  notAuthorizedReqActionType,
  startReqActionType,
  successReqActionType,
} from './helpers';

// main API request method
export const _apiRequest = async (options: {
  dispatch: any;
  actionBase: any;
  payload?: any;
  method: 'Get' | 'Post' | 'Put' | 'Patch' | 'Delete';
  path: string;
  returnPayloadOnSuccess?: boolean;
  callback?: (response?: any) => any;
  rawBody?: any;
  noContentError?: string;
  unauthorizedError?: string;
  handleStatus?: (status: any) => any;
  onSuccess?: () => any;
  onFailure?: (response: any) => any;
  headers?: Headers | any;
  metaDispatch?: any;
  notification?: string;
}) => {
  const {
    dispatch,
    actionBase,
    payload,
    method,
    path,
    returnPayloadOnSuccess,
    callback,
    rawBody,
    noContentError,
    unauthorizedError,
    handleStatus,
    onSuccess,
    onFailure,
    headers = {},
  } = options;
  dispatch({ type: startReqActionType(actionBase) });

  const abortController = new AbortController();

  dispatch(requestStart(actionBase, abortController));

  const response = await Request[method](
    path,
    method === 'Get' ? null : payload,
    { rawBody, signal: abortController.signal },
    headers
  );

  dispatch(requestEnd(actionBase));

  if (!response) {
    dispatch({ type: failReqActionType(actionBase) });
    return Promise.reject(new Error(`Did not receive a valid response ${method.toUpperCase()} /${path}`)).catch(err => {
      console.error(err);
    });
  }

  const { status } = response;

  if (!status) {
    dispatch({ type: failReqActionType(actionBase) });
    return Promise.reject(new Error(`Did not receive a valid status code ${method.toUpperCase()} /${path}`)).catch(
      err => {
        console.error(err);
      }
    );
  }

  if (response.error) {
    dispatch({ type: failReqActionType(actionBase) });
    return Promise.reject(new Error(`Did not receive a valid response ${method.toUpperCase()} /${path}`)).catch(err => {
      console.error(err);
    });
  }

  switch (status) {
    case 200:
      dispatch({
        type: successReqActionType(actionBase),
        payload: response.data ? (returnPayloadOnSuccess ? payload : response.data) : null,
        ...options.metaDispatch,
      });

      if (response.updateAvailable) {
        dispatch(needsUpdate(true));
      }

      onSuccess && onSuccess();
      callback && callback(response.data || null);
      break;
    case 204:
      // Handle a no content error
      dispatch({
        type: noContentReqActionType(actionBase),
        payload: response.error,
      });
      noContentError && window.showError(noContentError);
      break;
    case 401:
      // Handle a not authorized error
      dispatch({
        type: notAuthorizedReqActionType(actionBase),
        payload: response.error,
      });
      unauthorizedError && alert(unauthorizedError);
      onFailure && onFailure(response);
      break;
    default:
      // A 400 or handle any other kind of bad request
      dispatch({
        type: failReqActionType(actionBase),
        payload: { message: response.error },
      });
      onFailure && onFailure(response);
      break;
  }
  handleStatus && handleStatus(status);
  return Promise.resolve(response);
};

// system actions

export const setUser = payload => ({ type: SET_USER, payload });

export const getLocations = () => async dispatch =>
  _apiRequest({
    actionBase: GET_LOCATIONS,
    dispatch,
    method: 'Get',
    path: 'locations',
  });

export const editLocation = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_LOCATION,
    dispatch,
    method: 'Put',
    path: `location`,
    payload,
    returnPayloadOnSuccess: true,
  });

export const addLocation = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_LOCATION,
    dispatch,
    method: 'Post',
    path: `location`,
    payload,
  });

export const getSettings = () => async dispatch =>
  _apiRequest({
    actionBase: GET_SETTINGS,
    dispatch,
    method: 'Get',
    path: 'admin/settings',
  });

export const updateSetting = payload => async dispatch =>
  _apiRequest({
    actionBase: UPDATE_SETTING,
    dispatch,
    method: 'Put',
    path: `admin/setting`,
    payload,
    returnPayloadOnSuccess: true,
  });

export const getTaxCodes = () => async dispatch =>
  _apiRequest({
    actionBase: GET_TAX_CODES,
    dispatch,
    method: 'Get',
    path: `tax`,
  });

export const editTaxCode = payload => async dispatch =>
  _apiRequest({
    actionBase: EDIT_TAX_CODE,
    dispatch,
    method: 'Put',
    path: `tax`,
    payload,
    returnPayloadOnSuccess: true,
  });

export const addTaxCode = payload => async dispatch =>
  _apiRequest({
    actionBase: ADD_TAX_CODE,
    dispatch,
    method: 'Post',
    path: `tax`,
    payload,
  });

export const isAPIServerConnected = connected => ({
  type: API_SERVER_CONNECTED,
  payload: connected,
});

export const isAMQPServerConnected = connected => ({
  type: AMQP_SERVER_CONNECTED,
  payload: connected,
});

export const needsUpdate = update => ({
  type: NEEDS_UPDATE,
  payload: update,
});

export const submitFeedback = payload => async dispatch =>
  _apiRequest({
    actionBase: SUBMIT_FEEDBACK,
    dispatch,
    method: 'Post',
    path: 'feedback',
    payload,
    onSuccess: () => {
      window.showSuccess('Thanks for your feedback! We will respond as soon as possible.');
    },
  });

export const submitTicket = payload => async dispatch =>
  _apiRequest({
    actionBase: SUBMIT_FEEDBACK,
    dispatch,
    method: 'Post',
    path: 'support',
    payload,
    onSuccess: () => {
      window.showSuccess('Thank you. We will respond as soon as possible.');
    },
  });

export const openModal = modalName => ({
  type: OPEN_MODAL,
  payload: modalName,
});

export const closeModal = modalName => ({
  type: CLOSE_MODAL,
  payload: modalName,
});

export const getChangeLog = (path, uuid) => async dispatch =>
  _apiRequest({
    actionBase: GET_CHANGE_LOG,
    dispatch,
    method: 'Get',
    path: `${path}/log?id=${uuid}`,
  });

export const resetChangeLog = () => ({ type: RESET_CHANGE_LOG });

export const getViewHistoryLog = (type, uuid) => async dispatch =>
  _apiRequest({
    actionBase: GET_VIEW_HISTORY_LOG,
    dispatch,
    method: 'Get',
    path: `record/views?type=${type}&id=${uuid}`,
  });

export const resetViewHistoryLog = () => ({ type: RESET_VIEW_HISTORY_LOG });

export const setToggleMenu = (flag?: (data: any) => void) => ({
  type: TOGGLE_MENU,
  payload: { flag },
});

export const hideMenu = () => ({
  type: HIDE_MENU,
});

export const setDashboardTab = tab => ({
  type: SET_DASHBOARD_TAB,
  payload: tab,
});

export const setDashboardFilter = filter => ({
  type: SET_DASHBOARD_FILTERS,
  payload: filter,
});

export const resetAllData = () => ({ type: RESET_ALL_DATA });

export const setTableInfo = (page, key, data) => ({
  type: SET_TABLE_INFO,
  payload: {
    key,
    page,
    data,
  },
});

export const resetTablePage = (page, key) => ({
  type: RESET_TABLE_PAGE,
  payload: {
    key,
    page,
    data: { current: 0 },
  },
});

export const requestStart = (actionBase: string, abortController: AbortController) => ({
  type: REQUEST_START,
  payload: {
    actionBase,
    abortController,
  },
});

export const requestEnd = (actionBase: string) => ({
  type: REQUEST_END,
  payload: {
    actionBase,
  },
});

export const requestAbort = (actionBase: string) => ({
  type: REQUEST_ABORT,
  payload: {
    actionBase,
  },
});
