import { ModalFooter } from '@atlaskit/modal-dialog';
import { ReactNode } from 'react';

interface ModalFooterProps {
  children: ReactNode;
}

const CrystalModalFooter = ({ children }: ModalFooterProps) => <ModalFooter>{children}</ModalFooter>;

export default CrystalModalFooter;
