import cx from 'clsx';
import { Component, ReactNode } from 'react';

interface TabListProps {
  children: ReactNode;
  className?: string | string[];
  tabsRole?: string;
  attributes?: any;
}

/* eslint-disable-next-line */
export default class TabList extends Component<TabListProps, {}> {
  /* eslint-disable-next-line */
  static defaultProps = {
    className: 'react-tabs__tab-list',
  };

  render() {
    const { children, className, ...attributes } = this.props;

    return (
      <ul {...attributes} className={cx(className)} role='tablist'>
        {children}
      </ul>
    );
  }
}

type TTabList = typeof TabList & { tabsRole: string };

(TabList as TTabList).tabsRole = 'TabList';
