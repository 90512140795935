import Cookies from 'js-cookie';

import { PREVIEW_DEPLOY_COOKIE_KEY } from './constants';

export const getPreviewVersion = () => {
  const versionCookie = Cookies.get(PREVIEW_DEPLOY_COOKIE_KEY);

  return versionCookie;
};

export const removePreviewCookie = () => {
  if (process.env.NODE_ENV === 'development') {
    Cookies.remove(PREVIEW_DEPLOY_COOKIE_KEY);
    window.location.reload();
  } else {
    window.location.assign('/preview?v=live');
  }
};

export const isPreviewDeploy = () => !!getPreviewVersion();
