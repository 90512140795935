import { Link } from 'react-router-dom';
import { translate } from 'utils/translation';
import { ReportRow } from './field_types';

export const DISTRIBUTION_REPORT_FIELDS = [
  {
    name: translate('reports', 'distribution.columns.product_number', 'Product #'),
    id: 'product_number',
    key: 'product_number',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
  },
  {
    name: translate('reports', 'distribution.columns.description', 'Description'),
    key: 'description',
    id: 'description',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: translate('reports', 'distribution.columns.open_so', 'Open SO'),
    key: 'open_so',
    id: 'open_so',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.open_so || 0,
  },
  {
    name: translate('reports', 'distribution.columns.open_layaways', 'Open Lay'),
    key: 'open_layaways',
    id: 'open_layaways',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.open_layaways || 0,
  },
  {
    name: translate('reports', 'distribution.columns.open_repairs', 'Open Rep'),
    key: 'open_repairs',
    id: 'open_repairs',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.open_repairs || 0,
  },
];
