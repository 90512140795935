export const LOCATION_MODAL = 'LOCATION_MODAL';
export const ROLE_MODAL = 'ROLE_MODAL';
export const PERMISSION_MODAL = 'PERMISSION_MODAL';
export const SKU_CATEGORY_MODAL = 'SKU_CATEGORY_MODAL';
export const TAX_CODE_MODAL = 'TAX_CODE_MODAL';
export const VENDOR_MODAL = 'VENDOR_MODAL';
export const PAYMENT_METHOD_MODAL = 'PAYMENT_METHOD_MODAL';
export const CARD_PAYMENT_MODAL = 'CARD_PAYMENT_MODAL';
export const PRODUCT_ATTRIBUTES_MODAL = 'PRODUCT_ATTRIBUTES_MODAL';
export const PRODUCT_TYPE_MODAL = 'PRODUCT_TYPE_MODAL';
export const SALESPERSON_MODAL = 'SALESPERSON_MODAL';
export const CLIENT_MODAL = 'CLIENT_MODAL';
export const CLIENT_EMAIL_MODAL = 'CLIENT_EMAIL_MODAL';
export const CLIENT_ADDRESS_MODAL = 'CLIENT_ADDRESS_MODAL';
export const CLIENT_PHONE_MODAL = 'CLIENT_PHONE_MODAL';
export const ORDER_SKU_MODAL = 'ORDER_SKU_MODAL';
export const GENERAL_SKU_MODAL = 'GENERAL_SKU_MODAL';
export const RETURN_SKU_MODAL = 'RETURN_SKU_MODAL';
export const FIND_SKU_MODAL = 'FIND_SKU_MODAL';
export const ORDER_PAYMENT_MODAL = 'ORDER_PAYMENT_MODAL';
export const ORDER_CODE_MODAL = 'ORDER_CODE_MODAL';
export const ORDER_STATUS_MODAL = 'ORDER_STATUS_MODAL';
export const ORDER_CLOSE_MODAL = 'ORDER_CLOSE_MODAL';
export const ORDER_TYPE_MODAL = 'ORDER_TYPE_MODAL';
export const SAME_SKU_MODAL = 'SAME_SKU_MODAL';
export const EDIT_SKU_MODAL = 'EDIT_SKU_MODAL';
export const JOB_TYPE_MODAL = 'JOB_TYPE_MODAL';
export const JEWELRY_LABOR_EST_MODAL = 'JEWELRY_LABOR_EST_MODAL';

export const ORDER_EDIT_MODAL = 'ORDER_EDIT_MODAL';
export const PROMPT_CLOSE_ORDER_MODAL = 'PROMPT_CLOSE_ORDER_MODAL';

export const INVENTORY_SKU_MODAL = 'INVENTORY_SKU_MODAL';
export const SCAN_LOCATION_MODAL = 'SCAN_LOCATION_MODAL';
export const INVENTORY_FLAG_MODAL = 'INVENTORY_FLAG_MODAL';
export const ADD_SKU_SCAN_MODAL = 'ADD_SKU_SCAN_MODAL';
export const ADD_JOB_SCAN_MODAL = 'ADD_JOB_SCAN_MODAL';
export const VIEW_SKU_SCANS_MODAL = 'VIEW_SKU_SCANS_MODAL';
export const VIEW_JOB_SCANS_MODAL = 'VIEW_JOB_SCANS_MODAL';
export const EDIT_INVENTORY_SKU_MODAL = 'EDIT_INVENTORY_SKU_MODAL';
export const EDIT_INVENTORY_JOB_MODAL = 'EDIT_INVENTORY_JOB_MODAL';
export const SKU_ERROR_MODAL = 'SKU_ERROR_MODAL';
export const JOB_ERROR_MODAL = 'SKU_ERROR_MODAL';

export const CLIENT_NAME_MODAL = 'CLIENT_NAME_MODAL';
export const IMAGE_CAPTURE_MODAL = 'IMAGE_CAPTURE_MODAL';
export const IDLE_LOGOUT_MODAL = 'IDLE_LOGOUT_MODAL';
export const LARGE_REPORT_MODAL = 'LARGE_REPORT_MODAL';

export const NEW_PRODUCT_MODAL = 'NEW_PRODUCT_MODAL';
export const CHANGE_LOG_MODAL = 'CHANGE_LOG_MODAL';
export const COST_CHANGE_MODAL = 'COST_CHANGE_MODAL';
export const VIEW_HISTORY_LOG_MODAL = 'VIEW_HISTORY_LOG_MODAL';

export const PASSWORD_MODAL = 'PASSWORD_MODAL';
export const LOADING_MODAL = 'LOADING_MODAL';
export const INACTIVITY_LOGOUT_MODAL = 'INACTIVITY_LOGOUT_MODAL';

export const IMAGE_VIEW_MODAL = 'IMAGE_VIEW_MODAL';

export const CLIENT_COLLECTIONS_MODAL = 'CLIENT_COLLECTIONS_MODAL';
export const STORE_CREDIT_MODAL = 'STORE_CREDIT_MODAL';
export const MILESTONE_MODAL = 'MILESTONE_MODAL';
export const RETURN_MONEY_MODAL = 'RETURN_MONEY_MODAL';
export const SEND_EMAIL_RECEIPT_MODAL = 'SEND_EMAIL_RECEIPT_MODAL';

export const CARD_TRANSACTION_MODAL = 'CARD_TRANSACTION_MODAL';
export const CARD_PAYMENT_HISTORY_MODAL = 'CARD_PAYMENT_HISTORY_MODAL';
export const VOIDED_PAYMENTS_MODAL = 'VOIDED_PAYMENTS_MODAL';
export const PAYMENT_MODAL = 'PAYMENT_MODAL';
export const CLOSED_ORDERS_MODAL = 'CLOSED_ORDERS_MODAL';

export const MARKETING_SOURCE_MODAL = 'MARKETING_SOURCE_MODAL';
export const WARRANTY_ACTIVATION_MODAL = 'WARRANTY_ACTIVATION_MODAL';
export const WARRANTY_FORM_MODAL = 'WARRANTY_FORM_MODAL';
