interface TElement {
  type?: { tabsRole: string };
}

function makeTypeChecker(tabsRole: string) {
  return (element: TElement) => !!element.type && element.type.tabsRole === tabsRole;
}

export const isTab = makeTypeChecker('Tab');
export const isTabList = makeTypeChecker('TabList');
export const isTabPanel = makeTypeChecker('TabPanel');
