import { Checkbox } from '@atlaskit/checkbox';
import { css } from '@emotion/css';

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: any) => void;
  name?: string;
  label?: string;
}

export default function CrystalCheckbox({
  checked = false,
  defaultChecked = false,
  disabled = false,
  name = 'crystal-checkbox',
  onChange,
  label = '',
}: CheckboxProps) {
  return (
    <div className={css``}>
      <Checkbox
        defaultChecked={defaultChecked}
        isChecked={checked}
        isDisabled={disabled}
        label={label}
        name={name}
        onChange={onChange}
      />
    </div>
  );
}
