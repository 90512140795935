import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const SALES_PERFORMANCE_REPORT_FIELDS = [
  {
    name: 'Salesperson Name',
    id: 'name',
    key: 'name',
    isDefault: true,
    sortable: false,
    filterable: true,
  },
  {
    name: 'Sales #',
    id: 'sales_id',
    key: 'sales_id',
    isDefault: true,
    sortable: false,
    filterable: true,
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity || 0,
  },
  {
    name: 'Amount',
    id: 'amount',
    key: 'amount',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.amount),
  },
  {
    name: 'Discount',
    id: 'discount',
    key: 'discount',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.discount),
  },
  {
    name: 'Ext. Price',
    id: 'ext_price',
    key: 'ext_price',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.ext_price),
  },
  {
    name: 'Cost',
    id: 'cost',
    key: 'cost',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.cost),
  },
  {
    name: 'Gross Profit ($)',
    id: 'gross_profit',
    key: 'gross_profit',
    isDefault: true,
    sortable: true,
    filterable: false,
    type: 'CURRENCY',
    render: (row: ReportRow) => formatMoney(row.gross_profit),
  },
  {
    name: 'Gross Profit (%)',
    id: 'gross_profit_percent',
    key: 'gross_profit_percent',
    type: 'PERCENT',
    part: 'gross_profit',
    whole: 'ext_price',
    isDefault: true,
    sortable: true,
    render: row => `${(Number(row.gross_profit_percent) * 100).toFixed(2)}%`,
  },
];
