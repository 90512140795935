import cx from 'clsx';
import { CSSProperties, ReactNode } from 'react';

interface FormGroupProps {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  type?: string;
  name?: string;
  label?: string;
  validText?: string;
  invalidText?: string;
  required?: boolean;
  props?: any;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
}

const FormGroup = ({
  className,
  children,
  type,
  name,
  label,
  labelStyle,
  validText,
  invalidText,
  required,
  ...props
}: FormGroupProps) => (
  <div className={cx('form-group', className)} {...props}>
    <label htmlFor={name} style={labelStyle || {}}>
      {label} {required ? <span>*</span> : ''}
    </label>
    {children}
    <div className='valid-feedback'>{validText || ''}</div>
    <div className='invalid-feedback'>{invalidText === undefined ? (required ? 'Required' : '') : invalidText}</div>
  </div>
);

export default FormGroup;
