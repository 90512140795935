import { CLOSE_MODAL, OPEN_MODAL, RESET_ALL_DATA } from '../constants/action-types';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, [action.payload]: true };
    case CLOSE_MODAL:
      return { ...state, [action.payload]: false };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
