import { successReqActionType } from '../actions/helpers';
import {
  GET_DASHBOARD_INVENTORIES,
  GET_DASHBOARD_INVENTORIES_RESULTS,
  GET_DASHBOARD_INVENTORY_STATUS,
  GET_DASHBOARD_MERCH_ITEMS,
  GET_DASHBOARD_METRICS,
  GET_DASHBOARD_ORDERS,
  GET_DASHBOARD_REPAIRS,
  GET_DASHBOARD_SALES,
  RESET_ALL_DATA,
  SET_DASHBOARD_FILTERS,
  SET_DASHBOARD_TAB,
} from '../constants/action-types';

const initialState = {
  // A product created within receive flow
  sales: {
    row_count: 0,
    transactions: [],
  },
  metrics: {},
  repairs: [],
  inventories: [],
  merchItems: [],
  inventoryStatus: {},
  filters: null,
  tabId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_DASHBOARD_SALES):
      return {
        ...state,
        sales: action.payload,
      };
    case successReqActionType(GET_DASHBOARD_METRICS):
      return {
        ...state,
        metrics: action.payload,
      };
    case successReqActionType(GET_DASHBOARD_REPAIRS):
      return {
        ...state,
        repairs: action.payload,
      };
    case successReqActionType(GET_DASHBOARD_INVENTORIES):
      const retInventoriesState = {
        ...state,
      };
      if (action.tag) {
        retInventoriesState[action.tag] = action.payload;
      } else {
        retInventoriesState.inventories = action.payload;
      }
      return retInventoriesState;
    case successReqActionType(GET_DASHBOARD_INVENTORIES_RESULTS):
      return {
        ...state,
        inventories: [...state.inventories, ...action.payload],
      };
    case successReqActionType(GET_DASHBOARD_MERCH_ITEMS):
      return {
        ...state,
        merchItems: action.payload,
      };
    case successReqActionType(GET_DASHBOARD_ORDERS):
      const retOrdersState = {
        ...state,
      };
      if (action.tag) {
        retOrdersState[action.tag] = action.payload;
      }
      return retOrdersState;
    case successReqActionType(GET_DASHBOARD_INVENTORY_STATUS):
      return {
        ...state,
        inventoryStatus: action.payload,
      };
    case SET_DASHBOARD_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case SET_DASHBOARD_TAB:
      return { ...state, tabId: action.payload };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
