import { certOptions } from 'components/BrowseInventoryRefresh/Diamonds/data/diamondSearchOptions';
import { EMPTY, NOT_AVAILABLE } from 'strings/constants';
import { ClientPhoneRecord } from 'types/api-types';
import { OrderItem } from 'types/api/order';
import { Organization } from 'types/api/organization';
import { ZillionOfferRequest, ZillionOrderRequest } from 'types/api/zillion';
import { CurrencyCode } from 'types/localization';
import { ClientsState } from 'types/redux-types';
import { formatZillionCurrency } from 'utils/money';

export const buildOfferPayload = (
  order: any,
  items: OrderItem[],
  organization: Organization,
  version: string
): ZillionOfferRequest => {
  const payload = {
    partner_pos_version: version || NOT_AVAILABLE,
    store_id: order?.location ? String(order.location.id) : NOT_AVAILABLE,
    order_number: order?.recordID || NOT_AVAILABLE,
    billing_zip: order?.bill_to?.postal || NOT_AVAILABLE,
    items: items
      ? items.map((item: any) => {
          return {
            type: item.quote_values?.quote_stock_category_description
              ? item.quote_values.quote_stock_category_description
              : item.sku_category_description,
            quantity: item.quantity || 1,
            estimated_value: {
              amount:
                item.quote_values?.value || item.price
                  ? formatZillionCurrency(item.quote_values?.value ? item.quote_values.value : item.price)
                  : 0,
              currency: organization.currency ? organization.currency : CurrencyCode.USD,
            },
            purchase_price: {
              amount: item.subtotal ? formatZillionCurrency(item.subtotal / (item.quantity || 1)) : 0,
              currency: organization.currency ? organization.currency : CurrencyCode.USD,
            },
          };
        })
      : [],
  };
  return payload;
};

export const buildOrderPayload = (
  order: any,
  items: OrderItem[],
  organization: Organization,
  phoneNumber: string,
  version: string,
  products: any,
  sendSms: boolean
): ZillionOrderRequest => {
  // Use N/A for required items, empty for optional payload
  const payload = {
    partner_pos_version: version || NOT_AVAILABLE,
    pos_sms_requested: sendSms,
    order: {
      store_id: order?.location ? String(order.location.id) : NOT_AVAILABLE,
      order_number: order?.recordID || NOT_AVAILABLE,
      associate_id: order?.owner?.id?.toString() || EMPTY,
      associate_name: order?.owner?.name || EMPTY,
      customer: {
        ...(phoneNumber && { mobile_phone: phoneNumber }),
        first_name: order?.customer?.firstName || NOT_AVAILABLE,
        last_name: order?.customer?.lastName || NOT_AVAILABLE,
        email: order?.email || EMPTY,
        billing_street: order?.bill_to?.street || EMPTY,
        billing_city: order?.bill_to?.city || EMPTY,
        billing_state: order?.bill_to?.state || EMPTY,
        billing_zip: order?.bill_to?.postal || EMPTY,
      },
      items: items
        ? items.map((item: any) => {
            return {
              type: item.quote_values?.quote_stock_category_description
                ? item.quote_values.quote_stock_category_description
                : item.sku_category_description || NOT_AVAILABLE,
              quantity: item.quantity || 1,
              description_full: item.quote_values?.description
                ? item.quote_values.description
                : item.description || NOT_AVAILABLE,
              description_short: item.quote_values?.quote_stock_category_description
                ? item.quote_values?.quote_stock_category_description
                : item.sku_category_description || NOT_AVAILABLE,
              sku: item.sku || EMPTY,
              serial_number: item.serial || EMPTY,
              estimated_value: {
                amount:
                  item.quote_values?.value || item.price
                    ? formatZillionCurrency(item.quote_values?.value ? item.quote_values.value : item.price)
                    : 0,
                currency: organization.currency ? organization.currency : CurrencyCode.USD,
              },
              purchase_price: {
                amount: item.subtotal ? formatZillionCurrency(item.subtotal / (item.quantity || 1)) : 0,
                currency: organization?.currency ? organization.currency : CurrencyCode.USD,
              },
              photo_link: item.bucketKey || EMPTY,
              model_number: item.reference || EMPTY,
              certification_type: certOptions[products[item.productUUID]?.diamond?.cert]?.label || EMPTY,
              certification_number: products[item.productUUID]?.diamond?.cert_number || EMPTY,
            };
          })
        : [],
    },
  };
  return payload;
};

export const getClientPhoneNumbers = (clients: ClientsState, customerId: string) => {
  if (!clients[customerId] || !clients[customerId].phones || clients[customerId].phones.length === 0) {
    return [];
  }

  return clients[customerId].phones.map((value: ClientPhoneRecord) => value.phone);
};

export const isZillionEnabled = (integrations: string[]) => {
  return integrations?.includes('zillion');
};
