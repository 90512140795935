import { successReqActionType } from '../actions/helpers';
import {
  ADD_PRODUCT_ATTRIBUTE,
  ADD_PRODUCT_ATTRIBUTE_VALUE,
  DEACTIVATE_PRODUCT_ATTRIBUTE,
  GET_ATTRIBUTES,
  REMOVE_PRODUCT_ATTRIBUTE_VALUE,
  RESET_ALL_DATA,
  UPDATE_PRODUCT_ATTRIBUTE_SORT_ORDER,
  UPDATE_PRODUCT_ATTRIBUTE_VALUES,
  UPDATE_PRODUCT_ATTRIBUTE_VARIANT_STATUS,
} from '../constants/action-types';

const initialState = {};
let newState;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_ATTRIBUTES):
      return { ...state, [action.vendorId]: action.payload };
    case successReqActionType(ADD_PRODUCT_ATTRIBUTE_VALUE):
      newState = { ...state };
      newState[action.vendorId] = newState[action.vendorId].map(attrSet => {
        if (attrSet.id === action.attrId) {
          return {
            ...attrSet,
            values: [...attrSet.values, action.newValue],
          };
        }
        return attrSet;
      });
      return newState;
    case successReqActionType(UPDATE_PRODUCT_ATTRIBUTE_VARIANT_STATUS):
      newState = { ...state };
      newState[action.vendorId] = newState[action.vendorId].map(attrSet => {
        if (attrSet.id === action.attrId) {
          return {
            ...attrSet,
            is_variant_attribute: action.payload.is_variant_attribute,
          };
        }
        return attrSet;
      });
      return newState;
    case successReqActionType(UPDATE_PRODUCT_ATTRIBUTE_VALUES):
      newState = { ...state };
      newState[action.vendorId] = newState[action.vendorId].map(attrSet => {
        if (attrSet.id === action.attrId) {
          return {
            ...attrSet,
            values: action.payload.values,
          };
        }
        return attrSet;
      });
      return newState;
    case successReqActionType(ADD_PRODUCT_ATTRIBUTE):
      return {
        ...state,
        [action.vendorId]: [...state[action.vendorId], action.payload],
      };
    case successReqActionType(REMOVE_PRODUCT_ATTRIBUTE_VALUE):
      newState = { ...state };
      newState[action.vendorId] = newState[action.vendorId].map(attrSet => {
        if (attrSet.id === action.attrId) {
          return {
            ...attrSet,
            values: attrSet.values.filter(val => val !== action.value),
          };
        }
        return attrSet;
      });
      return newState;
    case successReqActionType(DEACTIVATE_PRODUCT_ATTRIBUTE):
      newState = { ...state };
      newState[action.vendorId] = newState[action.vendorId].map(attrSet => {
        if (attrSet.id === action.attrId) {
          return {
            ...attrSet,
            active: false,
          };
        }
        return attrSet;
      });
      return newState;
    case UPDATE_PRODUCT_ATTRIBUTE_SORT_ORDER:
      newState = { ...state };
      newState[action.payload.vendorId] = newState[action.payload.vendorId].map(attrSet => {
        const newSortOrderObj = action.payload.sortOrders.find(so => so.id === attrSet.id);
        return {
          ...attrSet,
          sortOrder: newSortOrderObj.sortOrder,
        };
      });
      return newState;
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
