export interface ProductType {
  id: any;
  name: any;
  non_stock: string;
  parent_type: any;
  active: boolean;
  custom_name_1?: string;
  custom_name_2?: string;
  custom_name_3?: string;
  custom_name_4?: string;
  custom_name_5?: string;
  custom_search: any;
}

export interface AttributeValue {
  attributeID: number;
  values: string[];
}

export interface CheckMap {
  [key: string]: boolean;
}

export interface ProductAttribute {
  active: boolean;
  id: number;
  name: string;
  sortOrder: number;
  values: string[];
}

export interface DiamondSearchFilters {
  shape: DiamondShape[];
  carat: any[];
  color: DiamondColor[];
  clarity: DiamondClarity[];
  cut: DiamondCut[];
  polish: DiamondPolish[];
  symmetry: DiamondSymmetry[];
  fluorescence: DiamondFluorescence[];
  cert: DiamondCert[];
}

export enum SearchType {
  Vendor = 0,
  ItemType = 1,
}

export enum DiamondShape {
  Round = 0,
  Pear = 1,
  Princess = 2,
  Marquise = 3,
  'Emerald Cut' = 4,
  'Cushion Brilliant' = 5,
  'Cushion Modified' = 6,
  Asscher = 7,
  'Sq. Emerald' = 8,
  Oval = 9,
  Radiant = 10,
  Heart = 11,
  'European Cut' = 12,
  'Old Miner' = 13,
  Baguette = 14,
  Briolette = 15,
  Bullets = 16,
  Calf = 17,
  'Half Moon' = 18,
  Hexagonal = 19,
  Kite = 20,
  Lozenge = 21,
  Octagonal = 22,
  Pentagonal = 23,
  Rose = 24,
  Shield = 25,
  Square = 26,
  'Square Radiant' = 27,
  Star = 28,
  'Tapered Baguette' = 29,
  'Tapered Bullet' = 30,
  Trapezoid = 31,
  Triangular = 32,
  Trilliant = 33,
  Other = 34,
}

export enum DiamondCut {
  Poor = 0,
  Fair = 1,
  Good = 2,
  'Very Good' = 3,
  Excellent = 4,
  Ideal = 5,
}

export enum DiamondPolish {
  Poor = 0,
  Fair = 1,
  Good = 2,
  'Very Good' = 3,
  Excellent = 4,
  Ideal = 5,
}

export enum DiamondSymmetry {
  Poor = 0,
  Fair = 1,
  Good = 2,
  'Very Good' = 3,
  Excellent = 4,
  Ideal = 5,
}

export enum DiamondColor {
  D = 0,
  E = 1,
  F = 2,
  G = 3,
  H = 4,
  I = 5,
  J = 6,
  K = 7,
  L = 8,
  M = 9,
  N = 10,
  O = 11,
  P = 12,
  Q = 13,
  R = 14,
  S = 15,
  T = 16,
  U = 17,
  V = 18,
  W = 19,
  X = 20,
  Y = 21,
  Z = 22,
}

export enum DiamondFancyColor {
  'Red' = 0,
  'Orangy Red' = 1,
  'Reddish Orange' = 2,
  'Orange' = 3,
  'Yellowish Orange' = 4,
  'Yellow Orange' = 5,
  'Orange Yellow' = 6,
  'Orangy Yellow' = 7,
  'Yellow' = 8,
  'Greenish Yellow' = 9,
  'Green Yellow' = 10,
  'Yellow Green' = 11,
  'Yellowish Green' = 12,
  'Green' = 13,
  'Bluish Green' = 14,
  'Blue Green' = 15,
  'Green Blue' = 16,
  'Greenish Blue' = 17,
  'Blue' = 18,
  'Violetish Blue' = 19,
  'Bluish Violet' = 20,
  'Violet' = 21,
  'Purple' = 22,
  'Reddish Purple' = 23,
  'Red Purple' = 24,
  'Purple Red' = 25,
  'Purplish Red' = 26,
}

export enum DiamondSaturation {
  'Fancy Light' = 0,
  'Fancy' = 1,
  'Fancy Intense' = 2,
  'Fancy Vivid' = 3,
  'Fancy Deep' = 4,
}

export enum DiamondClarity {
  FL = 0,
  IF = 1,
  VVS1 = 2,
  VVS2 = 3,
  VS1 = 4,
  VS2 = 5,
  SI1 = 6,
  SI2 = 7,
  I1 = 8,
  I2 = 9,
  I3 = 10,
}

export enum DiamondFluorescence {
  None = 0,
  'Very Slight' = 1,
  Slight = 2,
  Faint = 3,
  Medium = 4,
  Strong = 5,
  'Very Strong' = 6,
}

export enum DiamondCert {
  GIA = 0,
  AGS = 1,
  EGL = 2,
  IGI = 3,
  HRD = 4,
  RDC = 5,
  CGL = 6,
  DCLA = 7,
  GCAL = 8,
  GHI = 9,
  DBGIS = 10,
  GSI = 11,
  GWLAB = 12,
  NGTC = 13,
  PGS = 14,
  RAP = 15,
  NONE = 16,
}

export interface AdditionalFilterOptions {
  girdles: any[];
  star_lengths: any[];
  table_percents: any[];
  depth_percents: any[];
  widths: any[];
  lengths: any[];
  depths: any[];
}
