import { successReqActionType } from '../actions/helpers';
import {
  ADD_SKU,
  ADD_SKU_COMPONENT,
  EDIT_SKU,
  GET_GENERAL_SKUS,
  GET_SKU,
  GET_SKU_COMPONENTS,
  GET_SKU_SCANS,
  GET_SKU_STOCK,
  RESET_ALL_DATA,
} from '../constants/action-types';

const initialState = {
  nonstock: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SKU:
      return { ...state, [action.payload.uuid]: action.payload };
    case successReqActionType(EDIT_SKU):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          ...action.payload,
          partner:
            action.payload.partner_id && action.payload.partner_id > 0 ? state[action.payload.uuid].partner : null,
        },
      };
    case successReqActionType(GET_SKU):
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          ...action.payload,
        },
      };
    case successReqActionType(GET_SKU_STOCK):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          stock: action.payload,
        },
      };
    case successReqActionType(GET_SKU_SCANS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          scans: action.payload,
        },
      };
    case successReqActionType(GET_SKU_COMPONENTS):
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          components: action.payload,
        },
      };
    case successReqActionType(ADD_SKU_COMPONENT):
      return state;
    case successReqActionType(GET_GENERAL_SKUS):
      return {
        ...state,
        nonstock: action.payload,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
