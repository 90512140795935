import { _apiRequest } from 'redux/actions/system';
import {
  GET_DASHBOARD_INVENTORIES,
  GET_DASHBOARD_INVENTORIES_RESULTS,
  GET_DASHBOARD_INVENTORY_STATUS,
  GET_DASHBOARD_MERCH_ITEMS,
  GET_DASHBOARD_METRICS,
  GET_DASHBOARD_ORDERS,
  GET_DASHBOARD_REPAIRS,
  GET_DASHBOARD_SALES,
} from 'redux/constants/action-types';

export const getDashboardSales = input => async dispatch => {
  const {
    closeDateFilters,
    limit,
    offset,
    user,
    location,
    client,
    salesPeopleIds,
    includeRepairs = 1,
    includeHiddenLocations,
    includeJobCostGrossProfit = true,
    excludeAdjustments = false,
    excludeGiftCards = false,
    excludeStoreCredit = false,
  } = input;

  let locationIds = <any>[];
  if (user?.locationID) {
    locationIds = [user.locationID];
  } else if (location && location?.value !== 0) {
    locationIds = [location.value];
  }

  const payload = {
    limit,
    offset,
    start_close_date: closeDateFilters[0],
    end_close_date: closeDateFilters[1],
    previous_start_close_date: closeDateFilters[2],
    previous_end_close_date: closeDateFilters[3],
    client_ids: client ? [Number(client.id)] : [],
    include_repairs: includeRepairs ? 1 : 0,
    start_canceled_date: '',
    end_canceled_date: '',
    memo: 0,
    salesperson_ids: salesPeopleIds || (user?.role?.id === 5 ? [user.id] : []),
    location_ids: locationIds,
    exclude_hidden_locations: includeHiddenLocations ? 0 : 1,
    include_job_cost_in_gross_profit: includeJobCostGrossProfit ? 1 : 0,
    exclude_adjustment_transactions: excludeAdjustments ? 1 : 0,
    exclude_gift_card_transactions: excludeGiftCards ? 1 : 0,
    exclude_store_credit_transactions: excludeStoreCredit ? 1 : 0,
  };

  await _apiRequest({
    actionBase: GET_DASHBOARD_SALES,
    dispatch,
    method: 'Post',
    path: `dashboard/transactions`,
    payload,
  });
};

export const getDashboardMetrics = input => async dispatch => {
  const {
    closeDateFilters,
    user,
    location,
    client,
    salesPeopleIds,
    includeRepairs = 1,
    includeHiddenLocations,
    excludeAdjustments = false,
    excludeGiftCards = false,
    excludeStoreCredit = false,
  } = input;

  let locationIds = <any>[];
  if (user?.locationID) {
    locationIds = [user.locationID];
  } else if (location && location?.value !== 0) {
    locationIds = [location.value];
  }

  const payload = {
    start_close_date: closeDateFilters[0],
    end_close_date: closeDateFilters[1],
    previous_start_close_date: closeDateFilters[2],
    previous_end_close_date: closeDateFilters[3],
    salesperson_ids: salesPeopleIds || (user?.role?.id === 5 ? [user.id] : []),
    client_ids: client ? [Number(client.id)] : [],
    location_ids: locationIds,
    include_repairs: includeRepairs ? 1 : 0,
    exclude_hidden_locations: includeHiddenLocations ? 0 : 1,
    exclude_adjustment_transactions: excludeAdjustments ? 1 : 0,
    exclude_gift_card_transactions: excludeGiftCards ? 1 : 0,
    exclude_store_credit_transactions: excludeStoreCredit ? 1 : 0,
  };

  await _apiRequest({
    actionBase: GET_DASHBOARD_METRICS,
    dispatch,
    method: 'Post',
    path: `dashboard/sales`,
    payload,
  });
};

export const getDashboardOrders = input => async dispatch => {
  const { closeDateFilters, dateFilters, types, tag, user, location, salesPeopleIds, includeHiddenLocations } = input;

  let locationIds = <any>[];
  if (user?.locationID) {
    locationIds = [user.locationID];
  } else if (location && location?.value !== 0) {
    locationIds = [location.value];
  }

  const params = new URLSearchParams({
    dashboard: '1',
    status: '0',
    startCanceledDate: '',
    endCanceledDate: '',
    types,
    startDate: dateFilters && dateFilters.length >= 2 ? dateFilters[0] : '',
    endDate: dateFilters && dateFilters.length >= 2 ? dateFilters[1] : '',
    startCloseDate: closeDateFilters.length >= 2 ? closeDateFilters[0] : '',
    endCloseDate: closeDateFilters.length >= 2 ? closeDateFilters[1] : '',
    salespersonIds: salesPeopleIds || (user?.role?.id === 5 ? [user.id] : []),
    locationIds,
    excludeHiddenLocations: includeHiddenLocations ? '0' : '1',
    showStockComponents: 'false',
  });

  await _apiRequest({
    actionBase: GET_DASHBOARD_ORDERS,
    dispatch,
    method: 'Get',
    path: `reports/transactions?${params.toString()}`,
    metaDispatch: { tag },
  });
};

export const getDashboardRepairs = closeDateFilters => async dispatch => {
  const params = new URLSearchParams();

  params.append('status', '0');
  params.append('startDate', '');
  params.append('endDate', '');
  params.append('startCloseDate', closeDateFilters[0]);
  params.append('endCloseDate', closeDateFilters[1]);
  params.append('recentStatus', '0');

  const path = `reports/repairsReport?${params.toString()}`;

  await _apiRequest({
    actionBase: GET_DASHBOARD_REPAIRS,
    dispatch,
    method: 'Get',
    path,
  });
};

export const getDashboardInventories = input => async dispatch => {
  const { asOfDate, multiplyResult, tag, location, includeHiddenLocations } = input;

  const payload = {
    includeOpen: 0,
    stock: 1,
    certified: 0,
    memo: 0,
    asOfDate,
    locationId: location.value && location.value !== 0 ? location.value : 0,
    excludeHiddenLocations: includeHiddenLocations ? 0 : 1,
    showStockComponents: false,
  };

  await _apiRequest({
    actionBase: multiplyResult ? GET_DASHBOARD_INVENTORIES_RESULTS : GET_DASHBOARD_INVENTORIES,
    dispatch,
    method: 'Post',
    path: `reports/inventory`,
    payload,
    metaDispatch: { tag },
  });
};

export const getDashboardMerchItems = input => async dispatch => {
  const { dateFilters, location, includeHiddenLocations } = input;

  const payload = {
    type: 1,
    memo: 0,
    end_date: dateFilters[1],
    location_ids: location.value && location.value !== 0 ? [location.value] : [],
    start_date: dateFilters[0],
    exclude_hidden_locations: includeHiddenLocations ? 0 : 1,
  };

  await _apiRequest({
    actionBase: GET_DASHBOARD_MERCH_ITEMS,
    dispatch,
    method: 'Post',
    path: `reports/merch-items`,
    payload,
  });
};

export const getDashboardInventoryStatus = input => async dispatch => {
  const { dateFilters, location, includeHiddenLocations } = input;

  const params = new URLSearchParams({
    startDate: dateFilters && dateFilters.length >= 2 ? dateFilters[0] : '',
    endDate: dateFilters && dateFilters.length >= 2 ? dateFilters[1] : '',
    locationId: location.value ? location.value : '0',
    excludeHiddenLocations: includeHiddenLocations ? '0' : '1',
    showStockComponents: 'false',
  });

  await _apiRequest({
    actionBase: GET_DASHBOARD_INVENTORY_STATUS,
    dispatch,
    method: 'Get',
    path: `reports/dashboard-inventory-status?${params.toString()}`,
  });
};
