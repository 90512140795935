import cx from 'clsx';
import { Component, ReactNode } from 'react';

const DEFAULT_CLASS = 'react-tabs__tab';

interface TElement {
  type?: { tabsRole: string };
  props: { children: ReactNode };
}

interface TabProps {
  children?: TElement[] | ReactNode;
  className?: string | string[];
  disabled?: boolean;
  tabIndex?: number;
  disabledClassName?: string;
  focus?: boolean; // private
  id?: string; // private
  panelId?: string; // private
  selected?: boolean; // private
  selectedClassName?: string;
  tabsRole?: string;
  tabRef?: (node: ReactNode) => void; // private
}

/* eslint-disable-next-line */
export default class Tab extends Component<TabProps, {}> {
  /* eslint-disable-next-line */
  static defaultProps = {
    className: DEFAULT_CLASS,
    disabledClassName: `${DEFAULT_CLASS}--disabled`,
    focus: false,
    id: null,
    panelId: null,
    selected: false,
    selectedClassName: `${DEFAULT_CLASS}--selected`,
  };

  node: HTMLLIElement | { focus: () => void } | undefined;

  componentDidMount() {
    this.checkFocus();
  }

  componentDidUpdate() {
    this.checkFocus();
  }

  checkFocus() {
    const { selected, focus } = this.props;
    if (selected && focus && this.node) {
      this.node.focus();
    }
  }

  render() {
    const {
      children,
      className,
      disabled,
      disabledClassName,
      focus, // unused
      id,
      panelId,
      selected,
      selectedClassName,
      tabIndex,
      tabRef,
      ...attributes
    } = this.props;

    return (
      <li
        {...attributes}
        ref={node => {
          /* eslint-disable-next-line */
          // @ts-ignore
          this.node = node as ReactNode;
          if (tabRef && node) {
            /* eslint-disable-next-line */
            // @ts-ignore
            tabRef(node);
          }
        }}
        aria-controls={panelId}
        aria-disabled={disabled ? 'true' : 'false'}
        aria-selected={selected ? 'true' : 'false'}
        className={cx(className, {
          [selectedClassName as string]: selected,
          [disabledClassName as string]: disabled,
        })}
        id={id}
        role='tab'
        tabIndex={tabIndex || (selected ? 0 : -1)}
      >
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        {children}
      </li>
    );
  }
}

type TTab = typeof Tab & { tabsRole: string };

(Tab as TTab).tabsRole = 'Tab';
