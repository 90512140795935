import { successReqActionType } from '../actions/helpers';
import { CREATE_ORDER_TYPE, GET_ORDER_TYPES, RESET_ALL_DATA, UPDATE_ORDER_TYPE } from '../constants/action-types';

const initialState = {
  orderTypes: [],
};

const reducer = (state = initialState, action) => {
  const { orderTypes } = state;
  switch (action.type) {
    case successReqActionType(GET_ORDER_TYPES):
      return {
        ...state,
        orderTypes: action.payload,
      };
    case successReqActionType(CREATE_ORDER_TYPE):
      const addedNewOrderTypes = [...orderTypes, action.payload];
      return {
        ...state,
        orderTypes: addedNewOrderTypes,
      };
    case successReqActionType(UPDATE_ORDER_TYPE):
      const updatedNewOrderTypes = [...orderTypes];
      const { payload } = action;
      const currentItem = updatedNewOrderTypes.find(pt => pt.id === payload.id);
      const currentItemIndex = updatedNewOrderTypes.findIndex(pt => pt.id === payload.id);
      const newItem = { ...currentItem, ...payload };
      updatedNewOrderTypes.splice(currentItemIndex, 1, newItem);
      return {
        ...state,
        orderTypes: updatedNewOrderTypes,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
