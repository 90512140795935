// switch this to `true` if running local go-server or use command start:local-go
// do not commit this change to source control if changed
const useLocalGoServer = false;
const useBackupServer = false;

export const Deployment = {
  environment: process.env.WEB_ENV,
  host:
    process.env.NODE_ENV === 'development' && (process.env.USE_LOCAL_GO || useLocalGoServer)
      ? process.env.LOCAL_API_REPORTS_URL
      : useBackupServer
      ? process.env.API_BACKUP_URL
      : process.env.API_BASE_URL,
  hostBase:
    process.env.NODE_ENV === 'development' && (process.env.USE_LOCAL_GO || useLocalGoServer)
      ? process.env.LOCAL_API_BASE
      : process.env.API_BASE,
  reportsURL:
    process.env.NODE_ENV === 'development' && (process.env.USE_LOCAL_GO || useLocalGoServer)
      ? process.env.LOCAL_API_REPORTS_URL
      : useBackupServer
      ? process.env.API_BACKUP_URL
      : process.env.API_REPORTS_URL,
  amqp: {
    url: process.env.AMQP_URL,
    host: process.env.AMQP_VHOST,
    login: process.env.AMQP_LOGIN,
    passcode: process.env.AMQP_PASSCODE,
    debug: process.env.AMQP_DEBUG,
  },
};
