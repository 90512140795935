import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Theme } from 'styles/theme';

import { COLORS, FONTS } from './constants';
import { getPreviewVersion, removePreviewCookie } from './utils';

const Content = styled.div({
  maxWidth: 1950,
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: '1.25vh 2.5vw',
  fontSize: 16,
  fontWeight: 500,
  // add some spacing on left-side for Intercome chat widget bubble
  marginLeft: process.env.INTERCOM_ENABLED ? 80 : '',

  a: {
    color: Theme.colors.primary.base,
    textDecoration: 'none',
  },

  '*:not(code)': {
    lineHeight: 1.65,
    fontFamily: FONTS.default,
  },
});

const Code = styled.code({
  padding: '0.3em 0.6em',
  margin: '0px 4px',
  fontSize: '82.5%',
  backgroundColor: COLORS.markdownBg,
  color: '#fff',
  borderRadius: 6,
  fontWeight: 600,
  lineHeight: 1.45,
  fontFamily: FONTS.monospace,
});

const Button = styled.button({
  color: COLORS.light,
  border: `2px solid ${COLORS.light}`,
  fontSize: 14,
  backgroundColor: COLORS.dark,
  padding: '3px 12px;',
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  fontWeight: 700,
  whiteSpace: 'nowrap',

  '> svg': {
    fill: COLORS.light,
    height: '100%',
    width: 10,
    marginLeft: 7,
  },

  '&:hover': {
    backgroundColor: COLORS.light,
    color: COLORS.dark,
    '> svg': {
      fill: COLORS.dark,
    },
  },
});

const Banner = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: COLORS.dark,
  color: COLORS.light,

  '@media print': {
    display: 'none',
  },
});

const Half = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
});
const Left = styled(Half)();

const Right = styled(Half)({
  flex: 1,
  paddingTop: 6,

  '@media screen and (min-width: 900px)': {
    flex: 0,
    paddingTop: 0,
  },
});

const Line = styled.span({
  whiteSpace: 'pre-wrap',
});

const CloseIcon = () => (
  <svg viewBox='0 0 320 512' xmlns='http://www.w3.org/2000/svg'>
    <path d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z' />
  </svg>
);

const PreviewDeployBanner = (_, ref) => {
  const version = getPreviewVersion();

  if (!version) return null;

  return (
    <Banner ref={ref}>
      <Content>
        <Left>
          <Line>
            {'👋  You are using a preview of the app ('}
            <Link to='/build'>more info</Link>
            {'): '}
          </Line>
          <Code>{version}</Code>
        </Left>

        <Right>
          <Button onClick={removePreviewCookie}>
            Exit preview <CloseIcon />
          </Button>
        </Right>
      </Content>
    </Banner>
  );
};

export default forwardRef(PreviewDeployBanner);
