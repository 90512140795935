import PropTypes from 'prop-types';
import { CSSProperties, FocusEvent, FormEvent, KeyboardEvent, MouseEvent } from 'react';

export interface InputProps {
  id?: string;
  className?: string;
  type?: string;
  name?: string;
  size?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  readOnly?: boolean;
  autoComplete?: string;
  value?: string | number | null;
  defaultValue?: any;
  placeholder?: string;
  maskOptions?: { allowNegative: boolean };
  style?: CSSProperties;
  onKeyPress?: (e: KeyboardEvent) => void;
  onBlur?: (e: any) => void;
  onChange?: (e: FormEvent<HTMLInputElement> | any) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  props?: any;
}

const Input = ({ className, size, type, name, autoFocus, value, ...props }: InputProps) => {
  const sizeText = size === 'sm' ? 'form-control-sm' : size === 'lg' ? 'form-control-lg' : '';
  return (
    <input
      autoComplete='new-password'
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className={`${type === 'checkbox' || type === 'radio' ? 'custom-control-input' : 'form-control'} ${sizeText} ${
        className || ''
      }`}
      id={name || ''}
      name={name || ''}
      type={type || ''}
      value={value || ''}
      {...props}
    />
  );
};

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.oneOf(['text', 'number', 'password', 'checkbox', 'radio', 'email']),
};

Input.defaultProps = {
  type: 'text',
  size: 'md',
};

export default Input;
