import { AdminSetting } from 'components/Admin/Settings/Settings';
import { successReqActionType } from '../actions/helpers';
import { CONVERT_CURRENCY, SET_FOREIGN_EXCHANGE_RATE_SESSION_DATA, SET_USER } from '../constants/action-types';

const initialState = {
  rates: {},
  buffer: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(CONVERT_CURRENCY):
      return {
        ...state,
        rates: {
          ...state.rates,
          [action.payload.to]: {
            ...state.rates[action.payload.to],
            didSetRate: false,
            converted: action.payload.converted,
            buffer: state.buffer,
            appliedRate: action.payload.converted * (1 - state.buffer / 100),
          },
        },
      };
    case SET_USER:
      const setting = action.payload.organization?.settings.find(
        setting => setting.name === AdminSetting.ForeignExchangeRateBuffer
      );

      if (!setting) return state;

      return {
        ...state,
        buffer: setting.selected,
      };
    case SET_FOREIGN_EXCHANGE_RATE_SESSION_DATA:
      return {
        ...state,
        rates: {
          ...state.rates,
          [action.payload.currency]: {
            ...state.rates[action.payload.currency],
            ...action.payload,
          },
        },
      };
    default:
      return { ...state };
  }
};

export default reducer;
