import { Children, cloneElement, DetailedReactHTMLElement, ReactNode } from 'react';
import { isTab, isTabList, isTabPanel } from './elementTypes';

export interface TElement {
  key?: string;
  type?: { tabsRole: string };
  props: { children: ReactNode };
}

function isTabChild(child: TElement) {
  return isTab(child) || isTabList(child) || isTabPanel(child);
}

export function deepMap(
  children: TElement[] | ReactNode[] | DetailedReactHTMLElement<{ children: ReactNode }, HTMLElement>[],
  callback: (child: TElement | ReactNode | DetailedReactHTMLElement<{ children: ReactNode }, HTMLElement>) => void
): TElement | DetailedReactHTMLElement<{ children: ReactNode }, HTMLElement>[] {
  return Children.map(children as any[], child => {
    // null happens when conditionally rendering TabPanel/Tab
    // see https://github.com/reactjs/react-tabs/issues/37
    if (child === null) return null;

    if (isTabChild(child as TElement)) {
      return callback(child);
    }

    if (child.props && child.props.children && typeof child.props.children === 'object') {
      // Clone the child that has children and map them too
      return cloneElement(child, {
        ...child.props,
        children: deepMap(child.props.children, callback),
      });
    }

    return child;
  });
}

export function deepForEach(children: TElement[], callback: (child: TElement) => void) {
  return Children.forEach(children, child => {
    // null happens when conditionally rendering TabPanel/Tab
    // see https://github.com/reactjs/react-tabs/issues/37
    if (child === null) return;

    if (isTab(child) || isTabPanel(child)) {
      callback(child);
    } else if (child.props && child.props.children && typeof child.props.children === 'object') {
      if (isTabList(child)) callback(child);
      deepForEach(child.props.children as TElement[], callback);
    }
  });
}
