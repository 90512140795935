import { ModalBody } from '@atlaskit/modal-dialog';
import { ReactNode } from 'react';

interface ModalBodyProps {
  children: ReactNode;
}

const CrystalModalBody = ({ children }: ModalBodyProps) => <ModalBody>{children}</ModalBody>;

export default CrystalModalBody;
