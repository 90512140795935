import cx from 'clsx';
import { Component, ReactNode } from 'react';

const DEFAULT_CLASS = 'react-tabs__tab-panel';

interface TabPanelProps {
  children?: ReactNode;
  className?: string | string[];
  forceRender?: boolean;
  id?: string;
  selected?: boolean; // private
  selectedClassName?: string;
  tabId?: string;
  tabsRole?: string;
}

/* eslint-disable-next-line */
export default class TabPanel extends Component<TabPanelProps, {}> {
  /* eslint-disable-next-line */
  static defaultProps = {
    className: DEFAULT_CLASS,
    forceRender: false,
    selectedClassName: `${DEFAULT_CLASS}--selected`,
  };

  render() {
    const { children, className, forceRender, id, selected, selectedClassName, tabId, ...attributes } = this.props;

    return (
      <div
        {...attributes}
        aria-labelledby={tabId}
        className={cx(className, {
          [selectedClassName as string]: selected,
        })}
        id={id}
        role='tabpanel'
      >
        {forceRender || selected ? children : null}
      </div>
    );
  }
}

type TTabPanel = typeof TabPanel & { tabsRole: string };

(TabPanel as TTabPanel).tabsRole = 'TabPanel';
