import { css } from '@emotion/css';
import cx from 'clsx';
import { CSSProperties, DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

interface TextareaProps {
  className?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  rows?: number;
  style?: CSSProperties;
  props?: any;
  disabled?: boolean;
  autoComplete?: string;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
}

const Textarea = ({ className = '', value = '', ...props }: TextareaProps) => (
  <textarea
    className={cx(
      'form-control',
      css`
        resize: none;
      `,
      className
    )}
    value={value}
    {...(props as unknown as Omit<
      DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
      'rows'
    > &
      TextareaProps)}
  />
);

export default Textarea;
