import { keyBy } from 'lodash';
import { successReqActionType } from '../actions/helpers';
import { ADD_SKU_CATEGORY, EDIT_SKU_CATEGORY, GET_SKU_CATEGORIES, RESET_ALL_DATA } from '../constants/action-types';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_SKU_CATEGORIES):
      return { ...state, ...keyBy(action.payload, 'id') };
    case successReqActionType(ADD_SKU_CATEGORY):
      return { ...state, [action.payload.id]: action.payload };
    case successReqActionType(EDIT_SKU_CATEGORY):
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
