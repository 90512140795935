import { successReqActionType } from '../actions/helpers';
import { CREATE_JOB_TYPE, GET_JOB_TYPES, RESET_ALL_DATA, UPDATE_JOB_TYPE } from '../constants/action-types';

const initialState = {
  jobTypes: [],
};

const reducer = (state = initialState, action) => {
  const { jobTypes } = state;
  switch (action.type) {
    case successReqActionType(GET_JOB_TYPES):
      return {
        ...state,
        jobTypes: action.payload,
      };
    case successReqActionType(CREATE_JOB_TYPE):
      const addedNewJobTypes = [...jobTypes, action.payload];
      return {
        ...state,
        jobTypes: addedNewJobTypes,
      };
    case successReqActionType(UPDATE_JOB_TYPE):
      const updatedNewJobTypes = [...jobTypes];
      const { payload } = action;
      const currentItem = updatedNewJobTypes.find(pt => pt.id === payload.id);
      const currentItemIndex = updatedNewJobTypes.findIndex(pt => pt.id === payload.id);
      const newItem = { ...currentItem, ...payload };
      updatedNewJobTypes.splice(currentItemIndex, 1, newItem);
      return {
        ...state,
        jobTypes: updatedNewJobTypes,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
